$NotificationGood: #84c779;
$NotificationGood-BG: #f4fef2;

$NotificationNeutral: $AlertColor5;
$NotificationNeutral-BG: $AlertColor6;

$NotificationBad: #D23A3A;
$NotificationBad-BG: #f8e2e2;

$Grey1: #d5d5d5;
$Grey2: #d0d0d0;

$CheckboxColor: #283382;

$NotificationAnimationTime: 300ms;
// https://stackoverflow.com/a/8331169/14015703
$MaxNotificationHeight: 300px;

@keyframes closeNotification {
  0% {
    margin-bottom: 10px;
    max-height: var(--height);
    opacity: 1;
  }

  20% {
    margin-bottom: 10px;
    max-height: var(--height);
    opacity: 0;
  }

  100% {
    margin-bottom: -32px;
    max-height: 0px;
    opacity: 0;
  }
}

@keyframes openNotification {
  0% {
    margin-bottom: -32px;
    max-height: 0;
    opacity: 0;
  }

  80% {
    margin-bottom: 10px;
    max-height: $MaxNotificationHeight;
    opacity: 0;
  }

  100% {
    margin-bottom: 10px;
    max-height: $MaxNotificationHeight;
    opacity: 1;
  }
}

.Notifications {
  height: 100%;
  width: 100%;
}

.NotificationTile {
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  box-shadow: 0 0 5px #0000003a;
  overflow-y: hidden;
  padding: 15px 20px;
  position: relative;
  // TODO(HC): Remove z-index to make easier to drop tile into other projects.
  z-index: 10;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &.Good {
    background-color: $NotificationGood-BG;
    border-color: $NotificationGood;
  }

  &.Neutral {
    background-color: $NotificationNeutral-BG;
    border-color: $NotificationNeutral;
  }

  &.Bad {
    background-color: $NotificationBad-BG;
    border-color: $NotificationBad;
  }

  &.Closing {
    animation: closeNotification $NotificationAnimationTime linear;
    animation-fill-mode: forwards;
  }

  &.Opening {
    animation: openNotification $NotificationAnimationTime linear;
  }
  
  .CloseButton { 
    background-color: transparent;
    border: none;
    cursor: pointer;
    filter: contrast(0.5);
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .TitleBar {
    align-items: center;
    display: flex;
    margin: 0 12px 18px 0;
    min-height: 18px;

    .TitleIcon {
      height: 20px;
      margin-right: 8px;
    }
  }

  .Title {
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .Body {
    margin-bottom: 4px;

    :last-child {
      margin-bottom: 0;
    }
  }
}

.PreferencesTable {
  width: 100%;

  &.Desktop {
    border-collapse: collapse;

    @include bp('tablet') {
      display: none;
    }

    .HeadRow {
      border-bottom: 1px solid $Grey1;
    }
  
    th, 
    td {
      font-size: 12px;
      border-bottom: 1px solid $Grey1;
      padding: 20px 10px;
    }
  }

  &.Mobile {
    display: none;
    
    @include bp('tablet') {
      display: block;
    }

    .Row {
      border-top: 1px solid $Grey1;
      padding: 30px 0;
    }
  }

  .NotificationConfigCheckbox {
    align-items: center;
    display: flex;
    justify-content: center;
  
    input {
      cursor: pointer;
      height: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }
  
    .Checkmark {
      background-color: #eee;
      border: 1px solid $Grey2;
      border-radius: 3px;
      cursor: pointer;
      display: inline-block;
      height: 20px;
      position: relative;
      width: 20px;

      &.Disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
  
    input:checked ~ .Checkmark {
      background-color: $CheckboxColor;
      border: 1px solid $CheckboxColor;
    }
  
    .Checkmark:after {
      content: '';
      display: none;
      position: absolute;
    }
  
    input:checked ~ .Checkmark:after {
      display: block;
    }
  
    .Checkmark:after {
      border: solid white;
      border-width: 0 3px 3px 0;
      height: 8px;
      left: 6px;
      top: 2px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 4px;
    }
  }

  .Checkboxes {
    display: flex;
    flex-flow: wrap;
  }
  
  .CheckboxContainer {
    border-radius: 3px;
    border: 1px solid $Grey1;
    display: flex;
    margin-bottom: 10px;
    padding: 10px;

    &.Checked {
      background-color: #{$CheckboxColor}40;
      border-color: $CheckboxColor;
    }

    &.Disabled {
      pointer-events: none;
    }
    
    &:not(:last-of-type) {
      margin-right: 10px;
    }

    .Label {
      margin-bottom: 0;
      margin-left: 12px;
    }
  }
}