.ForgottenPasswordPage {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include bp('tablet') {
    width: 100%;
  }

  p {
    line-height: 24px;
  }

  .ForgottenPasswordForm,
  .SuccessMessage {
    width: 340px;

    @include bp('tablet') {
      width: 100%;
    }
  }

  .Redirect {
    margin-top: 30px;
    text-align: center;
  }
}