.UserSearchToolWrapper {
    &.Toggle {
        .UserSearchToolContent {
            transform: translateX(calc(-50% - 15px));
            transition: all 0.4s ease-in-out;
        }
    }
    &.Modal {
        .ModalHeader {
            border-bottom: unset;
            padding: 0px;
            padding-right: 10px;
            margin-bottom: 10px;
            h2 {
                margin-bottom: 0px;
                margin-right: auto;
            }
            .ExitingImg {
                margin-left: auto;
            }
            .BackButton {
                cursor: pointer;
                width: 40px;
                height: 40px;
                outline: none;
                background-color: unset;
                border: unset;
                border-radius: 3px;
                margin-bottom: 0px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
    .ModalContent {
        overflow: hidden;
        padding-top: 10px;
        .UserSearchToolContent {
            width: calc(200% + 30px);
            gap: 30px;
            display: flex;
            transition: all 0.4s ease-in-out;
            .CustomerSearch,
            .SearchFilter {
                width: 100%;
            }
            .CustomerSearch {
                .SearchContainer {
                    display: flex;
                    align-items: flex-end;
                    gap: 10px;
                    .FormField {
                        position: relative;
                        flex-grow: 1;

                        .EditBox:disabled {
                            opacity: 1;
                        }
                        .ClearIcon {
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            transform: translate(0, 0);
                        }
                    }
                    .MoreInfoButtonLarge,
                    .FilterButton {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        outline: none;
                        background-color: unset;
                        border: unset;
                        border-radius: 3px;

                        .FilterIcon {
                            width: 18px;
                            height: 18px;
                        }
                    }
                    .MoreInfoButtonLarge {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .MoreInfoLarge {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid $MainColor5;
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                        }
                    }
                }

                .ResultsContainer {
                    position: relative;
                    background-color: $MainColor4;
                    border: 1px solid $MainColor5;
                    // min-height: 80px;
                    height: 300px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    margin-top: 10px;
                    transition: all 0.5s ease-in-out;

                    &.Hide {
                        min-height: 0;
                        max-height: 0;
                        opacity: 0;
                        transition: all 0.5s ease-in-out;
                    }

                    .Loading {
                        position: absolute;
                        height: 100%;
                        min-height: 80px;
                        width: 100%;
                        background-color: #ffffff94;
                        z-index: 999;

                        &.NoBg {
                            background-color: transparent;
                        }
                    }

                    .ResultsContent {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        padding: 10px;
                        border-radius: 3px;

                        .Empty {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .Result {
                        position: relative;
                        padding: 10px;
                        font-size: 0.8rem;
                        background-color: $BasicColor1;
                        border-radius: 3px;
                        border: 1px solid $BasicColor1;
                        &:hover {
                            // background-color: transparent;
                            border-color: $DynamicColor1;
                        }

                        &.Selected {
                            border-color: $DynamicColor1;
                            &:hover {
                                border-color: $BasicColor1;
                            }
                        }

                        .MoreInfo {
                            cursor: pointer;
                            display: inline-flex;
                            margin-left: 10px;
                            align-items: center;
                            justify-content: center;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            border: 1px solid $MainColor5;
                            &:hover {
                                border-color: $MainColor1;
                            }
                        }

                        .SelectButon {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 0.8rem;
                            padding: 5px;
                            font-weight: normal;

                            img {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }

                .MoreInfoModal {
                    .MoreInfoRow {
                        display: flex;
                        align-items: center;
                        font-size: 0.8rem;
                        margin-bottom: 5px;

                        dt {
                            min-width: 130px;
                        }
                    }
                }
            }
            .SearchFilter {
                .FilterHeader {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin-bottom: 10px;

                    h2 {
                        margin-bottom: 0;
                    }
                }

                .FilterContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .SingleSelectFilter {
                        display: flex;
                        align-items: center;

                        .FormLabel {
                            min-width: 175px;
                        }
                        .FormField {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}
