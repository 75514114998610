/*
Duplicate of styles from businessVerificationv2 so when v2 is phased out 
we don't need to worry about deleting the lot
*/
.ModalBackground.BusinessVerificationModalBg.Rejected {
    align-items: center;
}
.Modal.BusinessVerificationModal.Rejected {
    min-height: unset;
    top: 0;
    width: 460px;

    .ModalContent {
        min-height: unset;
    }
}

// VERIFCATION MODAL GENERAL
.Modal.BusinessVerificationModal {
    width: 85%;
    min-width: 300px;
    height: fit-content;
    min-height: 90%;
    position: relative;
    top: 30px;
    z-index: 25;
    border-radius: 3px;

    .FormSideBySide {
        align-items: end;
    }

    .RadioButton {
        height: unset;
        min-height: 40px;
    }

    .RightHeader {
        display: flex;
        margin-left: auto;
        flex-direction: row;
        align-items: center;
        .SignOutLink {
            margin-left: 10px;
            font-size: 1.3em;
            cursor: pointer;
            font-weight: 700;
            padding: 5px;
            color: $DynamicColor2;
        }
        .SwitchAccountDropdown {
            width: 300px;
        }
    }
    .ModalContent {
        min-height: 85vh;
        display: flex;
        align-items: center;
        //MODAL CONTENT PAGES
        form {
            width: 100%;
        }
        .Terms {
            width: 100%;
            max-width: 500px;
            margin: auto;
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 1.2rem;
                width: 1.2rem;
                background-color: #eee;
                border-radius: 3px;
                border: 1px solid transparent;
                box-sizing: border-box;
            }
            &.Submit {
                .CustomInput {
                    height: fit-content;
                    line-height: 1.5em;
                }
            }
        }
        .Docs {
            max-width: 760px;
            margin: auto;
            height: 100%;
        }
        .SignificantParties {
            max-width: 600px;
            width: 95%;
            margin: auto;
            .PhoneNumberBox {
                width: 100%;
            }
            .SignificantParty {
                margin-bottom: 10px;
                .DropzoneFooter {
                    margin-bottom: 0px;
                }
            }
        }
        .CorporateApp {
            max-width: 550px;
            width: 100%;
            margin: auto;
            section {
                margin-bottom: 10px;
            }
        }
    }
    .FormHeader {
        border: solid 1px $MainColor3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        & :hover {
            cursor: pointer;
        }
        &.FormHeaderError {
            border-color: $AlertColor3;
            background-color: $AlertColor4;
        }
        .LeftSide {
            display: flex;
            gap: 10px;
        }
        h3 {
            margin: 0px;
            color: black;
            font-weight: 600;
        }
        border-radius: 3px 3px 0 0;
        .Row {
            display: flex;
            column-gap: 10px;
        }
    }

    .Form {
        border: solid 1px $MainColor3;
        border-top: none;
        border-radius: 0 0 3px 3px;
        padding: 25px 20px;
        .FormSideBySide {
            .PhoneNumberBox.SideBySide.FormBox {
                width: 100%;
            }
        }
        h3 {
            color: #060415;
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
    .SaveBtn {
        @include bp('tablet') {
            height: 50px;
        }
    }
    .DeleteButton {
        background: none;
        border: none;
        height: 20px;
        padding: 0;
        margin-right: auto;
        margin-left: 20px;
        img {
            height: 100%;
        }
    }
}

.ModalBackground.BusinessVerificationModalBg {
    height: 100%;
    overflow: auto;
    align-items: normal;
    z-index: 26;
}
//BACK BUTTON
.Back {
    background-color: transparent;
    border: none;
    font-size: small;
    &:hover {
        cursor: pointer;
    }
}
//STAGE INDICATOR START
.StageIndicatorContainer {
    width: 100%;
    margin: auto;
    justify-items: center;
    display: flex;
    flex-direction: column;
    .Progress {
        width: 100%;
        max-width: 900px;
        display: flex;
        align-items: center;
        align-self: center;
        margin-bottom: 5em;
        .Circle {
            position: relative;
        }
        .label {
            position: absolute;
            top: 22px;
            left: 10px;
            text-align: center;
            font-size: 0.6rem;
            line-height: 0.9rem;
            width: 80px;
            transform: translateX(-50%);
        }
    }
    @media only screen and (max-width: 768px) {
        .Progress .label:not(.CurrentStage) {
            display: none;
        }
        .Progress {
            margin-bottom: 3.5em;
        }
    }
}

.Stage {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e0e0e0;
}

.Circle.Filled {
    background-color: $DynamicColor2;
}

.Connector {
    flex-grow: 6;
    height: 4px;
    background-color: #e0e0e0;
}

.Connector.Filled {
    background-color: $DynamicColor2;
}

//STAGE INDICATOR END

.ExitContinueContainer {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    column-gap: 20px;
}

.NoMargin {
    margin-bottom: 0px;
    button:first-child {
        margin-right: 20px;
    }
}

//checkbox multi
.FormBox {
    .FormField {
        .custom-checkbox {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 16px;
            -webkit-user-select: none;
            user-select: none;
            color: #060415;
            font-size: 1.2em;
            line-height: 1.3em;
            font-size: 1.2em;
            font-weight: 500;
        }

        .custom-checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            box-sizing: border-box;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 1.2rem;
            width: 1.2rem;
            background-color: #eee;
            border-radius: 3px;
            border: 1px solid transparent;
            box-sizing: border-box;
        }

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        .custom-checkbox input:checked ~ .checkmark:after {
            display: block;
        }
        .custom-checkbox input:checked ~ .checkmark {
            background-color: $DynamicColor2;
        }
        .custom-checkbox .checkmark:after {
            left: 6px;
            top: 1px;
            width: 3px;
            height: 10px;
            border: solid 2px white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
//No Resize on TextArea
.NoResize {
    resize: none;
}
//CHEVRON
.ChevronOpen {
    width: 10px;
    height: 10px;
    border: solid 2px $MainColor3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    align-self: center;
}
.ChevronClosed {
    width: 10px;
    height: 10px;
    border: solid 2px $MainColor3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    align-self: center;
}

.DeleteOwner {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;

    img {
        width: 20px;
        height: 20px;
    }
}

//UPLOAD DOCS LIST

.UploadDocumentModal {
    span {
        word-break: break-word;
    }
    .UploadedFiles {
        h3 {
            margin-bottom: 0.5rem;
        }
        .UploadedFilesWrapepr {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-bottom: 1rem;
            max-height: 200px;
            overflow-y: auto;

            .UploadedFile {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                padding: 5px 10px;
                border-radius: 3px;
                background-color: $MainColor4;
                font-weight: 600;
                font-size: 0.8rem;

                button {
                    cursor: pointer;
                    outline: none;
                    border: none;
                    background-color: transparent;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .DocumentListContainer {
        display: flex;
        justify-content: space-between;
        p {
            margin: 3px 0px;
        }

        img {
            width: 12px;
            height: 12px;
            align-self: center;
            &:hover {
                cursor: pointer;
                width: 13px;
                height: 13px;
            }
        }
    }
}

//SELECT DROPDOWN ERROR FIELD
.ErrorSelect {
    .CustomSelect {
        .CustomSelect__control {
            background-color: $BasicColor6;
        }
    }
}

.BusinessVerificationForm {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .SegmentedProgressBar2 {
        max-width: 600px;
        align-self: center;
    }
    .RadioButton {
        min-height: 40px;
        height: unset;
    }
    &.Wider {
        max-width: 760px;
    }
}
.MarginBottom {
    margin-bottom: 1rem;
}
$OwnerPadding: 15px 20px;
$TreeBorder: 1px solid $MainColor3;
$TreeTitleFontSize: 0.8rem;
$SideLineMarginSmall: 10px;
$SideLineMarginLarge: 20px;
$SideLineWidth: 4px;

// Used for smaller screens
$SideLineMarginSmallReduced: 5px;
$SideLineMarginLargeReduced: 10px;
$SideLineWidthReduced: 3px;

.BusinessVerificationFormV3 {
    .BeneficialOwners {
        .ButtonCheckboxInput .CustomInput {
            padding: 10px 10px 10px 40px;

            &::after,
            &::before {
                left: 10px;
            }
        }
        .OwnersTree {
            margin-bottom: 2rem;

            .OwnersTreeDeletionModal {
                .ModalHeading {
                    color: $AlertBad;
                    font-size: 1.1rem;
                }
                .ModalContent {
                    min-height: unset;
                    align-items: flex-start;

                    .DeleteText {
                        margin-bottom: 0px;
                        font-weight: bold;
                        color: $MainColor10;
                    }
                    .DeleteOwnerButtons {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        flex-wrap: wrap;
                        margin-top: 1.5rem;

                        button {
                            flex-grow: 1;
                            font-size: 0.7rem;

                            &:last-of-type {
                                background-color: $AlertBad;
                                border-color: $AlertBad;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
            .UpdateBusinessModal,
            .UpdateIndividualModal {
                width: 75%;
                min-height: 70%;
                .ModalContent {
                    min-height: unset;
                    .Form {
                        border: none;
                    }
                    .BusinessOwnerButtons,
                    .IndividualOwnerButtons {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 10px;

                        @include bp('small-tablet') {
                            grid-template-columns: 1fr;
                        }
                    }
                }
            }

            .OwnerBox {
                width: 100%;
                padding: $OwnerPadding;
                border: $TreeBorder;
                font-size: $TreeTitleFontSize;
                font-weight: bold;
                color: $MainColor10;
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;

                &.Error {
                    border-color: $AlertBad;
                    background-color: $AlertBadBackground;
                    border-bottom-color: $MainColor3;
                }
            }
            .ChildrenWrapper {
                display: flex;

                .ConnectingLine {
                    width: $SideLineWidth;
                    min-width: $SideLineWidth;
                    background-color: $DynamicColor2;
                    margin: 5px $SideLineMarginLarge 0 $SideLineMarginSmall;
                    border-radius: 2px;

                    @include bp('small-tablet') {
                        width: $SideLineWidthReduced;
                        min-width: $SideLineWidthReduced;
                        margin: 5px $SideLineMarginLargeReduced 0 $SideLineMarginSmallReduced;
                    }
                }
                .BranchWrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 20px;
                    // Fix overflow brought on by flex
                    min-width: 0;
                    &::before {
                        content: '';
                    }
                    .SingleTreeItemWrapper {
                        position: relative;

                        .FloatingHide {
                            position: absolute;
                            bottom: 0;
                            left: calc(-#{$SideLineMarginLarge} - #{$SideLineWidth} - 1px);
                            transform: translate(-1px, 1px);
                            width: 5px;
                            height: calc(50% + 1px);
                            background-color: #fff;

                            @include bp('small-tablet') {
                                left: calc(
                                    -#{$SideLineMarginLargeReduced} - #{$SideLineWidthReduced} - 1px
                                );
                            }

                            &.Full {
                                height: calc(100% + 1px);
                            }
                            &.Branch {
                                transform: translate(0, 1px);
                                z-index: 1;
                            }
                        }
                    }

                    .SingleTreeItem {
                        position: relative;
                        padding: 8px 20px;
                        border: $TreeBorder;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        &.Error {
                            border-color: $AlertBad;
                            background-color: $AlertBadBackground;

                            &.Business {
                                border-bottom-color: $MainColor3;
                            }
                        }

                        &.SingleNewItem,
                        &.Business {
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                        &.SingleNewItem {
                            padding: $OwnerPadding;
                        }

                        .FloatingCircle {
                            position: absolute;
                            top: 50%;
                            left: calc(-#{$SideLineMarginLarge} - #{$SideLineWidth / 2} - 1px);
                            transform: translate(-50%, -50%);
                            width: 14px;
                            height: 14px;
                            border-radius: 50%;
                            background-color: $DynamicColor2;
                            z-index: 2;

                            @include bp('small-tablet') {
                                left: calc(
                                    -#{$SideLineMarginLargeReduced} - #{$SideLineWidthReduced / 2} -
                                        1px
                                );
                            }
                        }

                        h4 {
                            width: 100%;
                            font-size: $TreeTitleFontSize;
                            color: $MainColor10;
                            margin: 0;
                            margin-right: auto;
                            overflow: hidden;
                            overflow-wrap: break-word;
                        }
                        .DeleteIconButton {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            outline: none;
                            background-color: transparent;
                            border: none;
                            width: 16px;
                            min-width: 16px;
                            padding: 0;
                            margin-left: 10px;

                            .DeleteIcon {
                                fill: $DynamicColor3;
                            }
                        }
                        svg {
                            width: 16px;
                            min-width: 16px;
                            fill: $DynamicColor2;
                        }
                        .ValidButton {
                            border-color: $AlertGood;
                            background-color: $AlertGood;

                            &:hover {
                                border-color: $AlertGoodHover;
                                background-color: $AlertGoodHover;
                            }
                        }
                        button {
                            font-size: 10px;
                            padding: 8px 15px;
                            font-weight: 500;
                            min-width: 115px;
                        }

                        @include bp('small-tablet') {
                            flex-direction: column;

                            h4 {
                                margin-right: unset;
                                text-align: center;
                            }
                            svg:not(.DeleteIcon) {
                                width: 20px;
                                min-width: 20px;
                            }
                            button {
                                width: 100%;
                                min-width: unset;
                            }
                            .DeleteIconButton {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                            }
                        }
                    }
                }
            }
            .TreeActionButtons {
                position: relative;
                padding: 10px 20px;
                border: $TreeBorder;
                border-top: none;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                &.Error {
                    border-color: $AlertBad;
                    background-color: $AlertBadBackground;
                }

                button {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    gap: 5%;
                    font-size: 0.65rem;
                    line-height: 0.65rem;
                    border-color: $DynamicColor2;
                    padding: 8px;
                    span {
                        margin-top: 2px;
                        white-space: nowrap;
                        padding-right: 8px;
                    }

                    svg {
                        width: 12px;
                        min-width: 12px;
                        height: 12px;
                        fill: $DynamicColor2;
                    }
                }
                @include bp('small-tablet') {
                    flex-direction: column;

                    button {
                        justify-content: center;
                        width: 100%;
                        span {
                            white-space: unset;
                        }
                    }
                }
            }
            .TreeItemError {
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translate(0, calc(100% + 2px));
                &.HasChildren {
                    transform: translate(
                        calc(#{$SideLineMarginLarge} + #{$SideLineMarginSmall} + #{$SideLineWidth}),
                        calc(100% + 2px)
                    );
                }
            }

            .NewBusinessForm,
            .NewIndividualForm {
                position: relative;
                padding: $OwnerPadding;
                border: $TreeBorder;
                border-top: none;

                .NewBusinessFormRow,
                .NewIndividualFormRow {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    column-gap: 20px;
                    > * {
                        flex-grow: 1;
                        flex-basis: 20%;
                    }
                    button {
                        font-size: 0.65rem;
                        line-height: 0.65rem;
                        padding: 8px;
                    }
                    &.AddSave {
                        button {
                            font-weight: normal;

                            &:first-of-type {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        // Old styles from here
        // TODO: Strip out what's not needed
        .Row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;

            @include bp('small-tablet') {
                grid-template-columns: 1fr;
            }

            .PhoneNumberBox {
                width: 100% !important;
            }

            .FormBox {
                width: 100%;

                &.FormSideBySide {
                    flex-direction: column;
                    align-items: flex-start;

                    .DatePickerFormField {
                        width: 100%;
                        .DatePickerBox {
                            height: 38px;
                        }
                    }
                }
            }
        }
    }
}
