@import 'styles/variables.scss';

.Lookup {
    position: relative;
    margin-bottom: 1rem;

    .Icon {
        position: absolute;
        top: 20px;
        right: 0;
        width: 40px;
        height: 40px;

        img {
            width: 40px;
            height: 40px;
        }
    }

    :global(.CustomSelect__placeholder) {
        color: $MainColor1 !important;
    }
}
.Manual {
    position: relative;
    button.Toggle {
        z-index: 1;
    }
}
button.Toggle {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    outline: none;
    display: inline-block;
    width: auto !important;
    font-size: 0.7rem;
    color: $DynamicColor2;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
    }
    &.ToggleInline {
        position: relative;
    }
}
.Layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    align-items: center;

    @include bp('desktop') {
        grid-template-columns: 1fr;
    }

    .Group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}
