@import 'styles/variables.scss';
.DividingLine {
    &.SmallPad {
        margin: 10px 30px !important;
    }
}
// #region TransferNavLink
.IbaneraNetworkNavLink {
    text-decoration: none;

    .Chevron {
        display: none;
        width: 44px;
        height: 44px;
        margin-left: auto;
        opacity: 0.5;
        transform: rotate(270deg);
    }
    &.MobileMenu {
        display: flex;
        align-items: center;
        gap: 8px;
        .TextIcon {
            background-color: $MainColor1;
        }
        .LinkDetails {
            color: $MainColor1;
            font-size: 14px;
            font-weight: 600;
        }
        .Chevron {
            display: block;
        }
    }
}
// #endregion TransferNavLink

// #region IbaneraNetwork
.IbaneraNetworkWrapper {
    height: 100%;
    .RestrictWidth {
        max-width: 500px;
        .Back {
            align-self: flex-start;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 10px;
        }
        .IbaneraNetworkHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            h1 {
                margin-bottom: 0;
            }
            .AddConnection {
                width: unset;
            }
        }
        .Dropzone {
            max-width: 500px;

            &.Hover {
                border-width: 2px;
                background-color: $MainColor4;
                background-color: lighten($MainColor4, 1%);
            }
        }
    }
    .IbaneraNetworkError {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .Loading {
        position: relative;
        max-width: 500px;

        .Spinner {
            position: relative;
            top: unset;
            left: unset;
        }
    }

    .IbaneraNetworkList {
        max-width: 800px;
        animation: fadeIn 0.7s;
        transform-origin: top center;

        .CollapseHeader {
            padding: 10px 15px;
            &.Expand {
                .CollapsedIcon {
                    display: flex !important;
                }
                .ExpandIcon {
                    display: none !important;
                }
            }
            .IbaneraNetworkItemHeader {
                flex-grow: 1;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10px;

                @include bp('mobile') {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr max-content;
                }

                .HeaderInfo {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    flex-grow: 1;

                    @include bp('mobile') {
                        .SourceAccount,
                        .TransferType,
                        .Amount {
                            display: none;
                        }
                    }

                    .LeftSection {
                        flex-grow: 100;
                        .PayeeName {
                            font-weight: bold;
                            color: $BasicColor2;
                            font-size: 0.75rem;
                        }

                        @include bp('mobile') {
                            flex-grow: 0;
                        }
                    }
                }
                .AmountWrapper {
                    min-width: 120px;
                    .Amount {
                        font-size: 0.85rem;
                        font-weight: bold;
                    }
                }
                .StatusPill {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 20px;
                    font-size: 0.8rem;
                    color: $BasicColor1;
                    font-weight: 500;
                    background-color: $DynamicColor2;
                    padding: 0px 10px;
                    border-radius: 15px;
                    min-width: 70px;

                    &.Success {
                        background-color: $AlertGood;
                    }
                    &.Error {
                        background-color: $AlertBad;
                    }
                }
                .TransferType {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 20px;
                    font-size: 0.8rem;
                    color: $BasicColor1;
                    font-weight: 500;
                    background-color: $DynamicColor2;
                    padding: 0px 10px;
                    border-radius: 15px;
                    min-width: 70px;

                    &--NONE {
                        background-color: transparent;
                        border: 1px solid $DynamicColor2;
                        color: $BasicColor2;
                    }

                    &--WIRE {
                        background-color: $DynamicColor2;
                    }
                    &--ACH {
                        background-color: $DynamicColor3;
                    }
                    &--RTP {
                        background-color: $AlertNeutral;
                    }
                    &--CROSSBORDER {
                        background-color: #008c6e;
                    }
                    &--SWIFT {
                        background-color: $DynamicColor1;
                    }
                    &--FEDWIRE {
                        background-color: $AlertGood;
                    }
                }
                .MobileInfo {
                    display: none;
                    grid-column: span 2;

                    @include bp('mobile') {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }
                }
                .Icons {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    .CollapsedIcon,
                    .ExpandIcon,
                    .EditIconButton,
                    .DeleteIconButton {
                        box-sizing: content-box;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        outline: none;
                        background-color: transparent;
                        border: none;
                        width: 16px;
                        min-width: 16px;
                        padding: 0 7.5px;
                    }
                    .CollapsedIcon {
                        display: none;
                    }
                }
            }
        }
        .IbaneraNetworkItemContent {
            display: flex;
            gap: 15px;
            overflow: hidden;

            &.Wrap {
                flex-wrap: wrap;

                .MemoNotes .LabelValuePair {
                    margin-bottom: 10px;
                }
            }

            @include bp('small-tablet') {
                flex-wrap: wrap;
            }

            > * {
                flex-shrink: 0;
            }
            .MemoNotes {
                flex-shrink: 1;

                @include bp('mobile') {
                    .LabelValuePair {
                        flex-direction: column;
                        gap: 0;
                    }
                }

                .Memo .Value {
                    font-style: italic;
                }
            }

            .LabelValuePair {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                margin-bottom: 3px;
                color: $BasicColor2;

                &.MarginBottom {
                    margin-bottom: 1rem;
                }

                .Label {
                    font-weight: bold;
                }
                .Value {
                    text-wrap: wrap;
                }
            }
        }
        .Collapse {
            margin-bottom: 0;
            &:not(:first-child) {
                margin-top: 10px;
            }
            &.Success {
                border-color: $AlertGood;
                background-color: $AlertGoodBackground;
            }
        }
        .IbaneraNetworkButtons {
            margin-top: 10px;
            display: flex;
            gap: 10px;
            @include bp('mobile') {
                flex-direction: column;
            }
        }
    }
}

// #endregion IbaneraNetwork

// #region EditTransferModal
.IbaneraNetworkWrapper .EditTransferModal {
    width: 100%;
    max-width: 750px;
    .ModalContent {
        padding-top: 30px;
        flex-grow: 1;

        h4 {
            text-transform: uppercase;
            color: #505050 !important;
            font-size: 0.75rem;
        }
        h2 {
            margin-top: 1rem;

            &:first-of-type {
                margin-top: 0;
            }
        }
        textarea {
            resize: vertical;
            min-height: 130px;
            max-height: 300px;
        }
    }
}
.list-container-networks {
    position: relative;
    height: 100%;
    width: 100%;
}
.PageLoader {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Fill parent container height */
    width: 100%; /* Fill parent container width */
    position: relative; /* Ensure it aligns relative to the parent */
    background-color: transparent; /* No background */
}
.search-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    padding: 5px 40px 5px 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #888;
}

/* Styling for input focus */
.search-input:focus {
    border-color: #4caf50;
}
.accept-connection-modal {
    border-radius: 10px;

    .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: transparent;
    }
    p span {
        font-weight: 600;
        font-size: 16px;
    }
    .Buttons {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        button {
            flex: 1;
        }
        .adding-conenction-container {
            right: 10px;
            height: 10px;
            background-color: #4caf50;
        }
    }
}
.add-connection-modal {
    border-radius: 10px;
    height: 70%;

    .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        background: transparent;
    }

    .ModalContent {
        height: 100%;
        padding: 20px;
    }

    p span {
        font-weight: 600;
    }

    .Buttons {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 10px;
        button {
            flex: 1;
        }
        .adding-conenction-container {
            right: 10px;
            height: 10px;
            background-color: #4caf50;
        }
    }

    .search-container {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 10px;

        .LoadingSearchSpinner {
            position: absolute;
            right: 10px;
            display: flex;
            align-items: center;
            height: 100px;
        }
    }

    .search-input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .no-results-message {
        text-align: center;
        color: #666;
        font-size: 16px;
        margin-top: 20px;
    }
    .search-icon {
        width: 25px;
        height: 25px;
    }

    .container-net-users {
        flex-grow: 1;
        overflow-y: auto;
        padding: 10px;
        background-color: #f9f9f9;
    }

    .list-item-net-users {
        display: flex;
        align-items: center;
        padding: 8px;
        margin-bottom: 1px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }

    .item-icon-net-users {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .listitem-title-net-users {
        font-size: 14px;
        font-weight: bold;
        flex-grow: 1;
    }
}

.tab-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 20px;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
}

.tab-header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.tab-button {
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #555;
    transform: translateY(5px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.tab-button:hover {
    color: #007bff;
}

.tab-button.active {
    background-color: transparent;
    font-weight: bold;
    color: #000;
    border-bottom: 3px solid #007bff;
    transform: translateY(0);
}

.tab-content {
    padding: 20px;
    width: 100%;
    height: 100%;
}
/* Container for the row */
.row-container-options {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 10px 0x;
    font-family: Arial, sans-serif;
}

/* Individual label item */
.label-item-options {
    display: flex;
    align-items: center;
    margin: 10px;
    cursor: pointer;
}

/* Icon styles */
.icon-options {
    width: 30px;
    height: 30px;
    fill: #333333;
    object-fit: contain;
}

/* Title styles */
.title-options {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
}
.title-options:hover {
    color: #007bff;
}
.icon-options:hover {
    fill: #007bff;
}
.list-item-connection-contianer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.list-item-connection {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 1px;
    cursor: pointer;
    z-index: 0;
    .item-icon-connection {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .item-icon-pending {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    .listitem-title-connection {
        font-size: 14px;
        font-weight: bold;
        flex-grow: 1;
        cursor: pointer;
    }
    
}
.list-item-connection:hover {
    background-color: #eeeeee;
}
.make-connection-payment-modal {
    border-radius: 10px;
    width: 60%;
}
.dropdown-asset-accounts {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
}
.dropdown-asset-accounts option {
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
}
.dropdown-asset-accounts:hover {
    border-color: #4f46e5;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-asset-accounts:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}
.dropdown-asset-accounts-label {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
    display: inline-block;
}
.make-connect-payment-label {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
}
.make-connect-payment-label-input {
    width: 100%;
    padding: 5px 40px 5px 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
}
.ReviewPayment {
    .Layout {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;
    }

    .Row {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;

        &:last-of-type {
            margin-bottom: 2rem;
        }

        dt {
            min-width: 110px;
        }
        dd {
            font-weight: 500;
            color: $BasicColor2;
            text-wrap: wrap;
            word-break: break-word;
        }
    }
}