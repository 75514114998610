.TFAInputContainer {
    width: 100%;
    position: relative;

    .SwitchTFATypeContainer {
        margin-bottom: -20px;
    }

    .SwitchTFATypeButton {
        cursor: pointer;
        font-weight: 500;
        text-decoration: underline;
        z-index: 1;
    }

    .FormField .TFAHiddenField {
        opacity: 0.001;
    }

    .InputMask {
        height: 40px;
        pointer-events: none;
        position: absolute;
        top: 0;
        top: 20px;
        width: 100%;
    }

    .InputMask {
        background-color: $MainColor4;
        border: 1px solid $MainColor3;
        border-radius: 3px;
        display: flex;
        justify-content: space-around;

        &.white {
            background-color: white;
        }

        .Point {
            color: $MainColor3;
            font-size: 24px;
            font-weight: 500;
            transform: translate(-5px, 2px);
            width: 1px;

            &.Filled {
                transform: translate(-5px, 2px);
            }
        }
    }
}
