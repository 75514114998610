.filter-count {
    background-color: #006400;
    color: white;
    border-radius: 50%;
    padding: 3px 3px;
    font-weight: 300;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: 20px;
    font-size: small;
}
.filter-button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 2px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: blue;
    border-width: 1px;
    margin-right: 10px;
}

.filter-button:hover {
    background-color: #e0e0e0;
}

.Modal-Filters-Txns {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 768px;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.amount-range .range-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.amount-range .range-group input {
    flex: 1;
    max-width: 100%;
    margin-right: 5px;
}

.amount-range .range-group span {
    margin: 0 10px;
}
.FilterSection form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

@media (max-width: 768px) {
    .FilterSection form {
        grid-template-columns: 1fr;
    }
    .form-actions {
        grid-column: span 1;
        justify-content: flex-end;
    }
}
.FilterSection h3 {
    margin-bottom: 15px;
    font-size: 1.5em;
    color: #333;
}

.form-group {
    display: flex;
    flex-direction: column;
    height: 100%;
}
@media (min-width: 768px) {
    .form-group:last-child {
        grid-column: span 2;
    }
}
.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input,
.form-group select {
    width: 100%;
    min-height: 35px;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: small;
    font-weight: normal;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}
.sticky-actions {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #b2bac4;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.form-actions button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    margin: 0;
}

.form-actions button:hover {
    background-color: #0056b3;
}

.form-actions button:focus {
    outline: none;
}

.form-actions button[type='button'] {
    background-color: #6c757d;
    margin: 0px;
}

.form-actions button[type='button']:hover {
    background-color: #5a6268;
    margin: 0px;
}
