.ProgressBarContainer {
    display: flex;
    height: 6px;
    position: relative;
    width: 100%;

    @include bp('tablet') {
        align-items: flex-end;
        height: 20px;
    }

    .ProgressBar {
        border-radius: 3px;
        height: 6px;
        // overflow: hidden;
        width: 100%;

        .Filled {
            height: 100%;
            position: relative;
            border-radius: 3px;
            .FilledCircle {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                position: absolute;
                right: -4px;
                top: -2px;
                overflow: visible;
            }
        }
    }

    .Percentage {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-24px);

        @include bp('tablet') {
            transform: translateY(-12px);
        }
    }
}

.FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell .LowHigh {
    display: flex;
    justify-content: space-between;
    span {
        font-size: 0.7rem;
        // font-weight: 500;
        margin-top: 5px;
        padding: 0;
    }
}
