.Dashboard {
    .DashboardInformation {
        height: calc(100vh - 120px);
        padding: 20px 60px 40px;
        overflow: auto;

        @include use-width-content;
    }

    .CMSContentSpinner,
    .DashboardContentSpinner {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    .LocalContent {
        height: calc(100vh - 120px);
        padding: 23px 60px;

        @include use-width-content;

        @include bp('tablet') {
            height: unset;
            padding-bottom: 44px;
            padding-top: 44px;
        }
    }

    .ProfilePageContent {
        padding: 60px;

        &.WithIFrame {
            overflow-y: hidden;
            padding: 0;
        }

        @include use-width-content;
    }

    .DashboardPage {
        width: 100%;
        position: relative;
    }

    .DashboardPageContent {
        height: calc(100vh - 120px);
        overflow-y: auto;
        position: relative;

        &.WithSubNavigation {
            // Subtract additional 60px for sub navigation height
            height: calc(100vh - 120px - 60px);
        }

        &.IFrame {
            overflow-y: hidden;
        }
    }

    iframe {
        border: 0;
        height: 100%;
        width: 100%;
    }

    .GlobalPassModalBackground {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 3;

        .GlobalPassContainer {
            position: relative;

            .SignOutButton {
                padding-right: 8px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .GlobalPassModal {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 95%;
            padding: 52px 68px;

            @include bp('tablet') {
                overflow-y: scroll;
            }
        }

        .VerificationMessage {
            padding: 30px;
        }

        .LoaderContainer {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .SignOutButton {
                padding: 0px;
            }
        }
    }
}
