$BallSize: 11px;

.SegmentedProgressBar {
    .Segment {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;
        &:not(:first-of-type) {
            flex: 1;
        }
        .ConnectingLine {
            background-color: $MainColor3;
            height: 1px;
            flex: 1;
            margin-left: -5px;
            margin-right: -5px;
        }
        .Ball {
            background-color: $MainColor3;
            height: $BallSize;
            width: $BallSize;
            line-height: 19px;
            text-align: center;
            border-radius: 50%;
            z-index: 1;
        }
        &.Passed {
            .ConnectingLine,
            .Ball {
                background-color: $DynamicColor2;
                color: $BasicColor1;
            }
        }
        &.Active {
            .ConnectingLine,
            .Ball {
                background-color: $DynamicColor2;
                color: $BasicColor1;
            }
        }
        .SegmentLabel {
            position: absolute;
            top: 20px;
            right: 0;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;

            @include bp('small-tablet') {
                display: none;
            }
        }
        .SegmentLabel {
            transform: translateX(50%) translateX(calc(#{$BallSize} / -2));
        }
        &:first-of-type .SegmentLabel {
            max-width: 200px;
        }
    }
    .Bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1rem;
    }
    .SegmentLabels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > :not(:last-of-type, :first-of-type) {
            transform: translateX(-20px);
        }
    }
}

.SegmentedProgressBar2 {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 10px;
    .Segment {
        flex: 1 1 0px;
        position: relative;
        text-align: center;

        .Ball {
            height: 20px;
            width: 20px;
            background: $MainColor3;
            border-radius: 50%;
            position: absolute;
            top: -25px;
            left: calc(50% - 5px);
        }

        .ConnectingLine {
            position: absolute;
            z-index: -1;
            top: -17px;
            right: 50%;
            width: 100%;
            height: 4px;
            background-color: $MainColor3;
        }

        &.Passed {
            .ConnectingLine,
            .Ball {
                background-color: $DynamicColor2;
                color: $BasicColor1;
            }
        }
        &.Active {
            .ConnectingLine,
            .Ball {
                background-color: $DynamicColor2;
                color: $BasicColor1;
            }
        }
    }
}
