$Font1: Montserrat;

$BasicColor1: #fff;
$BasicColor2: #000;
$BasicColor4: #7cd838; /* valid green */
$BasicColor5: #d83838; /* error red */
$BasicColor6: #feefee; /* light error red */
$BasicColor7: #42b273;
$BasicColor8: #e9615b;

$MainColor1: #505050; /*text color*/
$MainColor3: #d0d0d0; /*border color*/
$MainColor4: #f2f2f2; /*grey for background*/
$MainColor5: #ededed; /*second grey for borders*/
$MainColor7: #e8f5fe;
$MainColor8: #f5f5f5;
$MainColor9: #9f9f9f;
$MainColor10: #1a1a1a;
$MainColor11: #f8f8f8;
$MainColor14: #818181;
$MainColor15: #323c46; // Highlight bg on basic 2

/* Password strength meter colours */
$Colour_VeryWeak: #e1e5e8; /* Very Weak: Grey */
$Colour_Weak: #d83838; /* Weak: Red */
$Colour_SoSo: #ffbf00; /* So-So: Amber */
$Colour_Good: #afc882; /* Good: Green */
$Colour_Great: #7cd838; /* Great: Green */

$Alert_Red: #e4413b;
$Alert_Background: #fff7f7;

// Fx price component
$FX_Green: #25cba2;
$FX_Red: #db1460;

//dropdown
$AlertColor1: #84c779; /* green */
$AlertColor2: #f4fef2; /* light green */
$AlertColor3: #c77979; /* red */
$AlertColor4: #fff7f7; /* light red */
$AlertColor5: #b0b6fd; /* blue */
$AlertColor6: #edeffe; /* light blue */

$DynamicColor1: var(--firstColor); // #1943DC;
$DynamicColor1a: var(--firstColorHover); // #0d31a2;
$DynamicColor2: var(--secondColor); //#636fec;
$DynamicColor2a: var(--secondColorHover); // #3544b8;
$DynamicColor3: var(--thirdColor); //#da0a59;
$DynamicColor3a: var(--thirdColorHover); //#B20A4A;

$AlertGood: var(--alertGood); //#84C779;
$AlertGoodHover: var(--alertGoodHover); //#6A9F61;
$AlertGoodBackground: var(--alertGoodBackground); //#F4FEF2;
$AlertBad: var(--alertBad); //#C77979;
$AlertBadHover: var(--alertBadHover); //#9F6161;
$AlertBadBackground: var(--alertBadBackground); //#FFF7F7;
$AlertNeutral: var(--alertNeutral); //#b0b6fd;
$AlertNeutralHover: var(--alertNeutralHover); //#283389;
$AlertNeutralBackground: var(--alertNeutralBackground); //#edeffe;

$DynamicColor5: var(--fifthColor); //#060415; headingColor
$DynamicColor6: var(--sixthColor); //#edeffe;

/* Filter Page - dropdown */
$WideFilterWidth: 360px; // On the filter panel: for the containers of the SingleChoiceField and DatePickerFormField to be the correct width

// Breakpoints
$breakpoints: (
    'small-mobile': 360px,
    'mobile': 480px,
    'small-tablet': 768px,
    'tablet': 1024px,
    'desktop': 1200px,
) !default;

@mixin bp($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "That's not a valid breakpoint. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin use-width-content {
    @include bp('tablet') {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include bp('mobile') {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include bp('small-mobile') {
        padding-left: 10px;
        padding-right: 10px;
    }
}
