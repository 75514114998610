.FormDropdownField {
    width: 170px;
    height: 40px;
    margin-right: 20px;

    .FormField {
        width: 100%;
        height: 100%;
    }
}

.CustomSelect {
    background-color: white;
    width: 100%;
    min-width: 150px;
    font-size: 0.8rem;

    &.CustomSelect--is-disabled {
        opacity: 0.5;
    }

    &.Small {
        max-width: 100px;
        width: 100%;
        font-size: 0.7rem;

        .CustomSelect__indicators {
            .CustomSelect__indicator {
                width: 15px;
                padding: 0;
                margin-right: 5px;
            }
        }
        .CustomSelect__control .CustomSelect__value-container {
            padding-left: 5px;
        }
    }

    &.RoundedRect {
        border-radius: 3px;

        .CustomSelect__control {
            width: 100%;
            padding: 0;
            border-radius: 3px;
            min-height: 30px;
            .CustomSelect__value-container {
                .CustomSelect__placeholder,
                .CustomSelect__single-value {
                    font-size: 12px;
                    line-height: 16px;
                    color: $BasicColor2;
                    font-weight: 400;
                    margin-left: 0;
                    + div {
                        margin: 0;
                    } // to remove margin from the input indicator, since we cannot target with CustomSelect__ prefix.
                }
            }
        }
    }

    .CustomSelect__control {
        width: 100%;
        padding: 2.5px 10px;
        background-color: $BasicColor5;
        border-radius: 44px;
        border: 1px solid $BasicColor4;
        cursor: pointer;

        /* &:hover {
      background-color: $BasicColor5;
      border: 1px solid $BasicColor4;
    } */

        .CustomSelect__indicators {
            .CustomSelect__indicator-separator {
                display: none;
                position: absolute;
                visibility: hidden;
            }
        }

        .CustomSelect__value-container {
            padding: 0 0 0 15px;
        }
    }
}
body > div > .CustomSelect__menu,
.CustomSelect .CustomSelect__menu {
    // applies when we portal
    border-radius: 3px;
    padding: 0px;
    z-index: 20;

    .CustomSelect__menu-list {
        border: $MainColor3;

        .CustomSelect__option {
            cursor: pointer;
            padding: 15px auto 15px 16px;
            font-size: 12px;
            line-height: 30px;
            color: $BasicColor2;
            font-weight: 400;

            &:hover {
                background-color: $AlertColor6;
                color: $BasicColor2;
            }
        }
        .CustomSelect__option--is-selected {
            background-color: $DynamicColor2;
            color: $BasicColor1;
            font-weight: 400;
        }
        .CustomSelect__option--is-focused:not(.CustomSelect__option--is-selected) {
            background-color: $AlertColor6;
            color: $BasicColor2;
            font-weight: 400;
        }
    }
}

.SingleChoiceField {
    .FormField {
        width: $WideFilterWidth;
        min-width: $WideFilterWidth;
    }
}
