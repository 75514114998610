// https://fkhadra.github.io/react-toastify/how-to-style/

$ToastRed: #C77979;
$ToastGreen: #84C779;
$ToastBlue: $NotificationNeutral;

.Main {
  .Toastify__toast {
    align-items: center;
    border-radius: 3px;
    min-height: unset;
    padding: 0;

    @include bp('tablet') {
      margin: 0 auto 10px;
      width: 90%;
    }
  }

  .Toastify__toast--error {
    background-color: $ToastRed;
  }

  .Toastify__toast--info {
    background-color: $ToastBlue;
  }

  .Toastify__toast--success {
    background-color: $ToastGreen;
  }

  .Toastify__toast-container--bottom-center {
    @include bp('tablet') {
      bottom: 70px;
    }
  }
  
  .Toastify__toast-body {
    font-family: $Font1;
    font-size: 12px;
    margin: 0;
    padding: 12px;
  }

  .Toastify__close-button {
    align-items: center;
    align-self: unset;
    display: flex;
    margin-right: 4px;
    min-height: 25px;
  }
}