.Modal.JumioModal {
    z-index: 60;
    width: min(700px, 100vw);
    max-height: calc(100vh - 20px);
    border-radius: 3px;

    .SignOutLink {
        margin-left: auto;
        font-size: 1.3em;
        cursor: pointer;
        font-weight: 700;
        padding: 5px;
    }

    .JumioIframe {
        height: calc(min(600px, 100vh - 80px));
    }
    .ManualVerificationRequiredSuccess {
        text-align: center;
    }
    .ManualVerificationRequired {
        height: calc(min(600px, 100vh - 280px));
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &.Loading {
            .Spinner {
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
        &.Error {
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
                text-align: center;
                color: $MainColor1;
            }
        }

        > p {
            text-align: center;
            margin-bottom: 0;
            font-weight: bold;
        }

        form {
            margin-top: 1rem;

            .FormBackButton {
                display: block;
                margin-bottom: 1rem;
                outline: none;
                background-color: transparent;
                border: none;
                color: $MainColor1;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            h2 {
                color: $MainColor1;
            }
            .Divide {
                padding-bottom: 1rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid $MainColor3;
            }
            .IndividualLayout {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 1rem;

                .FormSideBySide {
                    align-items: stretch;
                }

                .DatePickerBox {
                    height: 38px;
                }

                @include bp('small-tablet') {
                    grid-template-columns: 1fr;
                }
            }
            fieldset {
                border: none;
                legend {
                    font-size: 0.8rem;
                    line-height: 0.8rem;
                    color: $DynamicColor5;
                    margin-bottom: 1rem;
                    font-weight: 500;
                    text-transform: capitalize;
                }
                button {
                    margin-bottom: 1rem;
                }
            }
            .Row {
                display: flex;
                align-items: flex-end;
                gap: 0.5rem;
            }
        }
    }
    .PendingAdminApproval {
        text-align: center;
    }
    .Btn {
        margin-right: 5px;
    }

    .VerificationComplete,
    .VerificationError,
    .VerificationManualIntervention {
        text-align: center;
    }
    .Waiting {
        text-align: center;
        h3 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .Spinner {
            position: relative;
            left: 0;
            top: 0;
        }
    }
    .FormBox {
        flex-direction: column;
    }
    .ManualInterventionBtn {
        margin: 10px auto 0 auto;
        display: block;
    }
    .ModalContent {
        padding: 0;
        > :not(.JumioIframe) {
            padding: 40px 30px;
            @include bp('mobile') {
                padding: 40px 20px;
            }
            @include bp('small-mobile') {
                padding: 40px 10px;
            }
        }
    }
}
.ModalBackground.JumioModalBg {
    z-index: 30;
}
.Page.ManageesJumio {
    margin: 60px auto 60px auto;
    min-height: calc(100vh - 60px - 60px);
    width: min(700px, 100%);

    .FullWidth {
        margin-bottom: 0 !important;
    }
}
.Page {
    .Waiting,
    .VerificationComplete,
    .VerificationError {
        text-align: center;
        h3 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .Spinner {
            position: relative;
            left: 0;
            top: 0;
        }
    }
}
