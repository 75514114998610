/* Constants */
// Table Cell constants
$miniCellWidth: 30px;
$smallMinCellWidth: 55px;
$semiSmallMinCellWidth: 70px;
$mediumMinCellWidth: 100px;
$semiLargeMinCellWidth: 150px;
$largeMinCellWidth: 200px;

// Table Head constants
$tableHeadFontSize: 0.7rem;
$tableHeadLetterSpacing: 0em;
$tableHeadCellHeight: 40px;
$tableHeadCellPaddingRight: 15px;

// Table Body constants
$tableBodyFontSize: 0.9rem;
$tableBodyFontWeight: 600;
$tableBodyCellHeight: 70px;
$tableBodyCellPaddingRight: 15px;

// Misc
$RowSelectedColour: $MainColor8;

@mixin cell-sort-by-icon($iconUrl) {
    background-image: url($iconUrl);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin table-default-head {
    display: flex;
    justify-content: space-between;
}

@mixin table-head-default-cell {
    position: relative;
    display: flex;
    align-items: center;
    min-width: $mediumMinCellWidth;
    border-bottom: 1px solid $MainColor3;
    font-size: $tableHeadFontSize;
    letter-spacing: $tableHeadLetterSpacing;
    height: $tableHeadCellHeight;
    flex: 1;
    padding-right: $tableHeadCellPaddingRight;
    overflow: hidden;
    font-weight: 700;

    span {
        font-weight: 600 !important;
    }

    &.Sortable {
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        span.Sorting {
            color: #387db9;
            font-weight: bold;
        }

        span {
            font-weight: 700;
        }
    }

    .SortIcon {
        cursor: pointer;
        width: 15px;
        height: 20px;

        &.ASC {
            @include cell-sort-by-icon('../../assets/ibanera/Icon_SortBy_03.png');
        }

        &.DESC {
            @include cell-sort-by-icon('../../assets/ibanera/Icon_SortBy_02.png');
        }
    }

    &.Mini {
        min-width: $miniCellWidth;
        max-width: $miniCellWidth;
        padding-right: 0 !important;
        padding-left: 0;
    }

    &.Small {
        min-width: $smallMinCellWidth;
        padding-right: 10px;
    }

    &.SemiSmall {
        min-width: $semiSmallMinCellWidth;
        padding-right: 10px;
    }

    &.Large {
        min-width: $largeMinCellWidth !important;
    }

    &.SemiLarge {
        min-width: $semiLargeMinCellWidth !important;
    }

    span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
    }

    &.NoSortIcon {
        background-image: none;
        // padding-right: 0;
        span {
            padding-right: 0;
        }
    }

    &.NoPaddingRight {
        padding-right: 0;
    }

    &.CheckboxCell {
        background-image: none;
        min-width: 0;
        max-width: 40px;
        padding-left: 10px !important;
        padding-right: 10px !important;

        min-width: 40px;
    }

    &.FilterCell {
        background-image: none;
        display: none;
        min-width: 0;
        max-width: 50px;
        padding: 0 10px 0 20px;

        .FilterIcon {
            max-width: 18px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.8;
            }
        }
    }

    &.RowOptionCell {
        background-image: none;
        min-width: 50px;
        max-width: 50px;
        padding: 0;
        overflow: visible;
    }
}

@mixin table-body-default-cell {
    position: relative;
    display: flex;
    align-items: center;
    min-width: $mediumMinCellWidth;
    font-size: $tableBodyFontSize;
    font-weight: $tableBodyFontWeight;
    height: $tableBodyCellHeight;
    flex: 1;
    border-bottom: 1px solid $MainColor3;
    padding-right: $tableBodyCellPaddingRight;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    .CellFontSize {
        font-size: 12px;
    }
    h3 {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
    }
    span {
        font-size: 0.7rem;
        font-weight: 500;
    }
    &.Mini {
        min-width: $miniCellWidth;
        max-width: $miniCellWidth;
        padding-right: 0 !important;
        padding-left: 0 !important;
        justify-content: center;
        span {
            padding-right: 0 !important;
        }
    }

    &.Small {
        min-width: $smallMinCellWidth;
        padding-right: 10px;
    }

    &.SemiSmall {
        min-width: $semiSmallMinCellWidth;
        padding-right: 10px;
    }

    &.Large {
        min-width: $largeMinCellWidth !important;
    }

    &.SemiLarge {
        min-width: $semiLargeMinCellWidth !important;
    }

    span {
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        overflow: hidden;

        &.CellCurrency {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 0.7rem;
            padding-left: 5px;
        }
    }

    &.FilterCell {
        background-image: none;
        display: none;
        min-width: 0;
        max-width: 50px;
        padding: 0 10px;
        order: 0;
        @include bp('tablet') {
            padding: 0;
        }
    }

    &.ButtonCell {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $MainColor3;
        position: relative;
        justify-content: center;
        white-space: nowrap;
        padding: 0 10px;
        width: 250px;
        @include bp('tablet') {
            flex-direction: column;
            padding: 10px 0;
            padding-left: 10px;
            width: 100px;
        }

        .Btn {
            align-items: center;
            display: flex;
            justify-content: center;
            height: 30px;
            overflow: hidden;
            font-size: 12px;
            font-weight: 300;
            padding-left: 15px;
            padding-right: 15px;
            text-overflow: ellipsis;
            width: 100%;
        }
        .DepositBtn,
        .WithdrawBtn {
            margin-left: 20px;
            @include bp('tablet') {
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }

    &.NoPaddingRight {
        padding-right: 0;
    }

    &.CheckboxCell {
        background-image: none;
        min-width: 0;
        max-width: 40px;
        padding-left: 10px !important;
        padding-right: 10px !important;

        min-width: 40px;
    }

    &.RowOptionCell {
        min-width: 50px;
        max-width: 50px;
        padding: 0;
        order: 1;
        overflow: visible;

        .Icon {
            max-width: 100%;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    &.RowImage {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding-right: 10px;
        // width: 60px;

        img {
            height: 55%;
        }
    }
}

@mixin table-body-default-row {
    display: flex;
    justify-content: space-between;
    // cursor: pointer;

    // &:nth-child(even) {
    //     .Cell {
    //         background-color: $MainColor5;
    //     }
    // }
    &.Highlight .Cell {
        background-color: $MainColor8 !important;
    }

    &.ColourFilter_red .Cell {
        background-color: rgba(255, 0, 0, 0.7) !important;
    }

    &.ColourFilter_green .Cell {
        background-color: rgba(0, 128, 0, 0.7) !important;
    }

    &.ColourFilter_blue .Cell {
        background-color: rgba(0, 0, 255, 0.7) !important;
    }

    &.ColourFilter_purple .Cell {
        background-color: rgba(128, 0, 128, 0.7) !important;
    }

    &.ColourFilter_yellow .Cell {
        background-color: rgba(255, 255, 0, 0.7) !important;
    }

    &.ColourFilter_aqua .Cell {
        background-color: rgba(0, 255, 255, 0.7) !important;
    }

    &.NoHover {
        cursor: default;
    }
}

@mixin right-left-box-shadow {
    -webkit-box-shadow: inset 10px -30px 6px -6px $MainColor7,
        inset -10px -30px 6px -6px $MainColor7;
    -moz-box-shadow: inset 10px -30px 6px -6px $MainColor7, inset -10px -30px 6px -6px $MainColor7;
    box-shadow: inset 10px -30px 6px -6px $MainColor7, inset -10px -30px 6px -6px $MainColor7;
    z-index: 10;
}

/* Main Wrapper */
.FlexTableWrapper {
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    * {
        box-sizing: border-box;
    }

    &.NoHover {
        .TableContainer .RightTable .TableBody .Row,
        .TableContainer .LeftSideTable .TableBody .Row {
            cursor: default;
        }
    }

    &.LargeRow {
        .Cell {
            height: 60px !important;
        }
    }
}

.FlexTableWrapper .Inner {
    background: #ffffff;
    height: 100%;
}

.FlexTableWrapper .TableContainer {
    display: flex;
    width: 100%;
}

.FlexTableWrapper .TableContainer .Head .Cell {
    // border-bottom: 1px solid $MainColor3 !important;

    .SortIcon {
        @include cell-sort-by-icon('../../assets/ibanera/Icon_SortBy_01.png');
    }
}

/* Left side table */

.FlexTableWrapper .TableContainer .LeftSideTable {
    width: 60%;
    @include bp('tablet') {
        width: fit-content;
    }
}

.FlexTableWrapper .TableContainer .LeftSideTable .Head {
    @include table-default-head();

    &.SingleRowSelect {
        .Cell:first-child {
            padding-left: 40px;
        }
    }
}

.FlexTableWrapper .TableContainer .LeftSideTable .Head .Cell {
    @include table-head-default-cell();

    .SortIcon {
        @include cell-sort-by-icon('../../assets/ibanera/Icon_SortBy_01.png');
    }

    &:first-child {
        padding-left: 0px;
    }

    span {
        padding-right: 20px;
    }
}

.FlexTableWrapper .TableContainer .LeftSideTable .TableBody .Row {
    @include table-body-default-row();

    &.Selected {
        .Cell {
            background-color: $RowSelectedColour;
        }
    }

    &.NoHover {
        cursor: default;
    }
}

.FlexTableWrapper .TableContainer .LeftSideTable .TableBody .Row .Cell {
    @include table-body-default-cell();

    &:first-child {
        // padding-left: 20px;
    }

    &.BoldText {
        font-weight: bold;
    }

    &.GreyText {
        color: $MainColor3;
    }
    p {
        margin-bottom: 0;
    }
    h3 {
        margin-bottom: 0;
    }
    .RowImage {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding-right: 10px;
        // width: 60px;

        img {
            height: 55%;
        }
    }
}

/* Middle Table */

.FlexTableWrapper .TableContainer .MiddleTable {
    width: 100%;
    overflow: auto;
}

.FlexTableWrapper .TableContainer .MiddleTable .Head {
    @include table-default-head();
}

.FlexTableWrapper .TableContainer .MiddleTable .Head .Cell {
    @include table-head-default-cell();

    .SortIcon {
        @include cell-sort-by-icon('../../assets/ibanera/Icon_SortBy_01.png');
    }

    p {
        margin-bottom: 0;
    }
    h3 {
        margin-bottom: 0;
    }
    &.BoldText {
        font-weight: bold;
    }

    &.GreyText {
        color: $MainColor3;
    }
    &.HighLowCell {
        min-width: 140px;
    }
}
.FlexTableWrapper .TableContainer .MiddleTable .Head .Cell:first-child {
    padding-left: 0px;
    min-width: calc(#{$mediumMinCellWidth} + 20px);

    &.NoPaddingLeft {
        padding-left: 0;
    }
}

.FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row {
    @include table-body-default-row();
    &.Selected {
        .Cell {
            background-color: $RowSelectedColour;
        }
    }
}

.FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell {
    @include table-body-default-cell();

    div {
        font-weight: 600;
    }

    &.BoldText {
        font-weight: bold;
    }

    &.GreyText {
        color: $MainColor3;
    }
    p {
        margin-bottom: 0;
    }
    h3 {
        margin-bottom: 0;
    }

    &.HighLowCell {
        min-width: 140px;
    }

    .RowImage {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding-right: 10px;
        // width: 60px;

        img {
            height: 55%;
        }
    }

    .NameCell {
        display: flex;
        flex-direction: column;
        height: 55%;
        justify-content: space-between;

        .Name {
            font-weight: 600;
        }

        .Ticker {
            font-size: 10px;
            line-height: 12px;
        }
    }

    .PriceCell {
        display: flex;
        align-items: flex-end;
    }

    .CurrencyLabel {
        font-size: 10px;
    }
}
.FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell:first-child {
    padding-left: 0px;
    min-width: calc(#{$mediumMinCellWidth} + 20px);

    &.NoPaddingLeft {
        padding-left: 0;
    }
}

.FlexTableWrapper .TableContainer .RightTable .Head {
    @include table-default-head();
}

.FlexTableWrapper .TableContainer .RightTable .Head .Cell {
    @include table-head-default-cell();
    // border-bottom: 1px solid $MainColor3;

    .SortIcon {
        @include cell-sort-by-icon('../../assets/ibanera/Icon_SortBy_01.png');
    }
}

.FlexTableWrapper .TableContainer .RightTable .TableBody .Row {
    @include table-body-default-row();

    &.Selected {
        .Cell {
            background-color: $RowSelectedColour;
        }
    }

    &.NoHover {
        cursor: default;
    }
}

.FlexTableWrapper .TableContainer .RightTable .TableBody .Row .Cell {
    @include table-body-default-cell();
    border-bottom: none;

    // &:first-child {
    //   border-left: 1px solid $MainColor3;
    // }

    &.BoldText {
        font-weight: bold;
    }

    &.GreyText {
        color: $MainColor3;
    }

    &:only-child {
        // border-bottom: 1px solid $MainColor3;
        justify-content: center;
        padding-right: 0;
    }
}
.CryptoHoldings {
    margin-bottom: 40px;
}
.CryptoHoldings .FlexTableWrapper .TableContainer .LeftSideTable {
    width: fit-content;
}

@media only screen and (max-width: 1200px) {
    .FlexTableWrapper {
        transition: none;
    }

    .FlexTableWrapper.CalculateWidth {
        max-width: calc(100vw - 100px);
    }

    .SmallerWidth {
        max-width: auto;

        .TableContainer .MiddleTable {
            overflow: auto;
        }
    }
}
@media only screen and (max-width: 1024px) {
    .FlexTableWrapper.CalculateWidth {
        max-width: calc(100vw - 80px);
    }
}
@media only screen and (max-width: 768px) {
    .FlexTableWrapper.CalculateWidth {
        max-width: calc(100vw - 60px);
    }
}
@media only screen and (max-width: 480px) {
    .FlexTableWrapper.CalculateWidth {
        max-width: calc(100vw - 40px);
    }
}
@media only screen and (max-width: 360px) {
    .FlexTableWrapper.CalculateWidth {
        max-width: calc(100vw - 20px);
    }
}

// Overrides for specific instances
.ExchangePortfolioTableContainer {
    .FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell:first-child {
        min-width: 200px;
    }
}

.EmptyTable {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
    width: 100%;

    .EmptyTableImage {
        height: auto;
        width: 150px;
    }

    .Message {
        font-weight: 500;
        margin-top: 30px;
    }
}
