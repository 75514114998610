.LanguageDropdown {
    height: 60px;
    display: flex;
    align-items: center;

    .ClickOffCatcher {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .Flag {
        height: 28px;
        width: 28px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        cursor: pointer;
    }

    .CustomMenuContainer {
        .CustomMenu {
            position: absolute;
            top: 30px;
            right: -15px;
            width: 217px;
            font-size: 12px;
            box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);

            .Label {
                padding: 10px;
                border-bottom: 1px solid $MainColor5;

                span {
                    font-size: 14px;
                }
            }

            .Label:before {
                content: '';
                position: absolute;
                top: -15px;
                right: 20px;
                margin: auto;
                height: 20px;
                width: 20px;
                background-color: $BasicColor1;
                clip-path: polygon(50% 0%, 15% 100%, 85% 100%);
            }
        }
    }
    .CustomControl {
        .InputField {
            display: none;
        }
    }

    .LanguageDropdown__menu-list {
        .LanguageDropdown__option {
            cursor: pointer;
            background-color: $BasicColor1;
            &.LanguageDropdown__option--is-selected {
                background-color: $MainColor4;
            }
        }
    }
}
