.LoadingScreen {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: 30;

    .Text {
        height: 30px;
        opacity: 0;
        transition: opacity 0.25s linear;

        &.Showing {
            opacity: 1;
        }
    }
}
