.SwitchAccountDropdown {
    max-width: 300px;
    flex-grow: 1;
    &.ModalSelect {
        padding: 5px 10px;
        border: 1px solid $MainColor3;
        border-radius: 3px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        &:hover {
            cursor: pointer;
            border-color: $DynamicColor1a;
        }
    }

    .SwitchAccount {
        &__control {
            border-color: $MainColor3;

            &--is-focused {
                background-color: $DynamicColor6;
            }
        }
        &__single-value {
            color: $BasicColor2;
            font-size: 0.75rem;
        }
        &__indicators {
            .SwitchAccount {
                &__indicator-separator {
                    display: none;
                    position: absolute;
                    visibility: hidden;
                }
                &__dropdown-indicator svg {
                    fill: $MainColor9;
                }
            }
        }
        &__menu {
            font-size: 0.75rem;
            color: $BasicColor2;
        }
    }
}
