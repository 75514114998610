.DividingLine {
    &.SmallPad {
        margin: 10px 30px !important;
    }
}
// #region TransferNavLink
.BulkTransferNavLink {
    text-decoration: none;

    .Chevron {
        display: none;
        width: 44px;
        height: 44px;
        margin-left: auto;
        opacity: 0.5;
        transform: rotate(270deg);
    }
    &.MobileMenu {
        display: flex;
        align-items: center;
        gap: 8px;
        .TextIcon {
            background-color: $MainColor1;
        }
        .LinkDetails {
            color: $MainColor1;
            font-size: 14px;
            font-weight: 600;
        }
        .Chevron {
            display: block;
        }
    }
}
// #endregion TransferNavLink

// #region BulkTransfer
.BulkTransferWrapper {
    .RestrictWidth {
        max-width: 500px;
        .Back {
            align-self: flex-start;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 10px;
        }
        .BulkTransferHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            h1 {
                margin-bottom: 0;
            }
            .DownloadTemplate {
                width: unset;
            }
        }
        .Dropzone {
            max-width: 500px;

            &.Hover {
                border-width: 2px;
                background-color: $MainColor4;
                background-color: lighten($MainColor4, 1%);
            }
        }
    }
    .BulkTransferError {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .Loading {
        position: relative;
        max-width: 500px;

        .Spinner {
            position: relative;
            top: unset;
            left: unset;
        }
    }

    .BulkTransferList {
        max-width: 800px;
        animation: fadeIn 0.7s;
        transform-origin: top center;

        .CollapseHeader {
            padding: 10px 15px;
            &.Expand {
                .CollapsedIcon {
                    display: flex !important;
                }
                .ExpandIcon {
                    display: none !important;
                }
            }
            .BulkTransferItemHeader {
                flex-grow: 1;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10px;

                @include bp('mobile') {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr max-content;
                }

                .HeaderInfo {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    flex-grow: 1;

                    @include bp('mobile') {
                        .SourceAccount,
                        .TransferType,
                        .Amount {
                            display: none;
                        }
                    }

                    .LeftSection {
                        flex-grow: 100;
                        .PayeeName {
                            font-weight: bold;
                            color: $BasicColor2;
                            font-size: 0.75rem;
                        }

                        @include bp('mobile') {
                            flex-grow: 0;
                        }
                    }
                }
                .AmountWrapper {
                    min-width: 120px;
                    .Amount {
                        font-size: 0.85rem;
                        font-weight: bold;
                    }
                }
                .StatusPill {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 20px;
                    font-size: 0.8rem;
                    color: $BasicColor1;
                    font-weight: 500;
                    background-color: $DynamicColor2;
                    padding: 0px 10px;
                    border-radius: 15px;
                    min-width: 70px;

                    &.Success {
                        background-color: $AlertGood;
                    }
                    &.Error {
                        background-color: $AlertBad;
                    }
                }
                .TransferType {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 20px;
                    font-size: 0.8rem;
                    color: $BasicColor1;
                    font-weight: 500;
                    background-color: $DynamicColor2;
                    padding: 0px 10px;
                    border-radius: 15px;
                    min-width: 70px;

                    &--NONE {
                        background-color: transparent;
                        border: 1px solid $DynamicColor2;
                        color: $BasicColor2;
                    }

                    &--WIRE {
                        background-color: $DynamicColor2;
                    }
                    &--ACH {
                        background-color: $DynamicColor3;
                    }
                    &--RTP {
                        background-color: $AlertNeutral;
                    }
                    &--CROSSBORDER {
                        background-color: #008c6e;
                    }
                    &--SWIFT {
                        background-color: $DynamicColor1;
                    }
                    &--FEDWIRE {
                        background-color: $AlertGood;
                    }
                }
                .MobileInfo {
                    display: none;
                    grid-column: span 2;

                    @include bp('mobile') {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }
                }
                .Icons {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    .CollapsedIcon,
                    .ExpandIcon,
                    .EditIconButton,
                    .DeleteIconButton {
                        box-sizing: content-box;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        outline: none;
                        background-color: transparent;
                        border: none;
                        width: 16px;
                        min-width: 16px;
                        padding: 0 7.5px;
                    }
                    .CollapsedIcon {
                        display: none;
                    }
                }
            }
        }
        .BulkTransferItemContent {
            display: flex;
            gap: 15px;
            overflow: hidden;

            &.Wrap {
                flex-wrap: wrap;

                .MemoNotes .LabelValuePair {
                    margin-bottom: 10px;
                }
            }

            @include bp('small-tablet') {
                flex-wrap: wrap;
            }

            > * {
                flex-shrink: 0;
            }
            .MemoNotes {
                flex-shrink: 1;

                @include bp('mobile') {
                    .LabelValuePair {
                        flex-direction: column;
                        gap: 0;
                    }
                }

                .Memo .Value {
                    font-style: italic;
                }
            }

            .LabelValuePair {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                margin-bottom: 3px;
                color: $BasicColor2;

                &.MarginBottom {
                    margin-bottom: 1rem;
                }

                .Label {
                    font-weight: bold;
                }
                .Value {
                    text-wrap: wrap;
                }
            }
        }
        .Collapse {
            margin-bottom: 0;
            &:not(:first-child) {
                margin-top: 10px;
            }
            &.Success {
                border-color: $AlertGood;
                background-color: $AlertGoodBackground;
            }
        }
        .BulkTransferButtons {
            margin-top: 10px;
            display: flex;
            gap: 10px;
            @include bp('mobile') {
                flex-direction: column;
            }
        }
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: rotateX(20deg);
    }
    80% {
        transform: rotateX(0);
    }
    100% {
        opacity: 1;
    }
}
// #endregion BulkTransfer

// #region EditTransferModal
.BulkTransferWrapper .EditTransferModal {
    width: 100%;
    max-width: 750px;
    .ModalContent {
        padding-top: 30px;
        flex-grow: 1;

        h4 {
            text-transform: uppercase;
            color: #505050 !important;
            font-size: 0.75rem;
        }
        h2 {
            margin-top: 1rem;

            &:first-of-type {
                margin-top: 0;
            }
        }
        textarea {
            resize: vertical;
            min-height: 130px;
            max-height: 300px;
        }
    }
}
// #endregion EditTransferModal
