.SubNavigationMenu {
    align-items: center;
    background-color: $MainColor4;
    display: flex;
    min-height: 60px;
    height: 100%;
    padding: 0 60px;
    width: 100%;

    @include bp('tablet') {
        padding: 0 10px;

        &.HideLinkOptions {
            .BalanceDisplay {
                flex: unset;
                padding-top: 0;
            }
        }
    }
    @include bp('small-tablet') {
        flex-direction: column;
        align-items: flex-start;
        &.HideLinkOptions {
            justify-content: center;
        }
    }

    .BalanceDisplay {
        display: flex;
        gap: 1.5rem;
        margin-right: 2rem;
        padding-top: 5px;
        flex: 1;

        @include bp('tablet') {
            margin-left: 1rem;
            flex: unset;
        }
        @include bp('small-tablet') {
            padding-top: 1rem;
            width: 100%;
            flex-wrap: wrap;
            row-gap: 10px;
        }

        .BalanceDisplayItem {
            display: flex;
            flex-direction: column;

            dd {
                font-size: 0.8rem;
                line-height: 0.8rem;
                font-weight: bold;
                white-space: nowrap;
            }
            dt {
                font-style: italic;
                font-size: 0.65rem;
            }
        }
    }

    .SubNavigationMenuWrapper {
        display: flex;
        height: 60px;
        flex: 2;

        -ms-overflow-style: none;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 60px;
        scrollbar-width: none;
        width: 100%;

        @include bp('tablet') {
            padding: 0 10px;
            // Hiding scrollbar
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .Hidden {
        width: 1px;
        flex-grow: 0;
        flex-shrink: 1;

        @include bp('tablet') {
            display: none;
        }
    }

    .Link {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 60px;
        margin: 0 20px;
        text-decoration: none;
        white-space: nowrap;

        @include bp('tablet') {
            padding: 0 10px;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .LinkText {
        color: $BasicColor2;
        font-size: 12px;
        font-weight: 600;
    }
}
