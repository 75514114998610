@import 'styles/variables.scss';

.MonthlyStatementsPage {
    display: flex;
    flex-direction: row;
    display: flex;
    @include bp('tablet') {
        justify-content: center;
    }
    .Content {
        width: min(500px, 100%);
    }
    .FillerImage {
        position: relative;
        left: 30px;
        margin: 40px auto auto;
        max-width: min(40%, 400px);
        height: auto;

        @include bp('tablet') {
            display: none;
        }
    }
    [class~='react-datepicker'] {
        font-size: 1rem;
    }
}
