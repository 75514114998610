.RegistrationForm {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
    margin-top: 60px;
    position: relative;

    &.RegistrationFormWidth {
        max-width: 700px;
    }

    @include bp('tablet') {
        margin-bottom: 60px;
        width: 100%;
    }

    @include use-width-content;

    .RegisterFormWidth {
        width: 400px;
        .FormBox {
            width: 100%;
        }
        .CustomSelect__menu {
            top: 100% !important;
            right: 0;
            left: 0;
        }
        .CustomSelect__menu-list {
            background-color: $BasicColor1;
            box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
        }
        .QrCodeContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .QrCode {
                width: 200px;
            }
        }
        .CurrencyDropdownField {
            .FormSideBySide {
                display: block;
            }
        }
    }

    .RegisterFormButton {
        margin-top: 30px;
        width: 100%;
    }

    .SelectAccountType {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .RegistrationTile {
        border: 1px solid $MainColor3;
        border-radius: 3px;
        cursor: pointer;
        position: relative;

        &.Selected {
            .RegistrationTileBottom {
                background: none;

                .Checkmark {
                    display: block;
                }
            }
        }
        .Recommended {
            color: #fff;
            position: absolute;
            top: -1px;
            right: -1px;
            height: 30px;
            width: 116px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 3px 0 3px;
            font-size: 12px;
        }
    }

    .RegistrationTileTop {
        display: flex;
        flex-direction: column;
        padding: 23px;
        border-radius: 3px;
    }

    .RegistrationTileLabel {
        font-size: 20px;
        margin-bottom: 29px;
    }

    .RegistrationTileSubText {
        font-size: 12px;
        position: relative;
        bottom: 20px;
    }

    .RegistrationTileImage {
        align-self: center;
        height: auto;
        width: 90%;
    }

    .RegistrationTileBottom {
        align-items: center;
        display: flex;
        height: 41px;
        justify-content: center;
    }

    .CheckmarkContainer {
        background-color: $MainColor8;
        border: 1px solid $MainColor3;
        border-radius: 50%;
        height: 20px;
        width: 20px;

        img {
            margin-left: -8px;
            margin-top: -8px;
            display: none;
            height: 35px;
            width: 35px;
        }
    }

    .RegisterFormRedirect {
        text-align: center;
        .FormLink {
            color: $DynamicColor2;
            text-decoration: underline;
        }
    }

    .EmailVerificationForm {
        @include bp('tablet') {
            width: 100%;
        }
    }

    .SuccessMessage {
        width: 340px;

        @include bp('tablet') {
            width: 100%;
        }
    }

    .TFAInputContainer {
        position: relative;

        .InputMask {
            height: 40px;
            pointer-events: none;
            position: absolute;
            top: 0;
            top: 23px;
            width: 100%;
        }
    }
}

.FormBuilderComponent {
    width: 100%;
    .FormSideBySide {
        display: block;
        .FormField {
            width: 100%;
        }
    }

    .react-datepicker__input-container {
        input {
            height: 40px;
        }
    }
}

.NameConfirmation .CustomInput {
    height: fit-content;
}

/* Phone Number Input */
.PhoneNumberBox {
    &.SideBySide.FormBox {
        width: calc(50% - 10px);
        @include bp('tablet') {
            width: 100%;
        }
    }
    .PhoneInput {
        margin-bottom: 0;
    }
}
.PhoneInput {
    margin-bottom: 20px;
    .PhoneInputCountry {
        padding: 10px;
        background-color: $MainColor4;
        border: 1px solid $MainColor3;
        border-radius: 3px;
        height: 40px;
    }
    .PhoneInputCountryIcon {
        border-radius: 3px;
        img {
            border-radius: 3px;
        }
    }
    .PhoneInputInput {
        padding: 10px 15px;
        background-color: $MainColor4;
        border: 1px solid $MainColor3;
        border-radius: 3px;
        height: 40px;
    }
}

@include bp('tablet') {
    .RegistrationForm {
        .SelectAccountType {
            flex-direction: column;
            width: 100%;
            .RegistrationTile {
                width: 100%;
            }
            .RegistrationTile:first-of-type {
                margin-bottom: 20px;
            }
        }
        .RegisterFormWidth {
            width: 100%;
        }
    }
}

.ChangeNumberButton {
    left: 0;
    padding: 0 !important;
}

#TaxNumberTooltip {
    background-color: white;
    color: #060415;
    padding: 8px 12px; /* Padding */
    border-radius: 4px;
    border-color: $MainColor3;
    font-size: 1em;
    max-width: 250px; /* Maximum width */
    height: fit-content;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.TooltipTag {
    font-weight: normal;
    font-size: 0.8em;
    color: #060415;
    border: 1px solid $MainColor3;
    text-align: center;
    vertical-align: middle;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    margin-left: 5px;
}
