.RadioIdButtons {
    flex-wrap: wrap;
    height: unset !important;
    gap: 10px;

    .LoadingOptions {
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, $MainColor5 8%, $MainColor4, $MainColor5 54%);
        background-size: 1000px 640px;
        position: relative;
        height: 40px;
        width: 100%;
    }

    .RadioButton {
        min-height: 40px;
        height: unset;
        margin-right: 0;

        ::before {
            min-width: 20px;
            margin-right: 0;
        }
        span {
            align-items: center;
            gap: 10px;
        }
    }
}

.RadioButton {
    border: 1px solid $MainColor3;
    background-color: $BasicColor1;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    flex: 1 1;
    cursor: pointer;
    line-height: 20px;
    height: 40px;

    &:disabled {
        cursor: default;
    }

    .SubLabel {
        margin-left: 40px;
        font-size: 0.75rem;
        color: $MainColor1;
        text-align: left;
    }

    :first-child::before {
        content: ' ';
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        display: inline-block;
        margin-right: 10px;
        background-color: $MainColor4;
        border-radius: 50%;
        border: 1px solid $MainColor3;
    }

    span {
        display: flex;
        align-items: center;
    }

    &.Selected {
        :first-child::before {
            background-image: url('../../assets/ibanera/Icon_Tick_White.png');
            background-size: 35px;
            background-position: center;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.RadioButtons {
    display: flex;
    flex: 1;
    max-width: 100%;
    margin-bottom: 20px;

    &--error {
        margin-bottom: 10px;
    }
}
.RadioButtons .Radio {
    margin-left: 20px;
}
.RadioButtons .Radio:first-child {
    margin-left: 0;
}
.RadioButtons .Radio label {
    padding-left: 25px;
}
