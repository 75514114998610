.SideMenu {
    height: calc(100% - 60px);
    width: 260px;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: $MainColor4;
    overflow-y: auto;

    @include bp('tablet') {
        display: none;
    }

    .SideMenuLink {
        height: 50px;
        padding: 0 15px;

        .AccountValue {
            color: $DynamicColor1;
        }
        &.Active {
            .AccountValue {
                color: $DynamicColor2;
            }
        }
        .LinkDetails {
            font-weight: 700;
        }

        &.Link {
            padding: 0 15px;
        }

        &.Card {
            &.CardPending {
                pointer-events: none;

                .TextIcon {
                    .SvgCurrencyIcon,
                    .SvgIcon {
                        fill: lightgrey;
                    }
                }

                svg {
                    opacity: 0.4;
                    filter: saturate(0);
                }

                .AccountValue {
                    color: grey;
                }
            }
        }
    }

    .ExpandButton {
        text-align: center;
        line-height: 25px;
        font-size: 50px;
        cursor: pointer;
        &.Expanded {
            font-size: 12px;
            text-decoration: underline;
            line-height: 50px;
        }
    }

    ul {
        list-style: none;

        .DividingLine {
            border-bottom: 1px solid $MainColor3;
            margin: 15px 30px;
        }
        li {
            height: fit-content;

            .DashboardLink {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-bottom: 1rem;

                h3 {
                    color: $BasicColor1;
                    margin-bottom: 0;
                }
                span {
                    font-size: 12px;
                    color: $BasicColor1;
                    font-weight: 500;
                }

                &.NoHover:hover {
                    background-color: $DynamicColor1;
                }
            }

            .SideMenuButton {
                background: none;
                border: 1px solid $MainColor3;
                color: $MainColor1;
                font-size: 0.8em;
                padding: 5px 30px;
                width: 100%;
                margin: 0;
            }

            a,
            .Link {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                text-decoration: none;
                height: 60px;
                padding: 0 25px;
                border-left: 5px solid $MainColor4;
                position: relative;
                cursor: pointer;

                &:hover {
                    background-color: $DynamicColor6;
                }

                &.ButtonLink {
                    height: 24px;
                    margin-top: 15px;
                }

                img {
                    height: 40px;
                    margin-right: 10px;
                }
                .CardImg {
                    width: 40px;
                    height: auto;
                    margin-left: 10px;
                    margin-right: 10px;
                }

                .TextIcon {
                    height: 24px;
                    min-width: 24px;
                    width: 24px;
                    margin-right: 15px;
                    margin-left: 10px;
                    border-radius: 50%;

                    h3 {
                        color: white;
                        line-height: 24px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
                .AccountDetails {
                    width: 100%;
                    overflow: hidden;

                    .AccountValue {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .AccountName {
                    color: $MainColor1;
                    font-size: 0.7em;
                    font-weight: 600;
                }
                .AccountValue,
                .BigAccountName {
                    font-size: 1.1em;
                }
                .AccountNumber {
                    font-size: 0.7em;
                    color: $MainColor1;
                }
                .CardNumber {
                    margin-bottom: 0;
                    font-size: 0.7em;
                    font-weight: 400;
                    line-height: normal;
                }
                .CardIcons {
                    position: absolute;
                    bottom: 0;
                    height: 20px;
                    img {
                        height: 20px;
                    }
                }
            }

            > h5 {
                font-size: 10px;
                font-weight: 800;
                letter-spacing: 2px;
                margin-bottom: 5px;
                padding: 0 30px;
                text-transform: uppercase;
            }

            a.Active,
            .Link.Active {
                background-color: $BasicColor1;
            }
        }
    }

    .IconHidden {
        display: none;
    }
}
