.InitialsIcon {
    align-items: center;
    border-radius: 20px;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;

    span {
        color: white;
        font-size: 14px !important;
        font-weight: 600 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
