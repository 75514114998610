.Header {
    background-color: #fff;
    border-bottom: 1px solid $MainColor3;
    height: 60px;
    min-width: 320px;
    padding: 0 30px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 25;

    @include use-width-content;

    .InnerHeader {
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: space-between;
        min-width: 100%;

        .Logo {
            align-items: center;
            display: flex;
            height: 60px;
            width: fit-content;

            img {
                height: 34px;
            }
        }

        .Actions {
            align-items: center;
            display: flex;
            height: 100%;
            flex-grow: 1;
            justify-content: flex-end;
            margin-left: 10px;

            .LanguageDropdown {
                margin-right: 22px;
            }

            .Buttons {
                display: flex;
                width: fit-content;

                a {
                    min-width: 110px;
                    text-decoration: none;
                    width: 120px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }

            .MessagesButton {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                height: 100%;
                margin-right: 30px;

                @include bp('tablet') {
                    margin-right: 10px;
                }

                img {
                    height: 100%;
                    width: auto;
                }
            }

            .SwitchButton {
                margin-left: 30px;

                @include bp('tablet') {
                    display: none;
                }
            }
        }
    }
}
