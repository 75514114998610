.UserDropdown {
  margin-right: 25px;
  position: relative;

  @include bp('tablet') {
    margin-left: 22px;
    margin-right: 0;
    width: unset;
  }

  .ClickOffCatcher {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .OpenIcon {
    height: 100%;
    position: absolute;
    transform: translateY(-14px);
  }

  .OpenButton {
    
    &.Desktop {
      margin-right: 30px; 
      padding: 0;
      position: relative;
      white-space: nowrap;

      @include bp('tablet') {
        display: none;
      }
    }

    &.Mobile {
      border-radius: 50%;
      box-sizing: content-box;
      display: none;
      height: 28px;
      padding: 0;
      user-select: none;
      width: 28px;


      @include bp('tablet') {
        display: block;
      }

      img {
        display: none;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .Menu {
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(black, 0.1);
    font-weight: 500;
    position: absolute;
    right: -55px;
    top: 68px;
    width: 217px;

    @include bp('tablet') {
      right: -32px;
    }

    p {
      color: $MainColor10;
    }
  }

  .Top {
    padding: 16px 20px;
    text-decoration: none;

    a {
      text-decoration: none;
    }

    .Name {
      font-size: 14px;
      margin-bottom: 6px;
    }
  }

  .Middle {
    border-bottom: 1px solid $MainColor4;
    border-top: 1px solid $MainColor4;
    padding: 8px 0;
  }

  .DropdownOption {
    cursor: pointer;
    padding: 5px 20px;

    
    &:hover {
      background-color: $MainColor4;
    }
    
    a {
      text-decoration: none;
    }
  }

  .SignOut {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  // https://css-tricks.com/snippets/css/css-triangle/
  .Triangle {
    border-bottom: 15px solid white; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent; 
    height: 0;
    left: 160px;
    position: absolute;
    top: -10px;
    width: 0;
  }
}