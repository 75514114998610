.AuthFormContainer {
    display: flex;
    justify-content: center;

    @include bp('tablet') {
        width: 100%;
    }

    @include use-width-content;
}

.ReactivateAccount {
    &.Success {
        width: 340px;
    }
}

.AuthForm {
    margin-bottom: 60px;
    width: 340px;

    @include bp('tablet') {
        margin-bottom: 0;
        width: 100%;
    }

    &.ReactivateAccount {
        h2 {
            margin-bottom: 10px;
            color: $AlertColor3;
        }
    }

    .FieldsContainer {
        .RememberMe {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
        }

        .Redirect {
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }

        .RememberMe,
        .Redirect {
            span {
                font-size: 12px;
            }
        }

        .Label {
            font-size: 12px;
            color: $MainColor1;
        }

        .UseSMS {
            text-align: end;
            font-size: 12px;
            text-decoration: underline;
            margin-bottom: 30px;
            cursor: pointer;
        }

        .TFAInputContainer {
            position: relative;

            .InputMask {
                height: 40px;
                pointer-events: none;
                position: absolute;
                top: 0;
                top: 23px;
                width: 100%;
            }
        }

        .PhoneNumberField {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ErrorLabel {
                display: none;
            }

            .CountryCodeDropdown {
                width: 70px;
                margin-bottom: 13px;

                .CodeSelectValueContainer {
                    position: relative;
                    width: 70px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    background-color: $MainColor4;
                    border: 1px solid $MainColor3;
                    border-radius: 3px;
                    padding: 5px;

                    .CountryFlag {
                        width: 20px;
                        height: 15px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 3px;
                        margin-right: 5px;
                    }

                    .DownChevronContainer {
                        width: 20px;
                        height: 20px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 100%;
                        }
                    }
                }

                .css-26l3qy-menu {
                    width: 70px !important;
                    position: absolute !important;
                    top: 0px !important;
                    left: 0;

                    .css-oqc6hu-MenuList {
                        background-color: $BasicColor1 !important;
                        border-radius: 3px;
                    }
                }
            }

            .FormBox {
                width: 265px;
            }
        }

        .SignInErrorMessage {
            color: $BasicColor5;
            display: block;
            margin-bottom: 12px;
        }

        .PhoneNumberError {
            transform: translateY(-20px);
        }
    }
    .AlertNotification {
        margin-top: 15px;
        background-color: #F7DCB9;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
        display: flex;
        gap: 25px; 
        padding-left: 15px;
        padding-right: 15px;


        .WarningIcon {
            width: 55px;

        }

        .NotificationText {
            position: relative;
            top: 10px;
            font-size: 16px;
            gap: 0px;

            .SubText {
                font-size: 13px;
                position: relative;
                bottom: 15px;
            }
            
        }

    }
}

.Spinner.noBG-absCenter {
    transform: translate(-50%, -50%);
    background: none;
}


