.ManageVACustomers {
    .PageHeader {
        flex-wrap: wrap;
        row-gap: 10px;
        align-items: flex-end;

        @include bp('mobile') {
            align-items: center;
            justify-content: center;
        }
    }
    .HeaderActionsContainer {
        gap: 10px;
        flex-direction: column;
        align-items: flex-end;

        .SearchBoxContainer {
            margin-right: 0;

            @include bp('mobile') {
                width: 100% !important;
            }
        }

        .CustomAddButtons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            @include bp('mobile') {
                flex-direction: column;
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }
    // Create Action
    .RightPanel .Form {
        h4 {
            margin-top: 1rem;
        }
        .react-datepicker__input-container input {
            height: 40px;
        }
        .CustomSelect__option--is-disabled {
            cursor: default;
            opacity: 0.7;
            background-color: $MainColor8;
        }
    }
    // View Action
    .VACustomerAccountsList {
        > h4 {
            margin-top: 20px;
        }
        .Collapse {
            &.Pending {
                .CollapseHeader span {
                    color: $AlertBad;
                    font-style: italic;
                }
            }

            .CollapseHeader h4 {
                margin-top: 0;
            }
        }
        .FormSideBySide {
            margin: 10px 0 0;
        }
    }
}

// #region Transactions
.VaCustomerTransactions {
    .Loading {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff50;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .VATransactionsHeader {
        flex-direction: column;
        margin-bottom: 1rem;
        .Title {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;

            h1 {
                margin-bottom: 0;
            }
            .BackButton {
                background-color: transparent;
                border: 1px solid $MainColor3;
                border-radius: 3px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                box-sizing: content-box;
                margin-bottom: 0;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .Controls {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            .AccountSelect {
                width: 240px;
                .CustomSelect__indicator {
                    padding: 0;
                    padding-right: 8px;
                }
            }
            @include bp('mobile') {
                .SearchBoxContainer,
                .AccountLabel {
                    width: 100%;
                }
                .AccountSelect {
                    width: 100%;
                }
            }
        }
    }
    .VATransactionsTable {
        position: relative;
        display: flex;
        gap: 2rem;
    }
}
// #endregion Transactions
