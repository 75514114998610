.Collapse {
    border: 1px solid $MainColor3;
    border-radius: 3px;
    margin-bottom: 10px;
    .CollapseHeader {
        display: flex;
        align-content: center;
        justify-content: space-between;
        padding: 5px 0px 5px 15px;
        flex-wrap: wrap;
        &:hover {
            cursor: pointer;
        }
        &.Expand {
            border-bottom: 1px solid $MainColor5;
        }
        h4 {
            margin-bottom: 0px;
            color: black;
            font-weight: bold;
            align-self: center;
        }
        .Chevron {
            height: 35px;
            width: 35px;
            align-self: center;
            transform: rotate(180deg);
            &:hover {
                cursor: pointer;
            }
        }
        .Chevron.ChevronExpand {
            transform: rotate(270deg);
        }
    }
    .CollapseContent {
        padding: 15px;
    }
    .ArrowWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;

        svg {
            fill: $BasicColor2;
            width: 15px;
            height: 15px;
            transform: rotate(180deg);
        }
    }
    &.HasError {
        border-color: $AlertBad;
        background-color: $AlertBadBackground;
    }
}
