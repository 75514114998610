/* Pagination */
.Pagination {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Pagination .Inner {
    margin-top: 20px;
    display: flex;
    align-content: center;
    align-items: center;
}

.Pagination .Inner button:first-child {
    margin-right: 5px;
}
.Pagination .Inner button:last-child {
    margin-left: 5px;
}

.Pagination .Inner .Info {
    font-size: 1.3em;
    text-align: center;
    padding: 0 20px;
}

.TableNavigation {
    padding: 30px 0 0;
    min-height: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    //z-index: -1;

    @include bp('tablet') {
        flex-direction: column;
    }
    @include bp('small-tablet') {
        padding-top: 10px;
    }
}
.TableNavigation .PaginatorBlank {
    width: 25%;
}
.TableNavigation .PaginatorButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 50%;

    .PaginationButtonsRight,
    .PaginationButtonsLeft {
        display: flex;
        align-items: center;
        gap: 10px;

        @include bp('small-tablet') {
            gap: 5px;
        }
    }

    .PaginatorBtn {
        height: 30px;
        width: 30px;
        border-radius: 3px;
        border: 1px solid $MainColor5;
        cursor: pointer;
        background-color: transparent;

        @include bp('small-tablet') {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            opacity: 0.6;
            cursor: auto;
        }

        img {
            height: 30px;
            width: 30px;

            @include bp('small-tablet') {
                width: 20px;
                height: 20px;
            }
        }
    }

    .PageNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding: 10px 15px;
        font-size: 1.1em;
        color: $MainColor1;
        text-align: center;

        @include bp('small-tablet') {
            gap: 10px;
            padding: 10px;
        }

        .PageNumberInput {
            display: flex;
            align-items: center;
            gap: 15px;

            @include bp('small-tablet') {
                gap: 10px;
            }

            .FormBox {
                margin: 0px;
                .FormField {
                    .DropDown {
                        min-width: 55px;
                        height: 30px;
                        width: 55px;
                        padding: 0px 8px;
                        font-size: 1em;
                        border: 1px solid $MainColor3;

                        @include bp('small-tablet') {
                            height: 22px;
                        }
                    }
                }
            }

            input {
                height: 30px;
                width: 30px;
                background-color: $MainColor4;
                border-radius: 3px;
                border: 1px solid $MainColor3;
                text-align: center;
                font-size: 1em;

                @include bp('small-tablet') {
                    height: 20px;
                }
            }
        }
    }
}
.TableNavigation .PaginatorInfo {
    font-size: 1.1em;
    text-align: right;
    color: $MainColor1;

    @include bp('small-tablet') {
        font-size: 0.9em;
    }
}

/* Items Per Page */
.ItemsPerPage .Inner .Items {
    font-size: 1.3em;
    min-width: 100px;
}
