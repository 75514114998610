@import 'styles/variables.scss';

.InfoRequestNavLink {
    display: flex;
    align-items: center;
    background-color: $MainColor3;
    margin-bottom: 1rem;
    height: unset !important;
    padding: 0px 25px;
    text-decoration: none;
    font-size: 14px;

    &.Mobile {
        padding: 0.5rem 10px;
        border-radius: 3px;
        gap: 10px;

        background-color: $MainColor4;

        img {
            width: 30px;
            height: 30px;
        }
    }

    &:not(.Active) {
        border-left-color: $MainColor3;
    }
    img {
        width: 20px;
        height: 40px;
    }
    .InfoRequestText {
        text-transform: capitalize;
        font-weight: bold;
        color: $DynamicColor5;
    }
}

.InformationRequest {
    display: flex;
    flex-direction: column;
    height: 100%;

    .Description {
        font-size: 0.7rem;
        line-height: 1rem;
    }
    .Loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .Error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        h2,
        p {
            margin-bottom: 0;
        }

        h2 {
            color: $Alert_Red;
        }
    }

    .Table {
        @include bp('small-tablet') {
            display: none;
        }
    }
    .MobileTiles {
        display: none;
        @include bp('small-tablet') {
            display: flex;
        }
    }

    .MoneyIn {
        color: $DynamicColor2;
    }
    .MoneyOut {
        color: $DynamicColor3;
    }

    .Status {
        color: $DynamicColor2;
        font-weight: 600;
        font-size: 0.7rem;
    }
    // Used in mobile and in table - end

    :global(.TableContainer) {
        .MoreInfoButton {
            height: 25px !important;
            font-size: 0.6rem !important;
        }
        .Reference,
        .Date {
            font-size: 0.6rem !important;
        }
    }
    .MobileTiles {
        gap: 1rem;
        flex-wrap: wrap;
        padding-bottom: 2rem;

        .InfoRequestTile {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;
            padding: 1rem;
            border: 1px solid $MainColor3;
            border-radius: 3px;
            min-width: 300px;
            flex: 1;

            .Header {
                display: flex;
                align-items: center;
                gap: 1rem;

                .ToFrom {
                    align-items: center;
                    font-size: 0.8rem;

                    .Letter {
                        font-size: 0.9rem;
                    }
                }
                .Date {
                    flex-shrink: 0;
                    margin-left: auto;
                }
            }

            .Amounts {
                font-size: 0.9rem;
                font-weight: 500;
            }

            button {
                font-size: 0.7rem;
            }
        }
    }
}

// Used in mobile and in table - start
.ToFrom {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.7rem;
    font-weight: 600;

    .Letter {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        min-width: 22px;
        color: $BasicColor1;
        height: 22px;
        background-color: $DynamicColor3;
        font-weight: normal !important;

        &.MoneyIn {
            background-color: $DynamicColor1;
        }
    }
}
