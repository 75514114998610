.InputMask {
    background-color: $MainColor4;
    border: 1px solid $MainColor3;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;

    .Point {
        color: $MainColor3;
        font-size: 24px;
        font-weight: 500;
        transform: translate(-5px, 2px);
        width: 1px;

        &.Filled {
            transform: translate(-5px, 4px);
        }
    }
}
