// .ant-collapse-content-inactive,
// .ant-collapse-content-hidden {
//     height: 0 !important;
//     opacity: 0 !important;
// }
// .ant-collapse-header {
//     display: flex;
// }
// @import 'antd/dist/antd.css';
// *,
// *::before,
// *::after {
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }
// div {
//     margin: 0;
//     padding: 0;
// }
[role='button'] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ant-collapse {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    // box-sizing: border-box;
    // margin: 0;
    // padding: 0;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    // box-sizing: border-box;
    // margin: 0;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 16px;
    display: inline-block;
    font-size: 12px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ant-collapse-content {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
}

.ant-collapse-content.ant-collapse-content-inactive,
.ant-collapse-content.ant-collapse-content-hidden {
    height: 0;
    opacity: 0;
}
.ant-collapse-content.ant-collapse-content-active {
    height: fit-content;
    opacity: 1;
}
