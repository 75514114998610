.ContentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $MainColor3;
    border-radius: 3px;
    padding: 40px 30px;
    margin-bottom: 60px;
    min-width: 330px;
    @include bp('tablet') {
        margin-bottom: 30px;
        min-width: auto;
    }
    &.LeftAlign {
        align-items: flex-start;
    }

    &.Shadow {
        box-shadow: $MainColor5 0px 0px 30px 3px;
    }

    p {
        margin-bottom: 10px;
    }
}

.TableLoaderContainer {
    height: 20px;
}
.FeeColumn {
    flex-direction: column;
}
//Cryptos Market page
.Cryptos {
    display: flex;
    width: 100%;

    .Market,
    .Portfolio {
        width: 100%;
        .MarketHeader {
            display: flex;
            h1 {
                margin-right: 20px;
            }
        }
        .MarketSubHeader {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // margin-bottom: 12px;

            .Left {
                align-items: center;
                display: flex;
            }

            p {
                margin-bottom: 0;
                margin-right: 60px;

                @include bp('tablet') {
                    margin-right: 10px;
                }
            }
            .Btn {
                padding: 5px 10px;
                color: $MainColor1;
                a {
                    text-decoration: none;
                }
            }
        }
        .FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell {
            font-weight: 700;
        }
    }

    &.ExchangeTrade {
        .TableContainer {
            .ButtonCell {
                width: unset !important;
            }
        }
        .ExchangeMarketRowButtons {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .Btn {
                width: unset !important;
            }
        }
    }
}

.FullWidth {
    width: 100%;
}

//Individual Crypto Page
.Crypto {
    .Container {
    }
    .TopContainer {
        display: flex;
        @include bp('tablet') {
            flex-direction: column;
        }
        .LeftContainer {
            flex: 1 1;
        }
        .RightContainer {
            // width: 25%;
            border-radius: 3px;
            height: 100%;
            margin-left: 40px;
            @include bp('tablet') {
                width: 100%;
                margin-left: 0;
            }
            .ContentBox {
                .LinkText {
                    color: $BasicColor2;
                    font-size: 12px;
                    font-weight: 600;
                    cursor: pointer;
                }

                .Refresh {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 1rem;
                    p {
                        margin: 0;
                        span {
                            padding-left: 5px;
                        }
                    }
                    .RefreshBtn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $MainColor1;
                        border-radius: 3px;
                        min-width: 25px;
                        height: 25px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
                .OrderSummaryContainer {
                    width: 100%;
                    .SummarySection {
                        width: 100%;
                        border-bottom: 1px solid $MainColor3;
                    }
                    p {
                        margin: 5px 0;
                    }

                    .FloatRight {
                        float: right;
                    }
                }
                .AuthForm {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    width: 100%;

                    h1 {
                        font-size: 16px;
                        color: $DynamicColor2;
                    }
                }
            }
        }
    }
}

.ReviewAmendedOrder {
    width: min(50vw, 400px);
    .OrderSummaryContainer {
        width: 100%;
        margin-bottom: 10px;
        .SummarySection {
            width: 100%;
            border-bottom: 1px solid $MainColor3;
        }
        p {
            margin: 5px 0;
        }

        .FloatRight {
            float: right;
            font-weight: 600;
        }
        .Green {
            color: $Colour_Great;
        }
        .Red {
            color: $Colour_Weak;
        }
    }
    .ButtonRow {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .Btn {
            width: 45%;
        }
    }
}

.CryptoHoldings {
    .ButtonCell {
        width: unset !important;
    }
}

.MarketTableSearch {
    display: flex;
    margin-top: 10px;
    .MarketTableInput {
        height: fit-content;
        position: relative;
        img {
            position: absolute;
            height: 15px;
            top: 8px;
            left: 10px;
        }
        .FormBox {
            margin-bottom: 0;
            .FormLabel {
                margin: 0;
            }
            .FormField {
                width: 200px;
                .EditBox {
                    background-color: #fff;
                    padding: 5px 10px;
                    padding-left: 30px;
                }
            }
        }
    }

    &.OrderHistory {
        display: flex;
        justify-content: space-between;
    }
}

.CryptoHeaderContainer {
    margin-bottom: 10px;
}
.CryptoHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    .Left {
        display: flex;
        img {
            width: 40px;
        }
    }
    h1 {
        margin-bottom: 0;
        margin-left: 5px;
        span {
            margin-left: 5px;
            font-size: 12px;
        }
    }
}
.CryptoPrice {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0;
}

.KrakenGraph {
    // height: 300px;
    margin-bottom: 40px;
    img {
        width: 100%;
    }
}
.SummaryContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px 15px 0;
    margin-bottom: 10px;
}
.SummaryItem {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
    padding-bottom: 5px;
    .ItemTitle {
        margin-bottom: 0;
        font-size: 11px;
        font-weight: 700;
    }
    .ItemContent {
        font-size: 20px;
        display: flex;
        align-items: baseline;
        margin-bottom: 0;
        span {
            display: flex;
            font-size: 11px;
            font-weight: 700;
            margin-left: 5px;
        }
    }
}

.CryptoContent {
    margin-bottom: 40px;
}

.SelectTradeContainer {
    width: 100%;
    margin-bottom: 20px;
}
.SelectTrade {
    display: flex;
    width: 100%;
    .Trade {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 3px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        z-index: 3;
        width: 50%;

        &.Buy {
            position: relative;
            right: -1px;
        }
        &.Sell {
            position: relative;
            left: -1px;
        }

        &.Inactive {
            background-color: $MainColor4;
            border: 1px solid $MainColor3;
            color: $MainColor3;
            z-index: 2;
        }
        &.NoBorderRight {
            border-right: 0;
            border-radius: 3px 0 0 3px;
        }
        &.NoBorderLeft {
            border-left: 0;
            border-radius: 0 3px 3px 0;
        }
    }
}

.FormBuilderForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    .FormSideBySide {
        flex-direction: column;
    }
    .FormBox .FormField .EditBox.ViewOnly {
        border: 1px solid $MainColor3;
        padding: 10px 15px;
        color: $MainColor1;
        cursor: default;
    }

    .PriceField {
        height: 40px;
    }
    // .react-select-container {
    //     .react-select__control {
    //         cursor: pointer;
    //         .react-select__value-container {
    //             width: 35px;
    //             .react-select__single-value {
    //                 font-weight: 700;
    //                 font-size: 12px;
    //                 z-index: 0;
    //                 display: inline-block;
    //                 text-overflow: clip;
    //             }
    //         }
    //         .react-select__indicators {
    //             .react-select__indicator-separator {
    //                 width: 0;
    //             }
    //         }
    //     }

    //     .react-select__control--is-focused {
    //         border: 1px solid $MainColor3;

    //         box-shadow: none;
    //     }
    //     .react-select__menu {
    //         width: 100% !important;
    //         top: 20px !important;
    //         right: 0px;
    //         border-bottom-left-radius: 3px;
    //         border-bottom-right-radius: 3px;
    //         border: 1px solid $MainColor3;
    //         border-top: none;
    //         box-shadow: none !important;
    //     }
    //     .react-select__menu-list {
    //         .react-select__option {
    //             // color: $DynamicColor2;
    //             font-weight: 700;
    //             &.react-select__option--is-selected {
    //                 background-color: $MainColor4;
    //                 color: $DynamicColor5; GET FROM THEME BEFORE USE
    //             }
    //         }
    //     }
    // }
}

.FractionsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .Fraction {
        display: flex;
        font-weight: 700;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        min-width: 55px;
        height: 35px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 3px;
        border: 1px solid $MainColor3;
    }
}

.TradeSubmit {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
}

.TradeFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.AddFunds {
    .FormBuilderForm {
        width: 40%;
        margin-bottom: 20px;
    }
    .Divider {
        border-bottom: 1px solid $MainColor3;
        width: 70%;
        margin-bottom: 20px;
    }
    .CurrencyOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
        border: 1px solid $MainColor3;
        padding: 30px;
        width: 50%;
    }
    .LightBlue {
        color: $DynamicColor2;
    }
    .CurrencyOptionsModal {
        .FormBuilderForm {
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.BackButton {
    color: $MainColor1 !important;
    margin-bottom: 28px;
    font-size: 12px;
    cursor: pointer;
    width: fit-content;
}

.AddFundsBtn {
    width: fit-content;
}

.CreateAlert {
}

.CryptoNameCell {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 10px 0;

    .CryptoImageContainer {
        display: flex;
        height: 100%;
        justify-content: flex-start;
        width: 50px;
    }

    .CryptoImage {
        height: 100%;
        margin-right: 12px;
        width: 50px;
    }

    .CryptoText {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .CryptoName {
        margin-bottom: 4px;
    }

    .CryptoTicker {
        font-size: 10px !important;
    }
}

.CryptoPriceCell {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .Ticker {
        font-size: 10px !important;
    }
}

.CryptoChangeCell {
    &.positive {
        color: #1c9e52;
    }

    &.negative {
        color: #e4413b;
    }
}

.Toggle {
    margin-bottom: 1rem;
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        border: 1px solid $DynamicColor2;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 3px;
        bottom: 3px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        background-image: url('../../assets/ibanera/bitcoin-symbol.svg');
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    /* Manual slider */
    .slider.manual .SlidingIcon {
        user-select: none;
        text-align: center;
        line-height: 26px;
        position: absolute;
        font-size: 18px;
        color: $BasicColor1;
        height: 26px;
        width: 26px;
        left: 3px;
        bottom: 3px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
    }
    input:checked + .slider.manual .SlidingIcon {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
}
.CoinGeckoWidget {
    height: 400px;
}
