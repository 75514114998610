/* Basics */
* {
    background-repeat: no-repeat;
    padding: 0px;
    margin: 0px;
    outline: none;
    box-sizing: border-box;
}

body {
    font-size: 62.5%;
    font-weight: 400;
    color: $MainColor1;
    font-family: $Font1;
    background: $BasicColor1;
    min-width: 320px;
    height: 100vh;
    box-sizing: border-box;

    &.OverflowHidden {
        overflow: hidden;
    }
}

#root {
    display: flex;
    max-width: 100%;
    height: 100vh;
}

.Main {
    flex: 1;
}

h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

h3 {
    color: $MainColor1;
    font-size: 15px;
    margin-bottom: 20px;
}

h4 {
    font-size: 10.5px;
    margin-bottom: 20px;
}

p {
    color: $MainColor1;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 20px;
}

a {
    color: unset;
}

button {
    font-family: $Font1;
    font-weight: 500;
}

ul,
ol {
    list-style-position: inside;
    margin-bottom: 20px;
}

li {
    font-size: 12px;
}

/* Layout */
.WidthContent {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
}

.HeightContent {
    padding-top: 60px;
    padding-bottom: 60px;
}

/*Page*/

.Page {
    position: relative;
    min-height: calc(100vh - 60px);
    display: flex;
    margin-top: 60px;

    @include bp('tablet') {
        min-height: calc(100vh - 120px);
    }

    .FullWidth {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        //account for the space taken up by mobile navigation with position fixed
        @include bp('tablet') {
            margin-bottom: 60px;
        }
    }
    &.FooterHidden {
        @include bp('tablet') {
            min-height: calc(100vh - 60px);
        }
        .FullWidth {
            margin-bottom: 0;
        }
    }

    .PartialWidth {
        margin-left: 260px;
        overflow-x: hidden;
        position: relative;
        width: calc(100vw - 260px);

        @include bp('tablet') {
            margin-left: 0px;
            min-width: 320px;
            width: 100vw;
        }

        .Footer {
            width: calc(100% - 260px);
            margin-left: 260px;
        }
    }
}

/* Forms */
.FormPage {
    max-width: 500px;
    @include bp('tablet') {
        margin-left: auto;
        margin-right: auto;
    }
}

.FormHint {
    display: inline;
    float: right;
    font-size: 0.7em;
}

.FormBox {
    margin-bottom: 20px;
    position: relative;
    &.SideBySide {
        width: 100%;
    }
    &.Focused {
        .FormLabel label {
            color: $DynamicColor2;
        }
        .EditBox,
        .EditBox:focus {
            border-color: $DynamicColor2;
            background-color: $DynamicColor6 !important;
        }
    }
}
.ButtonCheckboxInput {
    border: 1px solid $MainColor3;

    &.Error {
        border-color: $AlertColor3;
        background-color: $AlertColor4;
    }

    .CustomInput {
        padding: 20px 20px 20px 50px;
        height: auto;

        &::before,
        &::after {
            border-radius: 50%;
            top: calc(50% - 9px);
            left: 20px;
        }
    }
    .CheckboxContainer input[type='checkbox']:checked + .CustomInput::after {
        background-color: $DynamicColor1;
        background-image: url('../assets/ibanera/Icon_Tick_White.png');
    }
}

.FormLabel {
    font-size: 1.2em;
    line-height: 1.3em;
    margin-bottom: 8px;
    font-weight: 500;

    .Optional {
        font-size: 0.6rem;
        vertical-align: bottom;
    }
}
.FormBox .FormLabel.NoPaddingTop {
    padding-top: 0;
}

.FormSideBySide {
    display: flex;
    flex-direction: row;

    &.Small {
        flex-direction: column;
    }

    @include bp('tablet') {
        display: block;
    }

    .SideBySide {
        &:not(:last-child) {
            margin-right: 20px;
            @include bp('tablet') {
                margin-right: 0;
            }
        }
    }
}

.PasswordField {
    position: relative;
    .FormBox {
        margin-bottom: 0;
        .FormField {
            position: relative;
            width: 100%;
            img {
                position: absolute;
                right: 0;
                top: 0;
                height: 40px;
                cursor: pointer;
            }
        }
    }
}

.FormSideBySide .FormField.Flex,
.FormField.Flex {
    display: flex;
    * {
        flex: 1;

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}
.FormField.FlexCol {
    display: flex;
    flex-direction: column;
    *:not(.CustomSelect__indicators) {
        flex: 1;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}
.RemoveOptionContainer {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    font-size: 0.85rem;
}

.OptionTextContainer {
    display: flex;
    flex-direction: column;
}

.OptionLabel {
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
}

.OptionSubtitle {
    font-weight: normal;
    color: #333;
    margin: 2px 0 0 0;
    line-height: 1.2;
}

.RemoveOptionContainerImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.RemoveOptionContainerImage img {
    width: 20px;
    height: 20px;
}
.DropdownContainer .FormSideBySide {
    display: block;
}
.CustomSelect .CustomSelect__control {
    background-color: $MainColor4;
    border: 1px solid $MainColor3;
}
.ErrorField .CustomSelect .CustomSelect__control {
    background-color: $BasicColor6 !important;
}

.FormField .SingleSelectDropdownWrapper.HasToolTip {
    .CustomSelect__indicators {
        padding-right: 30px;
    }
}
.FormField .SingleSelectDropdownWrapper,
.FormField.DatePickerFormField,
.FormField .InputWrapper {
    position: relative;
    .FormTooltipContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }
    .TooltipIconContainer {
        margin-left: auto;
        margin-right: 10px;
        pointer-events: auto;
        cursor: pointer;

        .TextTooltipIcon {
            background-color: $DynamicColor2;
            color: $BasicColor1;
            height: 20px;
            width: 20px;
            line-height: 20px;
            border-radius: 50%;
            text-align: center;
            font-size: 16px;
        }
    }
    .TooltipTextContainer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(100% + 10px);
        background-color: $MainColor3;
        border-radius: 3px;
        padding: 10px;
        .Text {
            font-size: 0.8rem;
            text-wrap: wrap;
            overflow-wrap: break-word;
        }
    }
    .TooltipArrow {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $MainColor3;
        top: -10px;
        right: 10px;
    }
    .EditBox.WithTooltip {
        padding-right: 35px;
    }
    &.HasTooltip .react-datepicker__input-container input {
        background-position: center right 30px;
    }
}
.FormField.DatePickerFormField .TooltipIconContainer {
    z-index: 1;
}
.FormField .InputWrapper.FormTextArea {
    .FormTooltipContainer {
        align-items: start;
        padding-top: 10px;
    }
}

.FormField .EditBox {
    font-size: 1.2em;
    line-height: 1.5em;
    width: 100%;
    padding: 10px 15px;
    background-color: $MainColor4;
    border: 1px solid $MainColor3;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: $Font1;
    color: $BasicColor2;
    -webkit-appearance: none;

    &:focus {
        border: 1px solid $MainColor1;
    }

    &:disabled {
        opacity: 0.6;
    }

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 0.8rem;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 0.8rem;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 0.8rem;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 0.8rem;
    }
}

.FormField .InputWrapper.HasSymbol {
    position: relative;

    .CurrencySymbol {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 10px;
        font-size: 0.8rem;
    }
    input {
        padding-left: 23px;
    }
}
.FormField .react-datepicker-wrapper {
    width: 100%;
}

.FormLabel + .FormField .EditBox.ViewOnly {
    padding-top: 15px;
    padding-bottom: 0;
}

.FormSideBySide .FormField .EditBox.ViewOnly {
    margin-bottom: -5px;
}

.FormField .EditBox.ViewOnly {
    border: 0;
    background-color: transparent;
    color: $MainColor3;
    // cursor: default;
}

.DateFields {
    display: flex;
    & > *:first-child {
        margin-right: 10px;
    }
}

.FormField .DropDown {
    font-size: 1.2em;
    line-height: 1.25em;
    width: 100%;
    padding: 10px 15px;
    background-color: $MainColor4;
    border: 1px solid $MainColor5;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: $Font1;
    color: $BasicColor2;
    -webkit-appearance: none;
}
.FormField .TextArea {
    font-size: 1.2em;
    line-height: 1.25em;
    width: 100%;
    padding: 10px 15px;
    background-color: $MainColor4;
    border: 1px solid $MainColor5;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: $Font1;
    color: $BasicColor2;
    -webkit-appearance: none;

    &:read-only {
        min-height: 120px;
        max-height: 500px;
        resize: vertical;
        background-color: transparent;
        border: none;
        color: $MainColor3;
    }
}

input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
input[type='radio'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.CustomInput {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.3em;
    cursor: pointer;

    &.SmallPaddingLeft {
        padding-left: 20px;
    }
}
.CustomInput:before,
.CustomInput:after {
    position: absolute;
    top: 1px;
    left: 0;
    display: block;
    transition: all 0.3s ease-out;
    width: 18px;
    height: 18px;
}
.CustomInput:before {
    content: ' ';
    border-radius: 3px;
    border: 1px solid $MainColor3;
    background-color: $MainColor4;
    box-sizing: border-box;
}
.FocusInputLabel {
    color: $MainColor1 !important;
}

.ErrorInput {
    // border: 1px solid $BasicColor5 !important;
    background-color: $BasicColor6 !important;
}
.ErrorInputLabel {
    color: $BasicColor5 !important;
}
.ErrorLabel,
.ErrorText {
    color: $BasicColor5 !important;
    font-size: 1em;
    margin-top: 3px;
    > a {
        color: revert;
    }
}

.ErrorMessage {
    color: $BasicColor5;
    font-size: 1em;
    margin-top: -15px;
    margin-bottom: 5px;
}

.RegisterLink {
    display: block;
    float: right;
    z-index: 1;
    position: relative;
    &.UnderButton {
        float: none;
        margin: 10px auto 0 auto;
        width: fit-content;
    }
}

.CheckboxContainer {
    &.Error {
        background-color: $AlertColor4;
    }
    &.FormStyling {
        padding: 0.5rem;
        border: 1px solid $MainColor3;
        border-radius: 3px;
        margin-bottom: 20px;

        label {
            margin: 0;
        }

        &.Checked {
            border-color: $DynamicColor2;
            background-color: $DynamicColor6;

            label {
                &::before {
                    background-color: $DynamicColor2 !important;
                    border-color: $DynamicColor2 !important;
                }
                &::after {
                    background-image: url(/assets/Cat-Entity/Icon_Checklist.png) !important;
                }
            }
        }
    }
}

input[type='checkbox'] + .CustomInput:after {
    content: '';
    line-height: 20px;
    text-align: center;
    margin: 0;
    background-image: inherit;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}
input[type='radio'] + .CustomInput:after {
    content: '';
    line-height: 20px;
    text-align: center;
    margin: 0;
    background-image: inherit;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}
input[type='radio'] + .CustomInput:before {
    border-radius: 100%;
}
input[type='checkbox']:checked + .CustomInput:before {
    background: $MainColor4;
    border: 1px solid $MainColor3;

    &.Black {
        background: $BasicColor2;
        border: 1px solid $BasicColor2;
    }
}
input[type='checkbox']:disabled + .CustomInput:after {
    background-color: #cbcbcb;
    opacity: 0.3;
    border-radius: 2px;
}
input[type='radio']:checked + .CustomInput:before {
    background: $MainColor1;
    border: 1px solid $MainColor1;
}
input[type='checkbox']:checked + .CustomInput:after,
input[type='radio']:checked + .CustomInput:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    // background-image: url("../assets/Icon_Checklist.png");
}
/* Checked and disabled checkbox*/
input[type='checkbox']:disabled + .CustomInput:before,
input[type='checkbox']:disabled + .CustomInput:after {
    transition: none;
}
input[type='checkbox']:disabled + .CustomInput {
    cursor: default;
}
input[type='checkbox']:checked:disabled + .CustomInput:before {
    border-radius: 100%;
}

input[type='checkbox']:checked:disabled + .CustomInput:after {
    background-size: 25px;
}
/* Not checked and disabled checkbox*/
input[type='checkbox']:not(:checked):disabled + .CustomInput:before {
    background: transparent;
    border: none;
}
input[type='checkbox']:not(:checked):disabled + .CustomInput:after {
    // background-image: url("../assets/Checkbox-min.png");
    background-size: 15px;
}

.Disclaimer {
    margin-top: 20px;
    color: $MainColor9;
    font-size: 10px;
    line-height: 18px;
}

// .FormSideBySide {
//   display: table;
//   width: 100%;
// }
// .FormSideBySide .FormLabel {
//   display: table-cell;
//   width: 175px;
//   padding: 15px 5px 0px 0px;
//   vertical-align: top;
// }
// .FormSideBySide .FormField {
//   position: relative;
//   display: table-cell;
// }
// .FormSideBySide .FieldView {
//   display: table-cell;
//   vertical-align: top;
// }

.MultiChoice {
    padding: 15px;
    margin: 10px 0px 0 0;
    height: 200px;
    overflow: auto;
    background-color: $MainColor4;
    border: 1px solid $MainColor5;
}
.MultiChoice .Checkbox {
    margin-bottom: 10px;
}
.MultiChoice .Checkbox:last-child {
    margin-bottom: 0px;
}

/* Buttons */
.Btn {
    display: inline-block;
    font-size: 13px;
    line-height: 1em;
    font-weight: 600;
    color: $BasicColor1;
    text-align: center;
    //border: 1px solid $MainColor1;
    border-radius: 4px;
    box-sizing: border-box;
    background: $MainColor1;
    padding: 10px 30px;
    cursor: pointer;
    text-decoration: none;
    -webkit-appearance: none;
    font-family: $Font1;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    box-sizing: border-box;
    position: relative;

    .ButtonLoading {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Btn:hover {
    // opacity: 0.8;
    transition: all 0.3s ease-in-out;
}
.Btn:disabled {
    opacity: 0.5;
}

.Btn.BtnFull {
    display: block;
    width: 100%;
    height: 40px;
}

.Btn.DepositCryptoButton {
    margin-top: 1px;
    margin-left: 5px;
    padding: 3px 10px;
    font-size: 0.7rem;
    color: $MainColor1;
    border-color: $MainColor1;
}

.Disabled {
    cursor: not-allowed !important;
}
.RightMenu .ItemsContainer .Item.Disabled {
    opacity: 0.5;
    cursor: default !important;
}
.RightMenu .ItemsContainer .Item.Disabled:hover {
    background-color: initial;
}

/* Password strength helper */
.PasswordStrengthHelper {
    display: flex;
    flex-direction: column;
}
.PasswordStrengthMeterContainer {
    margin: 3px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.PasswordStrengthInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.PasswordStrengthInfo.Strength_Weak {
    color: $Colour_Weak;
}
.PasswordStrengthInfo.Strength_SoSo {
    color: $Colour_SoSo;
}
.PasswordStrengthInfo.Strength_Good {
    color: $Colour_Good;
}
.PasswordStrengthInfo.Strength_Great {
    color: $Colour_Great;
}

.PasswordStrengthInfo .MoreInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $BasicColor1;
    background-color: $MainColor1;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-left: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.PasswordStrengthInfo .MoreInfo:hover {
    opacity: 0.8;
}
.PasswordStrengthMeterContainer.Strength_VeryWeak .PasswordStrengthMeter {
    background-color: $Colour_VeryWeak;
}
.PasswordStrengthMeterContainer.Strength_Weak .PasswordStrengthMeter:first-child {
    background-color: $Colour_Weak;
}
.PasswordStrengthMeterContainer.Strength_SoSo .PasswordStrengthMeter:nth-child(-n + 2) {
    background-color: $Colour_SoSo;
}
.PasswordStrengthMeterContainer.Strength_Good .PasswordStrengthMeter:nth-child(-n + 3) {
    background-color: $Colour_Good;
}
.PasswordStrengthMeterContainer.Strength_Great .PasswordStrengthMeter {
    background-color: $Colour_Great;
}
.PasswordStrengthMeterContainer .PasswordStrengthMeter {
    width: 24%;
    height: 5px;
    background-color: $MainColor5;
    margin-right: 1%;
}
.PasswordStrengthMeterContainer .PasswordStrengthMeter:last-child {
    margin-right: 0;
}

.PasswordHelperMoreInfo {
    position: absolute;
    border: 1px solid $MainColor5;
    padding: 10px;
    border-radius: 3px;
    background-color: $BasicColor1;
    top: 0;
    right: 0;
    transform: translateX(115%);
    ul {
        list-style: none;
    }
}

/* MODALS */
.ModalBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 27;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    
    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #8cbdfe; 
        border-radius: 10px; 
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #556ef8;
    }

    ::-webkit-scrollbar-track {
        background: transparent; 
    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    /* For Firefox */
    html {
        scrollbar-width: thin; 
        scrollbar-color: #8cbdfe transparent;
    }
}
.ModalContent {
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;

    @include bp('mobile') {
        padding: 40px 20px;
    }
    @include bp('small-mobile') {
        padding: 40px 10px;
    }
    .ModalNumber {
        background-color: $MainColor4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 20px;
        font-size: 12px;
        margin-bottom: 20px;
        p {
            margin: 0;
        }
    }
}
.Modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    background-color: white;
    width: 460px;
    margin: 10px;
    border-radius: 3px;
    @include bp('mobile') {
        width: auto;
        max-width: 460px;
    }
    * {
        font-family: unset;
    }
    .ModalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        width: 100%;
        border-bottom: 1px solid $MainColor3;
        padding: 0 30px;
        @include bp('mobile') {
            padding: 10px 20px;
        }
        @include bp('small-mobile') {
            padding: 10px;
        }
        .ModalHeading {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;    
            word-wrap: break-word;
            line-height: 30px;
            color: $DynamicColor5;
            text-transform: capitalize;
        }
        .ExitImg {
            height: 60px;
            cursor: pointer;
            transform: translateX(30px);
            img {
                height: inherit;
            }
        }
    }
    .ModalNavigation {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px 30px;
        background-color: $MainColor4;
        @include bp('mobile') {
            padding: 10px 20px;
        }
        @include bp('small-mobile') {
            padding: 10px;
        }
        .Btn {
            white-space: nowrap;
        }
        .Btn:first-of-type {
            margin-right: 10px;
        }
    }
}

.CryptoRed {
    color: #e4413b;
}
.CryptoGreen {
    color: #1c9e52;
}

.SpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.AccountTask {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    &.Completed {
        font-weight: normal;
    }
}
.HistoryTableWarpper {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
}
.TableHeader {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    justify-content: space-between;
    .DateRange {
        display: flex;
        margin-right: auto;

        @include bp('small-tablet') {
            flex-direction: column;
            gap: 0.5rem;
            width: 100%;

            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            label {
                min-width: 100px;
            }
            .react-datepicker-wrapper {
                flex: 1;
            }

            .react-datepicker__input-container input {
                width: 100%;
            }
        }
    }
    .DateRange > div {
        > * {
            font-size: 12px;
            margin-right: 10px;
        }
    }
    label {
        font-weight: 600;
    }
    .SearchBoxContainer {
        border-radius: 3px;
        padding: 0;
        position: relative;
        height: 30px;

        .SearchBox {
            height: 100%;
            width: 100%;
            border: none;
            background: none;
            padding: 0 25px 0 30px;

            &::placeholder {
                font-size: 0.75rem;
            }
        }
        .SearchIcon {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translate(0, -50%);
            z-index: -1;
            width: 20px;
            height: 20px;
        }

        .ClearSearchIcon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            height: 40px;
        }
    }
    .Btn {
        margin-left: 5px;
        font-size: 0.7rem;
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;

        @include bp('small-tablet') {
            width: 100%;
        }
    }
    @include bp('tablet') {
        flex-direction: column;

        & > * {
            margin-bottom: 5px;
        }
        .Btn {
            margin-left: 0;
        }
    }
}
.react-datepicker-wrapper {
    &.Focused input {
        border-color: $AlertColor5;
        background-color: $AlertColor6;
    }
}
.react-datepicker__input-container {
    input {
        width: 140px;
        height: 30px;
        font-size: 0.7rem;
        padding: 15px;
        font-weight: 600;
        color: $MainColor1;
        border: 1px solid $MainColor3;
        border-radius: 3px;
        background-image: url('../assets/Icon_Form_DatePicker.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: 30px;

        &:read-only {
            background-image: none;
        }
    }
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 0.7rem;
    line-height: 1.2rem;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
    border-width: 0.35rem;
    top: 12px;
}
.react-datepicker__navigation--next {
    border-left-color: $MainColor1;
}
.react-datepicker__navigation--previous {
    border-right-color: $MainColor1;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 1.1rem;
    line-height: 1.1rem;
}
.react-datepicker {
    font-size: 0.6rem;
}
//hide up/down arrows for number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Error page */
.ErrorPage {
    display: table;
    width: 100%;
    background-color: $BasicColor1;
    height: calc(100vh - 120px);
}
.ErrorPage .ErrorCell {
    display: table-cell;
    vertical-align: middle;
}
.ErrorPage .Img {
    display: block;
    text-align: center;
}
.ErrorPage .Img img {
    width: 50px;
    display: inline-block;
}
.ErrorPage .Txt {
    display: block;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.6em;
    margin: 10px 0px 0px 0px;
}
.WarningText {
    font-size: 1.4em;
    color: $Alert_Red;
    font-weight: 600;
}
.QRCode {
    width: 400px;
}
.SharedSecret {
    text-align: center;
}
.SwitchTFATypeContainer {
    margin-bottom: 12px;
    text-align: right;
    width: 100%;
}
.SwitchTFATypeButton {
    margin-top: -10px;
    padding: 4px;
}
#notifications-portal {
    height: fit-content;
    max-height: 500px;
    position: fixed;
    right: 20px;
    top: 70px;
    width: 460px;
    overflow-y: auto;
    z-index: 10;

    @include bp('tablet') {
        right: unset;
        left: 50%;
        max-height: calc(100vh - 120px);
        transform: translateX(-50%);
        width: 95%;
    }
}

.zsiq_floatmain.siq_bR {
    transform: translateY(-60px); // move above footer
}

.TextIcon {
    height: 24px;
    width: 24px;
    margin-right: 15px;
    margin-left: 10px;
    border-radius: 50%;

    h3 {
        color: white;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
    }
    img.TextIconFallback {
        height: 100%;
        width: 100%;
    }
}

// CMS pages
.terms-and-conditions li {
    display: block;
}
.terms-and-conditions h3,
.terms-and-conditions h2 {
    display: inline-block;
}
.terms-and-conditions ol {
    list-style-type: decimal;
}
.terms-and-conditions li {
    counter-increment: item;
}
.terms-and-conditions li h2:before,
.terms-and-conditions li h3:before,
.terms-and-conditions li li:before {
    content: counters(item, '.') ' ';

    margin-right: 10px;
}
.terms-and-conditions ol {
    counter-reset: item;
}

.terms-and-conditions ol li ol li {
    margin-left: 18px;
    display: flex;
    text-align: justify;
}
.terms-and-conditions ol ol div ol {
    margin: 0;
}
.terms-and-conditions ol ol div li {
    margin-bottom: 0;
    margin-top: 10px;
}

.careers ul li,
.privacy-policy ul li,
.custody-terms ul li {
    text-indent: -17px;
    padding-left: 20px;
}

li {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}
.careers,
.regulations,
.privacy-policy,
.custody-terms,
.terms-and-conditions {
    padding-bottom: 20px;
}

.terms-and-conditions ol li,
.careers ul li,
.privacy-policy ul li,
.custody-terms ul li {
    color: $MainColor1;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 5px;
}

.UsdcWarning {
    color: $Alert_Red;
    background-color: $BasicColor6;
    display: flex;
    align-items: center;
    text-align: start;
    border-radius: 3px;
    padding: 10px;
    img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}
.Dropzone {
    background-color: $MainColor4;
    border-radius: 3px;
    border: 1px solid $MainColor3;
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        border: 2px solid $MainColor3;
    }
    img {
        height: 25px;
    }
    h5 {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
}
.DropzoneFooter {
    margin-top: 5px;
    font-size: 0.9em;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .Btn {
        height: 30px;
        line-height: 30px;
        padding: 0 30px;
        color: $MainColor1;
        border-color: $MainColor3;
    }
}

/*Cat table*/
.FlexTableWrapper .TableContainer .MiddleTable .Head .Cell.Sortable span.Sorting {
    color: $BasicColor2;
    font-weight: 400;
}

.MobilePayButtons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    --apple-pay-button-width: 240px;
    --apple-pay-button-height: 40px;

    .gpay-card-info-container {
        min-width: unset;
        width: 240px;
    }
}

/*business users page*/
.CategoryContainer.listsCatTable {
    .WidthContent {
        padding-left: 0;
        padding-right: 0;
    }
    .HeightContent {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.GeneralError {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .ErrorTitle {
        color: $DynamicColor5;
    }

    h2,
    p {
        margin: 0;
        text-align: center;
    }
}
.GeneralWarning {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: $AlertBadBackground;
    padding: 10px;
    border-radius: 3px;

    p {
        margin: 0;
    }
}

// utility
.m {
    &-0 {
        margin: 0;
    }

    &b {
        &-0 {
            margin-bottom: 0;
        }
        &-2 {
            margin-bottom: 0.5rem;
        }
        &-4 {
            margin-bottom: 1rem;
        }
    }
    &t {
        &-0 {
            margin-top: 0;
        }
        &-2 {
            margin-top: 0.5rem;
        }
        &-4 {
            margin-top: 1rem;
        }
    }
}

.TableFilterBtn {
    background-color: $MainColor4;
    border: 1px solid $MainColor4;
    color: $MainColor1;

    &:hover {
        opacity: 0.8;
    }
    .TableButtonIcon {
        margin-right: 3px;
    }
}

.VisaDirectModal {
    .ModalContent {
        position: relative;
        padding: 20px;
        min-height: 336px;

        .FormBuilderComponent {
            width: unset;
            flex-basis: 1%;
        }

        .RadioButtons {
            flex-grow: unset;
        }
        .CustomSelect.RoundedRect .CustomSelect__control {
            height: 40px;
        }
        .Layout {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 10px;
            > * {
                grid-column: span 3;
            }
            .CardNumber {
                grid-column: span 4;
            }
            .FormBuilderComponent {
                grid-column: span 2;
            }
        }
    }
}
.Relative {
    position: relative;
}

.ArrowIcon {
    position: relative;

    $arrowThickness: 5px;
    $arrowLength: 100px;
    .Arrow {
        position: absolute;
        top: 25px;
        width: $arrowLength;
        height: $arrowThickness;
        background-color: $DynamicColor1;
        border-radius: 3px;

        &::after,
        &::before {
            content: '';
            position: absolute;
            width: 60%;
            height: $arrowThickness;
            right: calc(-0.6 * #{$arrowLength} * 0.7071 * 0.5);
            background-color: $DynamicColor1;
            border-radius: 3px;
        }

        &::after {
            top: calc(-0.6 * #{$arrowLength} * 0.7071);
            transform: rotate(45deg);
        }

        &::before {
            top: 12px;
            transform: rotate(-45deg);
        }
    }
}
/* Scrollbar */
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    background: #eee; 
    border-radius: 10px; 
}
::-webkit-scrollbar-thumb {
    background-color: #5d52f7; 
    border-radius: 10px; 
}
::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
}
/* Scrollbar For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #5d52f7 #f0f0f0; 
}
*::-moz-scrollbar-thumb:hover {
    scrollbar-color: #555 #f0f0f0; 
}