@import 'styles/variables.scss';
.DividingLine {
    &.SmallPad {
        margin: 10px 30px !important;
    }
}
.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f8ff;
}

.success-message {
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #c3e6cb;
    display: flex;
    justify-content: center;
    flex-direction: column; 
    align-items: center;   
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

}
.success-animation{
  animation: fade-in 0.5s ease-out, slide-in 0.5s ease-out;
  font-size: 50px;
}
.success-message h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.success-message p {
    font-size: 16px;
    margin-bottom: 15px;
}

.success-dismiss-button {
    padding: 10px 15px;
    width: 200px;
    background-color: #155724;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease-in-out;
}

.success-dismiss-button:hover {
    background-color: #0c3d16;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }
}
