.modal_content {
    width: 800px;
    @media (max-width: 768px) {
        width: 90%;
        max-width: 600px;
    }
    @media (max-width: 480px) {
        width: 95%;
        max-width: 400px;
    }
    @media (max-width: 350px) {
      width: 95%;
      max-width: 340px;
  }
}