.ModalBackground.BusinessVerificationModalBg.Rejected {
    align-items: center;
}
.Modal.BusinessVerificationModal.Rejected {
    min-height: unset;
    top: 0;
    width: 460px;

    .ModalContent {
        min-height: unset;
    }
}
// VERIFCATION MODAL GENERAL
.Modal.BusinessVerificationModal {
    width: 85%;
    min-width: 300px;
    height: fit-content;
    min-height: 90%;
    position: relative;
    top: 30px;
    z-index: 25;
    border-radius: 3px;

    .RightHeader {
        display: flex;
        margin-left: auto;
        flex-direction: row;
        align-items: center;
        .SignOutLink {
            margin-left: 10px;
            font-size: 1.3em;
            cursor: pointer;
            font-weight: 700;
            padding: 5px;
            color: $DynamicColor2;
        }
        .SwitchAccountDropdown {
            width: 300px;
        }
    }
    .ModalContent {
        min-height: 85vh;
        display: flex;
        align-items: center;
        //MODAL CONTENT PAGES
        form {
            width: 100%;
        }
        .Terms {
            width: 100%;
            max-width: 500px;
            margin: auto;
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 1.2rem;
                width: 1.2rem;
                background-color: #eee;
                border-radius: 3px;
                border: 1px solid transparent;
                box-sizing: border-box;
            }
            // input[type='checkbox']:checked + .CustomInput:after {
            //     background-image: url('${imagesPath}/Icon_Tick.png');
            // }
            &.Submit {
                .CustomInput {
                    height: fit-content;
                    line-height: 1.5em;
                }
            }
        }
        .Docs {
            max-width: 760px;
            margin: auto;
            height: 100%;
            // .DocsHeader,
            // .DocsFooter {
            //     max-width: 760px;
            //     margin: auto;
            // }
            // .CardsSection {
            //     max-width: 760px;
            //     justify-content: center;
            //     margin: auto;
            // }
        }
        .SignificantParties {
            max-width: 600px;
            width: 95%;
            margin: auto;
            .PhoneNumberBox {
                width: 100%;
            }
            .SignificantParty {
                margin-bottom: 10px;
                .DropzoneFooter {
                    margin-bottom: 0px;
                }
            }
        }
        .CorporateApp {
            max-width: 550px;
            width: 100%;
            margin: auto;
            section {
                margin-bottom: 10px;
            }
        }
    }
    .FormHeader {
        border: solid 1px $MainColor3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        & :hover {
            cursor: pointer;
        }
        h3 {
            margin: 0px;
            color: black;
            font-weight: 600;
        }
        border-radius: 3px 3px 0 0;
        .Row {
            display: flex;
            column-gap: 10px;
        }
    }

    .Form {
        border: solid 1px $MainColor3;
        border-top: none;
        border-radius: 0 0 3px 3px;
        padding: 25px 20px;
        .FormSideBySide {
            .PhoneNumberBox.SideBySide.FormBox {
                width: 100%;
            }
        }
        h3 {
            color: #060415;
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
    .SaveBtn {
        @include bp('tablet') {
            height: 50px;
        }
    }
}

.ModalBackground.BusinessVerificationModalBg {
    height: 100%;
    overflow: auto;
    align-items: normal;
    z-index: 26;
}
//BACK BUTTON
.Back {
    background-color: transparent;
    border: none;
    font-size: small;
    &:hover {
        cursor: pointer;
    }
}
//STAGE INDICATOR START
.StageIndicatorContainer {
    width: 100%;
    margin: auto;
    justify-items: center;
    display: flex;
    flex-direction: column;
    .Progress {
        width: 100%;
        max-width: 900px;
        display: flex;
        align-items: center;
        align-self: center;
        margin-bottom: 5em;
        .Circle {
            position: relative;
        }
        .label {
            position: absolute;
            top: 22px;
            left: 10px;
            text-align: center;
            font-size: 0.6rem;
            line-height: 0.9rem;
            width: 80px;
            transform: translateX(-50%);
        }
    }
    @media only screen and (max-width: 768px) {
        .Progress .label:not(.CurrentStage) {
            display: none;
        }
        .Progress {
            margin-bottom: 3.5em;
        }
    }
}

.Stage {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e0e0e0;
}

.Circle.Filled {
    background-color: $DynamicColor2;
}

.Connector {
    flex-grow: 6;
    height: 4px;
    background-color: #e0e0e0;
}

.Connector.Filled {
    background-color: $DynamicColor2;
}

//STAGE INDICATOR END

.ExitContinueContainer {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    column-gap: 20px;
}

.NoMargin {
    margin-bottom: 0px;
    button:first-child {
        margin-right: 20px;
    }
}

//checkbox multi
.FormBox {
    .FormField {
        .custom-checkbox {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 16px;
            user-select: none;
            color: #060415;
            font-size: 1.2em;
            line-height: 1.3em;
            font-size: 1.2em;
            font-weight: 500;
        }

        .custom-checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            box-sizing: border-box;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 1.2rem;
            width: 1.2rem;
            background-color: #eee;
            border-radius: 3px;
            border: 1px solid transparent;
            box-sizing: border-box;
        }

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        .custom-checkbox input:checked ~ .checkmark:after {
            display: block;
        }
        .custom-checkbox input:checked ~ .checkmark {
            background-color: $DynamicColor2;
        }
        .custom-checkbox .checkmark:after {
            left: 6px;
            top: 1px;
            width: 3px;
            height: 10px;
            border: solid 2px white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
//No Resize on TextArea
.NoResize {
    resize: none;
}
//CHEVRON
.ChevronOpen {
    width: 10px;
    height: 10px;
    border: solid 2px $MainColor3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    align-self: center;
}
.ChevronClosed {
    width: 10px;
    height: 10px;
    border: solid 2px $MainColor3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    align-self: center;
}

//UPLOAD DOCS LIST

.UploadDocumentModal {
    .UploadedFiles {
        h3 {
            margin-bottom: 0.5rem;
        }
        .UploadedFilesWrapepr {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-bottom: 1rem;
            max-height: 200px;
            overflow-y: auto;

            .UploadedFile {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                padding: 5px 10px;
                border-radius: 3px;
                background-color: $MainColor4;
                font-weight: 600;
                font-size: 0.8rem;

                button {
                    cursor: pointer;
                    outline: none;
                    border: none;
                    background-color: transparent;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .DocumentListContainer {
        display: flex;
        justify-content: space-between;
        p {
            margin: 3px 0px;
            // &:not(:first-of-type) {
            //     border-top: 1px solid $MainColor3;
            // }
        }

        img {
            width: 12px;
            height: 12px;
            align-self: center;
            &:hover {
                cursor: pointer;
                width: 13px;
                height: 13px;
            }
        }
    }
}

//SELECT DROPDOWN ERROR FIELD
.ErrorSelect {
    .CustomSelect {
        .CustomSelect__control {
            background-color: $BasicColor6;
        }
    }
}
