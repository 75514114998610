.FlexTableWrapper {
    .MarketNameCell {
        align-items: center;
        display: grid;
        height: 100%;
        padding: 10px 0;
        min-width: 150px;
        grid-template-columns: 1fr 5fr;

        .MarketImageContainer {
            display: flex;
            height: 75%;
            justify-content: flex-start;
            width: 50px;
        }

        .MarketImage {
            height: 100%;
            margin-right: 12px;
            width: 50px;
        }

        .MarketText {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .MarketName {
            font-weight: 600 !important;
            margin-bottom: 2px;
        }

        .MarketTicker {
            font-size: 10px !important;
        }
    }

    .MarketPriceCell {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .MarketChangeCell {
        &.positive {
            color: $BasicColor7;
        }

        &.negative {
            color: $BasicColor8;
        }
    }

    .MarketRowButtons {
        display: contents;

        &.OneButton {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .Btn {
                max-width: 120px;
            }
        }

        button {
            padding: 0 15px;
        }
    }

    .MarketReferenceCell {
        align-items: center;
        display: flex;

        &.Exchange {
            .Reference {
                font-weight: 500 !important;
            }
        }

        .RowImage {
            height: 60px !important;
        }

        .Names {
            align-items: flex-end;
            display: flex;

            .Name {
                font-size: 0.86rem;
                line-height: 0.86rem;
                font-weight: 600;
                margin-right: 6px;
            }
        }

        .Reference {
            font-size: 10px !important;
            line-height: 10px !important;
        }
    }

    .FilterCell {
        display: none !important;
    }

    .DateCell {
        .Date {
            font-weight: 500 !important;
        }
    }

    .BuySellCell {
        .BuyOrSell {
            font-weight: 700 !important;
            text-transform: uppercase;
        }
    }

    .NumberTickerCell {
        color: $DynamicColor2;
        overflow: hidden;
        text-overflow: ellipsis;

        &.Minus {
            color: $DynamicColor3;
        }
        &.PlainColour {
            color: inherit;
        }

        .Number {
            font-weight: 500 !important;
        }
    }

    .EmptyCell {
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, $MainColor5 8%, $MainColor4, $MainColor5 54%);
        background-size: 1000px 640px;
        height: 25%;
        position: relative;
        width: 90%;
    }

    .ButtonCell {
        max-width: unset !important;
    }

    .StringCell {
        font-weight: 500 !important;
    }

    .Ticker {
        font-size: 10px !important;
        line-height: 10px !important;
        font-weight: 600 !important;
    }

    .Exchange {
        .TableContainer {
            .ButtonCell {
                width: unset !important;
            }
        }
    }
    .NameInitialsCell {
        display: flex;
        align-items: center;

        .Left {
            margin-right: 8px;
            height: fit-content;
        }

        .Right {
            display: flex;
            flex-direction: column;

            .Name {
                margin: auto 0 auto 10px;
                font-size: 12px !important;
                font-weight: 700 !important;
            }

            .SubName {
                font-size: 12px !important;
                font-weight: 500 !important;
            }
        }
    }
    .MoneyIn {
        color: $DynamicColor2;
    }
    .MoneyOut {
        color: $DynamicColor3;
    }
    .OrderType {
        display: flex;
        align-items: center;
        gap: 10px;

        .Pending {
            font-weight: 600 !important;
            font-weight: 700 !important;
            font-size: 0.8rem !important;

            &--in {
                color: $DynamicColor2;
            }
            &--out {
                color: $DynamicColor3;
            }
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
