.MobileNavigation {
    background-color: $MainColor11;
    border-top: 1px solid $MainColor3;
    bottom: 0;
    display: none;
    height: 60px;
    min-width: 320px;
    position: fixed;
    width: 100%;
    z-index: 5; //ensures mobilenav and menus go over page content

    @include bp('tablet') {
        display: flex;
        justify-content: space-around;
    }

    .MobileNavigationButton {
        align-items: center;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        justify-content: center;
        display: flex;
        position: relative;
        width: 60px;

        .AlertIcon {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 8px;
            right: 8px;
        }

        .Icon {
            height: 80%;
            position: absolute;
            top: 0;
            width: 80%;

            &.Hidden {
                display: none;
            }
        }

        .FallbackTextIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            .TextIcon {
                background-color: $MainColor1;
            }
            &.Active .TextIcon {
                background-color: $DynamicColor1;
            }
        }

        .Label {
            font-family: $Font1;
            font-size: 11px;
            font-weight: 500;
            margin-top: 32px;
            text-wrap: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.MoreMenu {
    background-color: white;
    display: none;
    height: calc(100% - 120px);
    left: 0;
    overflow-y: scroll;
    padding: 40px 20px;
    pointer-events: all;
    position: fixed;
    top: 60px;
    width: 100%;

    @include use-width-content;

    @include bp('tablet') {
        display: flex;
        flex-direction: column;
    }

    .FooterLinks {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .FooterLink {
        background-color: $MainColor11;
        cursor: pointer;
        margin-bottom: 2px;
        padding: 10px;
        width: 100%;
    }

    .PageLinks {
        margin-bottom: 40px;

        .MobileNavigationButton {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-right: 0;
            width: 100%;

            img {
                height: 48px;
                left: 0;
                position: static;
                width: 48px;
            }

            .Label {
                color: $MainColor1;
                font-family: $Font1;
                font-weight: 600;
                font-size: 14px;
                margin-top: 0;
                margin-left: 8px;
            }
        }
        .MobileSubMenuButton {
            width: 100%;
            margin-top: 20px;
        }
        .SideMenuLink {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            text-decoration: none;
            height: 60px;
            padding: 0;
            border-top: 1px solid $MainColor3;
            &:last-of-type {
                border-bottom: 1px solid $MainColor3;
            }

            img {
                height: 40px;
                margin-right: 10px;
            }
            .CardImg {
                width: 40px;
                height: auto;
                margin-left: 10px;
            }

            .TextIcon {
                height: 36px;
                width: 36px;
                margin-right: 15px;
                margin-left: 0;
                border-radius: 50%;

                h3 {
                    color: white;
                    line-height: 36px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
            .AccountName {
                color: $MainColor1;
                font-size: 0.7em;
                font-weight: 600;
            }
            .AccountValue {
                font-size: 1.1em;
            }
            .AccountNumber {
                font-size: 0.7em;
                color: $MainColor1;
            }
            .CardNumber {
                margin-bottom: 0;
                font-size: 0.7em;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .MoreMenuPageLink {
        align-items: center;
        border-top: 1px solid $MainColor3;
        cursor: pointer;
        display: flex;
        height: 60px;
        position: relative;

        &:last-of-type {
            border-bottom: 1px solid $MainColor3;
        }

        .Icon {
            height: 80%;
        }

        .TextIcon {
            background-color: $MainColor1;
        }

        .LinkLabel {
            color: $MainColor1;
            font-size: 14px;
            font-weight: 600;
            margin-left: 8px;
        }

        .Chevron {
            height: 75%;
            margin-left: auto;
            opacity: 0.5;
            transform: rotate(270deg);
        }
        .CardIcons {
            position: absolute;
            bottom: 0;
            height: 20px;
            img {
                height: 20px;
            }
        }
    }

    .SwitchButton {
        margin-bottom: 20px;
    }
}
