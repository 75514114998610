.ProfilePage {
    min-height: 100%;
    position: relative;

    .CreateAccount {
        max-width: 530px;
        width: 100%;

        @include bp('tablet') {
            max-width: unset;
        }
    }

    .SwitchForm {
        width: 340px;

        @include bp('tablet') {
            width: 100%;
        }

        .SideBySide,
        .FormBuilderComponent {
            width: 50%;

            @include bp('tablet') {
                width: 100%;
            }
        }

        // Overriding select menu size and position.
        .CustomSelect__menu {
            left: 0;
            width: 100% !important;
        }
    }

    .SecurityPage {
        .SecurityPageSection {
            border-bottom: 1px solid $MainColor3;
            padding-bottom: 50px;
            padding-top: 50px;

            &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0;
            }

            &:first-of-type {
                padding-top: 0;
            }

            .Content {
                width: 440px;
            }

            .Item {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                @include bp('tablet') {
                    align-items: flex-start;
                    flex-direction: column;
                }

                .Label {
                    font-size: 11px;
                    margin-bottom: 0;

                    @include bp('tablet') {
                        margin-bottom: 10px;
                    }
                }

                .Button {
                    font-size: 12px;
                    padding: 0;
                    width: 140px;
                    flex-shrink: 0;
                }
            }

            .Deactivate {
                background-color: $MainColor11;
                border-color: $MainColor3;
                color: $MainColor14;
            }

            .TableContainer {
                overflow-x: auto;
            }

            .LoginTable {
                border: 0;
                border-collapse: collapse;
                min-width: 440px;
                text-align: left;
                width: 100%;

                thead {
                    background-color: $MainColor11;
                }

                tr {
                    border-bottom: 1px solid $MainColor3;
                    height: 60px;
                    margin: 0 52px;

                    td,
                    th {
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    td:first-child,
                    th:first-child {
                        padding-left: 56px;

                        @include bp('tablet') {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
        .Toggle {
            margin: 0 20px 0 auto;

            .switch {
                height: 30px;
            }
            .slider {
                border-radius: 30px;
                border-color: $MainColor3;
                background-color: $MainColor11;
                &::before {
                    left: 1px;
                    bottom: 1px;
                    background-image: none;
                    background-color: $MainColor3;
                }
            }
            input:checked + .slider {
                background-color: $BasicColor1;
                &::before {
                    transform: translateX(30px);
                    color: $BasicColor1;
                    font-weight: 600;
                    content: '|';
                    line-height: 26px;
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
    }

    .Spinner {
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        transform: translate(-50%, -50%);
    }
    .RegisterBusinessForm {
        .DropdownContainer > .FormBox {
            margin: 0;
        }
        .PhoneNumberBox.SideBySide.FormBox {
            width: 100%;
        }
    }
}
.ProfileDetailsPage {
    display: flex;
    .ProfileDetails {
        width: 100%;
        max-width: 600px;
        padding: 20px;
        font-size: 1.3em;
        margin-bottom: auto;
        tr {
            height: 40px;
        }
        .Label {
            font-weight: 600;
        }
        .Value {
            text-align: end;
        }
    }
    .FillerImage {
        position: relative;
        left: 30px;
        margin: auto;
        max-width: min(40%, 400px);
        height: auto;
    }
    @include bp('tablet') {
        .FillerImage {
            display: none;
        }
        .ProfileDetails {
            margin: auto;
        }
    }
}
