@import 'styles/variables.scss';

$PanelWidth: 420px;
$FlexGap: 2rem;

.TableEntryInfoPanelWrapper {
    @media only screen and (max-width: 1400px) {
        display: none;
    }
}

.Modal {
    border-radius: 3px;
    :global(.ModalContent) {
        padding: 0;
    }
}
:global(.PayeesTable),
:global(.MoveMoneyPage) {
    :global(.FlexTableWrapper) {
        max-width: calc(100% - #{$PanelWidth} - #{$FlexGap});
        @media only screen and (max-width: 1400px) {
            max-width: unset;
        }
    }
    &:global(.Empty) {
        :global(.FlexTableWrapper) {
            max-width: unset;
        }
    }
}

.Panel {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid $MainColor3;
    border-radius: 3px;
    padding: 1rem;
    min-width: $PanelWidth;
    width: $PanelWidth;
    min-height: 500px;

    @media only screen and (max-width: 1400px) {
        min-width: unset;
        max-width: unset;
        width: 100%;
        min-height: unset;
        padding: 1.5rem;
    }

    &.Empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1rem;

        img {
            width: 100px;
        }
    }

    .Header {
        padding-right: 2rem;
        display: flex;
        gap: 1rem;

        .Title {
            color: $DynamicColor5;
            font-size: 1.88rem;
            margin-bottom: 0;
            font-weight: 600;
        }

        .CloseIcon {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            width: 40px;
            height: 40px;
        }
    }

    .Error,
    .Loading {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
    }

    :global(.Collapse) {
        margin-bottom: 0;
    }
    :global(.CollapseContent),
    .Details {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        &:not(:global(.CollapseContent)) {
            margin-bottom: 2rem;
        }
        .InfoItem {
            display: flex;
            gap: 10px;

            p {
                margin: 0;
                font-size: 0.7rem;
                line-height: 0.7rem;
                font-weight: 500;
            }
            .Label {
                text-transform: capitalize;
                min-width: 150px;
                color: $MainColor1;
            }
            .Value {
                color: $BasicColor2;

                &.ApprovalPending {
                    color: $DynamicColor3;
                    font-weight: 700;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .Buttons {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: auto;
    }
}
