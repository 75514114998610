.PublicInformationPage {
  height: 100%;
  max-width: 1200px;
  padding: 60px 20px;
  width: 100%;

  @include use-width-content;

  .CMSContentSpinner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}