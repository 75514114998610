@import 'styles/variables.scss';

.PayInPage {
    display: flex;
    flex-direction: row;
    display: flex;
    height: calc(100% - 40px);
    .LeftSide {
        width: min(500px, 100%);
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .FillerImage {
        position: relative;
        left: 30px;
        margin: 40px auto auto;
        max-width: min(40%, 400px);
        height: auto;
    }

    .TransferDetails {
        border-radius: 3px;
        margin-bottom: 20px;
        padding: 10px 20px;

        tr {
            height: 20px;
            font-size: 1.3em;
        }
        @include bp('mobile') {
            tr:not(.SectionHeading) {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                height: auto;
            }
        }
        .Label {
            font-weight: 600;
            min-width: 200px;
            vertical-align: top;
        }
        .Value {
            vertical-align: top;
        }
        tbody {
            .SectionHeading:first-child {
                height: 35px;
                vertical-align: bottom;
            }
        }
        .SectionHeading th {
            font-weight: normal;
            text-align: left;
        }
    }

    // .ChooseMethod {
    //     height: 40px;
    //     max-width: 100%;
    //     width: 100%;
    //     display: flex;
    //     flex-direction: row;
    //     margin-bottom: 20px;
    //     .CardOption {
    //         background-color: $MainColor4;
    //         border: 1px solid $MainColor3;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         border-radius: 3px;
    //         padding: 10px 20px;
    //         font-size: 14px;
    //         font-weight: 700;
    //         cursor: pointer;
    //         z-index: 3;
    //         width: 50%;
    //         box-sizing: border-box;

    //         &.Active {
    //             color: $BasicColor1;
    //             background-color: $MainColor13; REPLACE WITH THEME BEFORE USE
    //             border: none;
    //         }
    //     }
    //     :last-child {
    //         &.Active {
    //             background-color: $DynamicColor2; REPLACE WITH THEME BEFORE USE
    //         }
    //     }
    // }
    .CheckboxContainer {
        margin-bottom: 15px;
    }
    .ExistingCardOptions {
        > .FormBox.FormSideBySide {
            flex-direction: column;
        }
        .CvvInput {
            max-width: 100px;
        }
    }
    .IframeContainer {
        display: flex;
        flex-grow: 1;
        width: 100%;
        margin-left: 0;
        position: relative;
        iframe {
            height: 100%;
        }
        .IframePlaceholder {
            position: absolute;
            left: 0;
            top: 5px;
            z-index: -1;
        }
    }
    .CardSelect__control {
        background-color: $MainColor4;
        .CardSelect__placeholder {
            color: $MainColor1;
            font-size: 12px;
        }
        .CardSelect__single-value {
            color: $MainColor1;
            font-size: 12px;
        }
    }
    .CardSelect__menu {
        margin-top: 0;
        font-size: 12px;
    }
    .RadioButton {
        color: $MainColor1;
    }
    .FormLabel {
        color: $MainColor1;
    }
    @include bp('tablet') {
        .FillerImage {
            display: none;
        }
        .LeftSide {
            margin: auto;
        }
    }
    .SubledgerInfo {
        h3.head {
            display: flex;
            gap: 5px;

            .ExclamationIcon {
                border: 1px solid $MainColor1;
                width: 19px;
                text-align: center;
                border-radius: 50%;
                line-height: 17px;
            }
        }
    }
}

.AddAccountPage {
    display: flex;
    margin-top: 40px;
    .LeftSide {
        max-width: 450px;
    }
    .FillerImage {
        position: relative;
        left: 30px;
        margin: auto;
        max-width: min(40%, 400px);
        height: auto;
    }
    h3 {
        font-size: 16px;
    }
    .FormBox {
        flex-direction: column;
    }

    .LegalText {
        margin-top: 10px;
        color: $MainColor9;
    }

    .CustomSelect__control {
        background-color: $MainColor4;
    }

    @include bp('tablet') {
        .FillerImage {
            display: none;
        }
        .LeftSide {
            margin: auto;
        }
    }
}
.MoveMoneySubPage {
    display: flex;
    .LinkText {
        align-self: flex-start;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
            text-decoration: underline;
        }
    }
    .LeftSide {
        width: min(600px, 100%);
        display: flex;
        flex-direction: column;
        @include bp('tablet') {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .RadioButtons {
        height: 40px;
        flex: none;
        margin-bottom: 20px;
    }
    .CustomInput {
        margin-bottom: 20px;
    }
    .CustomSelect p {
        margin-bottom: 5px;
    }
}

.PayeeInfo {
    display: flex;
    width: 100%;
    height: 60px;
    border: 1px solid $MainColor3;
    border-radius: 3px;
    margin-bottom: 20px;
    align-items: center;
    font-size: 1.2em;
    .InitialsIcon {
        margin: auto 20px auto 10px;
    }
}
.PayeesTable {
    .Cell {
        height: auto !important;
        min-height: 70px;
    }
    .ButtonCell {
        padding: 5px !important;
        min-width: 145px !important;
    }
    .MoveMoneyButtons {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding: 0;
        align-items: center;
        width: 100%;
        > button {
            margin: 0 !important;
        }

        .DeletePayeeBtn {
            height: 30px;
            width: 30px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            :hover {
                opacity: 0.7;
            }
        }
    }
}

.TransferButtons {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: flex-start;
    gap: 5px;
    .AddPersonalAccount {
        display: flex;
        flex-direction: column;
    }
    @include bp('tablet') {
        justify-content: flex-start;
    }
    @include bp('small-tablet') {
        flex-direction: column;
        .AddPersonalAccount {
            width: 100%;
        }
    }
}

.TransactionRowInfoButton {
    border-radius: 50%;
    border: 1px solid $MainColor3;
    width: 1em;
    min-width: 1em;
    display: block;
    line-height: 1em;
    text-align: center;
    font-size: 1em;
    color: $MainColor3;
    overflow: visible !important;
    padding: 0 !important;
    cursor: pointer;
    user-select: none;
}

.TransactionNoteTextArea {
    resize: vertical;
    height: 120px;
    min-height: 120px;
}
.TransactionInfoTable {
    font-size: 0.8rem;
    tr {
        height: 30px;
        line-height: 30px;
        font-weight: 500;

        .Label {
            width: 170px;
            margin-right: 20px;
        }
        .Value {
            color: $MainColor10;
        }
    }
}

.TransactionsButtonRow {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .TransactionInfoButton {
        flex: 1;
    }
    .NotesButton {
        margin-left: auto;
        width: 75px !important;
    }
}
.PayeesTable,
.Cryptos,
.TransactionsPage {
    .TableHeader {
        margin-bottom: 1rem;
    }
    .TableWrapper {
        display: flex;
        gap: 2rem;
    }
}

.TransactionsPage {
    .LeftHeader {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 0.5rem;

        .DownloadCsvBtn {
            height: 32px;
            font-size: 0.75rem;
        }

        @include bp('small-tablet') {
            gap: 10px;
            .DownloadCsvBtn {
                width: 100%;
            }
        }
    }

    .Loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.MoveMoneyPage {
    overflow: hidden;

    .TableWrapper {
        margin-top: 1.5rem;
        display: flex;
        gap: 2rem;
    }
}
.TransactionNoteModal .FormBox {
    margin: 0;
}

.BulkTransferWrapper,
.MoveMoneySubPage,
.EditPayeePage {
    .FormDateField {
        flex-direction: column;
        .DatePickerFormField {
            width: 100%;
            .DatePickerBox {
                height: 38px;
            }
        }
    }
    .Error,
    .Loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        font-size: 0.8rem;
    }

    h1 {
        text-transform: capitalize;
    }
    h4 {
        text-transform: uppercase;
        color: $MainColor1 !important;
        font-size: 0.75rem;
    }
    .Layout {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;

        > * {
            flex: 1;
            min-width: 40%;
        }

        .AccountNameFullWidth {
            flex-basis: 100%;
        }
    }
    .AddressLayout {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        > * {
            flex: 1;
            min-width: 180px;
        }
    }
    button:not(.react-datepicker__navigation, .SwitchTFATypeButton) {
        width: 100%;
    }
}
.MoveMoneySubPage,
.CurrencyExchangeModal {
    .InputSection {
        background-size: cover;
        label {
            color: $BasicColor1;
        }
        .ButtonLoading {
            position: absolute;
            top: 35px;
            left: calc(50% - 40px);
        }
    }
    .BuySellAmounts {
        background-size: cover;
        h4 {
            color: $BasicColor1 !important;
            text-transform: unset;
        }
    }
    margin-bottom: 20px;
}
.MakePaymentMfaModal {
    .FormBox {
        margin-bottom: 0;
    }
}

.EditPayeeWarning {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    background-color: $AlertBadBackground;
    padding: 10px;
    border-radius: 3px;

    p {
        margin: 0;
    }
}

.FeeOptions {
    flex-direction: column;
    .RadioButton :first-child:before {
        transform: translateY(3px);
    }
    &.RadioIdButtons .RadioButton {
        @include bp('mobile') {
            flex: 1 1 100%;
        }
    }
}
