@import 'styles/variables.scss';

.TableEntryInfoPanelWrapper {
    @media only screen and (max-width: 1400px) {
        display: none;
    }
}
.Modal {
    border-radius: 3px;
    :global(.ModalContent) {
        padding: 0;
    }
}

.Panel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1rem;
    border: 1px solid $MainColor3;
    border-radius: 3px;
    padding: 1rem;
    flex-grow: 1;
    max-width: 400px;
    min-width: 350px;
    width: 100%;

    @media only screen and (max-width: 1400px) {
        min-width: unset;
        max-width: unset;
        padding: 1.5rem;
    }

    &.Empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        min-height: 500px;

        img {
            width: 100px;
        }
    }

    .Header {
        padding-right: 2rem;
        display: flex;
        gap: 1rem;

        .Title {
            color: $DynamicColor3;
            margin-bottom: 0;
        }

        &.MoneyIn {
            .ArrowIcon {
                transform: rotate(180deg);
            }
            .Title {
                color: $DynamicColor2;
            }
        }

        .ArrowIcon {
            margin-top: 2px;
            min-width: 18px;
            width: 18px;
            height: 18px;
        }
        .CloseIcon {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            width: 40px;
            height: 40px;
        }
    }

    .Summary {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 1rem;
        margin-bottom: 1rem;

        .Amount {
            font-size: 2rem;
            font-weight: 600;
            color: $DynamicColor5;

            span {
                font-size: 1rem;
            }
        }
        .Pending {
            font-weight: 600 !important;
            font-weight: 700 !important;
            font-size: 0.8rem !important;

            &In {
                color: $DynamicColor2;
            }
            &Out {
                color: $DynamicColor3;
            }
        }
        :last-child {
            margin-bottom: 5px;
        }
        .Reference {
            margin-bottom: 0px;
        }
    }

    .Loading {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
    }

    .Details {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $MainColor3;

        &.DisableEdit {
            border-bottom: none;
            padding-bottom: 0;
        }

        .InfoItem {
            display: flex;
            gap: 10px;

            p {
                margin: 0;
                font-size: 0.7rem;
                line-height: 0.7rem;
                font-weight: 500;
            }
            .Label {
                text-transform: capitalize;
                min-width: 120px;
                color: $MainColor1;
            }
            .Value {
                color: $BasicColor2;
                overflow: hidden;
                word-wrap: break-word;
            }
        }

        :global(.CollapseContent) {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;

            .Label {
                max-width: 120px;
            }
        }
    }

    form {
        textarea {
            resize: vertical;
            min-height: 100px;
            max-height: 300px;
        }
        .NoteDisabled {
            display: flex;
            flex-direction: column;
            column-gap: 10px;
            .NotesLabel {
                min-width: 120px;
                margin-bottom: 0;
                color: $MainColor1;
            }
        }
        button {
            width: 100%;
            font-size: 0.7rem;
        }
    }
    .ApprovalDetails {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $MainColor3;
        padding-top: 10px;
        margin-top: 10px;
        gap: 5px;
        h3 {
            color: $DynamicColor3;
        }
        p {
            color: $BasicColor2;
            margin-bottom: 5px;
            line-height: 100%;
        }
        .ApprovalRow {
            display: flex;
            justify-content: space-between;
        }
    }
}
