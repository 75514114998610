@import 'styles/variables.scss';

.SingleSelectWithSearch {
    width: 100%;

    :global(.CustomSelect) {
        height: 40px;

        :global(.CustomSelect__control) {
            height: 40px;
        }
    }

    .SearchIcon {
        width: 40px;
        height: 40px;
    }

    &.Error {
        .Label {
            color: $AlertColor3;
        }
        :global(.CustomSelect__control) {
            border-color: $AlertColor3;
            background-color: $AlertColor4;
        }
    }

    .Label {
        label {
            display: flex;
            align-items: center;

            .OptionalLabel {
                color: $MainColor1;
                font-size: 1rem;
            }
        }
    }

    .ErrorText {
        color: $AlertColor3;
        font-size: 1.2rem;
        min-height: 1.6rem;
        margin-bottom: 0;
        line-height: 1.6rem;
    }
}
