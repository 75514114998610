/*business users page category*/
.CategoryContainer {
    height: 100%;
    width: 100%;

    margin-top: 0; //v1 //38px;//v0
}
.CategoryPage {
    //width: calc(100% - #{$navWidth} - 120px);
    flex: 1;

    padding: 0;
    width: 100%;
    height: 100%;
}
.CategoryPage.entered {
    max-width: calc(
        100% - 200px
    ); // rightpanel is 240px, but we have some padding here from .DashboardPage, so we can remove a little less because of that
}
.CategoryContainer.listsCatTable {
    // // height: calc(100% + 46px);
    // // width: calc(100% + 120px);
    // position: absolute;
    // width: 100%;
    // height: 100%; //auto;
    // top: 0;
    // left: 0;
    // height: calc(100vh - 120px);
    .FlexTableWrapper .Inner .TableContainer .RightTable .Head .Cell.NoSortIcon.FilterCell {
        display: block;
    }
    .FlexTableWrapper .TableContainer .RightTable .TableBody .Row .Cell {
        border-bottom: 1px solid #d5d5d5;
    }
    .FlexTableWrapper .TableContainer .RightTable .Head {
        display: flex;
        justify-content: center;
        margin: 0 auto; //& padding
        .Cell {
            min-width: auto;
            justify-content: center;
            padding: 0;
        }
    }
    .FlexTableWrapper .TableContainer {
        .LeftSideTable {
            width: 20%;
        }
        .LeftSideTable,
        .RightTable,
        .MiddleTable {
            .TableBody .Row:nth-child(2n) .Cell {
                background-color: #f5f5f5;
                //border-bottom: #fff;
            }
            .TableBody .Row.Selected {
                .Cell {
                    background-color: #d2d2d2;
                    //.CustomInput:after,
                    input[type='checkbox']:checked + .CustomInput:after {
                        background-image: url(../../assets/Cat-Entity/Icon_Checklist.png);
                        //background-color: red;
                    }
                    input[type='checkbox']:checked + .CustomInput:before {
                        background: $DynamicColor1;
                        border: 1px solid $DynamicColor1;
                    }
                }
            }
        }
    }
}

.RightMenu.entered {
    min-width: 240px;
    width: 240px;
    visibility: visible;
    //border-left: 1px solid #d5d5d5;
    box-sizing: border-box;

    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid $MainColor3;
}
// .CategoryContainer.businessusers {
//     position: relative;
// }
// .RightMenu.entered::after {
//     position: absolute;
//     content: '';
//     top: 0;
//     right: 0;
//     height: 100%; //calc(100% + 88px);
//     min-width: 240px;
//     width: 240px;
//     border-left: 1px solid #d5d5d5;
// }
.RightMenu.exited {
    visibility: hidden;
    display: none;
}

.RightMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 0;
    min-width: 0;
    //overflow: hidden;
    visibility: hidden;
    transition: min-width 0.2s ease-in-out;
    white-space: nowrap;
    //min-height: 100vh;
    height: 100%;
    .ItemsContainer {
        height: auto;
        padding: 60px 0 0;
        .Item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1 1;
            padding: 0 30px;
            width: 100%;
            height: 40px;
            vertical-align: middle;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            //color: #333;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;

            span {
                text-wrap: wrap;
            }
        }
    }
}

.RightPanelUnderlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    z-index: 45;
}
.RightPanelUnderlay.entered {
    opacity: 1;
}

.RightPanel {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    min-width: 560px;
    background-color: #fff;
    border-left: 1px solid #e1e5e8;
    border-radius: 0;
    transform: translate3d(100vw, 0, 0);
    transition: all 0.2s ease-in-out;
    z-index: 50;
    max-width: 700px;
    .Top {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e5e8;
        box-sizing: border-box;
        height: 39px;
        margin-top: 22px;
        padding: 0 334px 22px 30px;
        .Title {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            padding: 0;
            margin: 0;
            //color: #333;
        }
        .Cross {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            cursor: pointer;
            img {
                width: 100%;
                display: block;
            }
        }
    }
    .Form {
        padding: 40px 30px 20px;
        height: calc(100vh - 121px);
        box-sizing: border-box;
        overflow-x: auto;
        .FormSideBySide:first-child {
            margin-top: 0;
        }
        .FormSideBySide {
            display: table;
            width: 100%;
            margin: 20px 0 0;
            .FormLabel {
                display: table-cell;
                width: 175px;
                height: 30px;
                vertical-align: middle;
                line-height: 1.2em;
            }
        }
        h4 {
            font-size: 12px;
            line-height: 22px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            //color: #333;
            border-bottom: 24px;
        }
        .SubTitle {
            margin: 30px 0;
        }
        .react-datepicker__input-container input {
            font-weight: normal;
        }
        .DropzoneFooter {
            height: auto;
        }
    }
    .Form.read-only {
        .FormLabel + .FormField .EditBox.ViewOnly {
            padding-top: 0;
            color: $DynamicColor5;
            min-height: 30px;
        }
        .FormField .EditBox {
            padding: 0 15px;
        }
        .FormField .react-datepicker__input-container input {
            text-align: left;
        }
    }
    .PanelButtonsContainer {
        .Buttons {
            height: 60px;
            display: flex;
            align-items: center;
            background: #f9f9fb;
            padding: 0 30px;
            justify-content: flex-end;
            .Btn {
                margin: 0 0 0 10px;
                min-width: 120px;
            }
        }
        .Buttons.BottomErrorSummary {
            justify-content: space-between;
        }
    }
    .Buttons {
        align-items: center;
        background: $MainColor8;
        display: flex;
        height: 60px;
        justify-content: flex-end;
        padding: 0 30px;
        gap: 10px;
    }
}
.RightPanel.entered {
    transform: translateZ(0);
    .PanelButtonsContainer {
        .Buttons {
            background: #f5f5f5;
            border: none;
            .Btn {
                border-radius: 3px;
            }
            .Btn.BtnCancel {
                background: transparent;
                border: 1px solid #d5d5d5;
                color: #333;
            }
        }
    }
    .RadioButtons,
    .RadioButtons2 {
        .Radio {
            .CustomInput {
                height: 30px;
                vertical-align: middle;
            }
            .CustomInput:before,
            .CustomInput:after {
                top: 6px;
            }
            input[type='checkbox']:checked + .CustomInput:after,
            input[type='radio']:checked + .CustomInput:after {
                -webkit-transform: scale(1);
                transform: scale(1);
                background-image: url('../../assets/Cat-Entity/Icon_Checklist.png');
            }
        }
    }
    .RadioButtons2 {
        display: flex;
        flex: 1 1;
        max-width: 100%;
        .Radio:first-child {
            margin-left: 0;
        }
        .Radio {
            margin-left: 20px;
        }
    }
    .MultiChoice {
        input[type='checkbox']:checked + .CustomInput:before,
        input[type='radio']:checked + .CustomInput:before {
            background: $DynamicColor1;
            border: 1px solid $DynamicColor1;
        }
        input[type='checkbox']:checked + .CustomInput:after,
        input[type='radio']:checked + .CustomInput:after {
            -webkit-transform: scale(1);
            transform: scale(1);
            background-image: url('../../assets/Cat-Entity/Icon_Checklist.png');
        }
    }
}

.Spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    //transform: translate(calc(#{$navWidth/2} - 50%), -50%);
    z-index: 10;
    border-radius: 100%;
    padding: 5px;
    background-color: white;

    &.PositionAbsolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &.NoPosition {
        position: static;
    }
}

.DeleteModal {
    h4 {
        font-size: 12px;
        line-height: 22px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .BoldText {
        margin-top: 10px;
        font-weight: 700;
    }
    .DeleteModalWarning {
        position: relative;
        width: 100%;
        height: 40px;
        background: #fff7f7;
        border-radius: 3px;
        display: flex;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
        }
        p {
            color: #c77979;
            margin: auto auto auto 44px;
        }
    }
}

.PageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    .HeaderActionsContainer {
        display: flex;
        flex: 1 1;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .SearchBoxContainer {
            position: relative;
            display: flex;
            margin-right: 30px;
            width: 230px;
            height: 30px;
            .SearchBox {
                font-size: 10px;
                font-weight: 400;
                width: 100%;
                padding: 10px 40px 10px 15px;
                border: 1px solid #d5d5d5;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: 'Open Sans', sans-serif;
                color: #333;
                -webkit-appearance: none;
                background-color: #f5f5f5;
            }
            .SearchIcon,
            .ClearSearchIcon {
                position: absolute;
                width: 33px;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }

        .Btn.BtnAdd {
            //background: #ebf8f7;
            border: 1px solid #edf6f8;
            //color: #00a095;
            font-size: 11px;
            line-height: 16px;
            font-weight: 700;
            text-align: left;
            width: 200px;
            height: 30px;
            width: auto;
            background-image: url('../../assets/Cat-Entity/Icon_Button_Add.png');
            background-position: 0;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 0 20px 0 35px;
            //padding: 0 20px;
            white-space: nowrap;
        }
    }
    .HeaderActionsContainer > :not(:last-child) {
        margin-right: 10px;
    }
}

.ClickOutsideDetector {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.OptionsList {
    position: absolute;
    top: 40px;
    z-index: 10;
    min-width: 200px;
    padding: 10px 0;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    box-shadow: 0 0 4px -2px #a7b0b9;
    .OptionItem {
        padding: 10px 20px;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
.OptionsList.entered {
    display: flex;
    opacity: 1;
}
