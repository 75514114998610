.Modal.InformationRequestModal {
    .ModalHeader .ModalHeading {
        font-weight: 700;
    }
    .ModalContent {
        padding: 2rem;

        p {
            font-size: 0.7rem;
            line-height: 1.2rem;
        }
    }
}
.Modal.UploadInfoRequestModal {
    width: 550px;
    @include bp('small-tablet') {
        width: 460px;
    }
    @include bp('mobile') {
        width: auto;
    }

    .ModalContent {
        padding: 2rem;
    }

    .InfoRequestError,
    .InfoRequestLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.7rem;
    }
    .InfoRequestError {
        h2,
        p {
            margin: 0;
        }
        button {
            margin-top: 1rem;
            align-self: stretch;
        }
    }
    .InfoRequestHeader {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 1rem;
        flex: 1 0;

        @include bp('mobile') {
            flex-direction: column;

            .InfoRequestArrow {
                flex: 1;
            }
        }

        .Background {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            --c: #2a282e; /* Color */
            --t: 2px; /* Thickness */
            --h: 5px; /* Height (vertical distance between two curve) */
            --w: 32px; /* Width  */
            --p: 2px; /* adjust this to correct the position when changing the other values*/
            background: radial-gradient(
                    farthest-side at 50% calc(100% + var(--p)),
                    #000 47%,
                    var(--c) 50% calc(50% + var(--t)),
                    transparent calc(52% + var(--t))
                ),
                radial-gradient(
                    farthest-side at 50% calc(0% - var(--p)),
                    #000 47%,
                    var(--c) 50% calc(50% + var(--t)),
                    #000 calc(52% + var(--t))
                );

            background-size: var(--w) var(--h);
            background-position: calc(var(--w) / 2) calc(var(--h) / 2), 0px calc(var(--h) / 2);
        }

        .InfoRequestArrow {
            align-self: stretch;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $MainColor15;
            padding: 1.5rem 2rem;
        }
        .TitleWrapper {
            padding: 1.5rem 2rem;
            color: $BasicColor1;

            .TransferType {
                font-size: 0.8rem;
                font-weight: 500;
                text-transform: capitalize;
                margin-bottom: 0.5rem;
            }
            .TransferAmount {
                font-size: 1.5rem;
            }
        }
    }
    .FromValue {
        font-size: 0.8rem;
        font-weight: bold;
        padding-bottom: 1rem;
        border-bottom: 1px solid $MainColor3;
        margin-bottom: 1rem;
    }
    .Message {
        font-size: 0.65rem;
        line-height: 1rem;
    }
    form {
        textarea {
            resize: vertical;
            min-height: 100px;
            max-height: 300px;
        }
        button {
            width: 100%;
            font-size: 0.7rem;
        }
        .DropzoneFooter {
            height: unset;
        }
    }
}

.RemovePayeeModal {
    .ModalContent {
        padding: 2rem;
    }
    .Loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 0.8rem;

            span {
                font-weight: 600;
            }
        }
    }
    p span {
        font-weight: 600;
    }
    .Buttons {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        button {
            flex: 1;
        }
    }
}

.DepositCryptoModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    img {
        max-width: 100%;
        width: 300px;
    }
    &.Loading {
        p {
            margin-bottom: 5px;
        }
    }
    .CustomSelect .CustomSelect__control {
        border-radius: 5px;
    }
}
.DepositCryptoModalWrapper.WithSelector {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .DepositCryptoModal {
        min-height: 60px;
    }
    .CustomSelect .CustomSelect__control {
        border-radius: 5px;
    }
}
.WithdrawCrypto {
    // round footer edge. No time to check if this would be okay for every modal
    overflow: hidden;
    .Loading {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 150px;

        .Spinner {
            position: relative;
            top: 0;
            left: 0;
        }
    }
    .RadioButtons {
        flex-wrap: wrap;
        gap: 10px;
    }
    .RadioButton {
        margin-right: 0;
        span {
            white-space: nowrap;
        }
    }
}
.ModalBackground.DeleteNicknameContainer {
    // One index higher than the standard modal
    z-index: 28;
    .DeleteNicknameWarning {
        width: 100%;
        height: 40px;
        background: $AlertBadBackground;
        border-radius: 3px;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 40px;
            height: 40px;
        }
        p {
            color: $AlertBad;
            margin-bottom: 0;
        }
    }
}
.WithdrawCryptoModal,
.BuyDusdModal,
.LiquidateCryptoModal {
    .ContentBox {
        border: none;
        padding: 0;
        margin: 0;
        .OrderSummaryContainer {
            width: 100%;
            .FloatRight {
                float: right;
                font-weight: 600;
            }
        }
    }
    .AuthFormContainer {
        border: 1px solid $MainColor3;
        border-radius: 3px;
        padding: 20px;

        .AuthForm {
            margin-bottom: 0;
        }
        .Btn {
            display: none;
        }
    }
    .FormField .EditBox.ViewOnly {
        color: $MainColor14;
    }
}
.LiquidateCryptoModal {
    .FormField .EditBox.PriceField.ViewOnly {
        padding: 10px 15px;
        border: 1px solid $MainColor4;
        color: $MainColor1;
        cursor: default;
    }
}

.ModalFieldsRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    //justify-content: space-between;
    .FormBox {
        //display: flex;
        //flex-direction: column;
        .FormLabel {
            margin-bottom: 0;
        }
    }
    .FormBox.FormSideBySide,
    .FormBox.SideBySide {
    }
}
.PullFundsModal {
    .FormBox.FormSideBySide {
        ////display: flex;
        ////justify-content: space-between;
        display: flex;
        flex-direction: column;
        .FormField {
            //width: 100%;
            ////flex: 1;
        }
    }
}

.PullFundsModal.Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}

.ManageCardUsers_AddCardModal {
    .FormLabel {
        width: 150px;
    }
    .FormField {
        flex: 1;
    }
}

.DocumentUploadModal {
    z-index: 1000;
}

.Modal.PendingCardModal {
    .ModalContent {
        .Text {
            font-size: 1.3em;
        }
    }
}

.Modal.ReverseTransferModal {
    &--Large {
        .ModalContent {
            min-height: 300px;
        }
    }
    .ModalContent {
        padding: 2rem;
    }
}
.Modal.CancelTransferModal {
    .ModalContent {
        padding: 2rem;
    }
}

.Modal.PendingCardModal {
    .ModalContent {
        .Text {
            font-size: 1.3em;
        }
    }
}
.TableWrapperLoadingOverlay {
    position: relative;
    display: flex;
    min-height: 100px;
    gap: 2rem;
}
.LoadingOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(100, 100, 100, 0.2); 
    width: 100%;
    height: 100%;
}