// Please don't add any additional CSS to this file. Instead use the bp() mixin
// where you are originally selecting the element.

/*Layout*/
@media only screen and (max-width: 1200px) {
    .WidthContent {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .WidthContent {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .WidthContent {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .WidthContent {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 360px) {
    .WidthContent {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 1200px) {
    .HeightContent {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .HeightContent {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

/*Header*/
@media only screen and (max-width: 768px) {
    .Header {
        .InnerHeader {
            .Actions {
                .LanguageDropdown {
                    margin-right: 0;
                }

                .Buttons {
                    display: none;
                }
            }
        }
    }
}

/*Category table- business users page*/

@media only screen and (max-width: 768px) {
    .CategoryContainer.listsCatTable {
        // height: calc(100% + 46px);
        // width: calc(100% + 120px);
        //position: absolute;
        width: 100%;
        height: 100%; //auto;
        top: 0;
        left: 0;
        //height: calc(100vh - 120px);
        .WidthContent {
            padding-left: 30px;
            padding-right: 30px;
        }
        .HeightContent {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}
@media only screen and (max-width: 480px) {
    .CategoryContainer.listsCatTable {
        .WidthContent {
            padding-left: 20px;
            padding-right: 20px;
        }
        .HeightContent {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
@media only screen and (max-width: 360px) {
    .CategoryContainer.listsCatTable {
        .WidthContent {
            padding-left: 10px;
            padding-right: 10px;
        }
        .HeightContent {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .CategoryPage {
        padding: 0;
        width: 100%;
        height: 100%;
    }
    .CategoryPage.entered {
        max-width: calc(100% - 240px);
    }
    .CategoryContainer.listsCatTable {
        // .HeightContent {
        //     padding-top: 40px;
        //     padding-bottom: 40px;
        // }
        // .WidthContent {
        //     padding-left: 40px;
        //     padding-right: 40px;
        // }
        .FlexTableWrapper .TableContainer .LeftSideTable {
            width: fit-content;
        }
    }
}
@media only screen and (max-width: 768px) {
    .CategoryContainer.listsCatTable {
        flex-direction: column;
        height: fit-content; // or auto;//[[better to remove overflow auto if remove next line]]
        //overflow: auto;//opt for height 100%/calc(100vh - 120px) (def opt for fit-content/auto)
        .CategoryPage.entered {
            width: 100%;
            max-width: calc(1200px - 240px);

            overflow: auto;
            max-height: calc(100vh - 120px);

            max-width: 100%;

            margin-bottom: 80px;
        }
        .RightMenu.entered {
            width: 100%;
            height: auto;
        }
        .HeightContent.entered {
            padding-bottom: 60px; //120px;
            padding-bottom: 120px;
        }

        .RightMenu.entered {
            // position: absolute;
            // bottom: -60px;
            // bottom: 0;
            position: fixed;
            bottom: 60px;
            top: auto;
        }
        .RightMenu .ItemsContainer {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .PageHeader .HeaderActionsContainer {
        padding-left: 20px;
        .SearchBoxContainer {
            width: 165px;
        }
    }
}
@media only screen and (max-width: 768px) {
    .Btn.BtnAdd {
        width: auto;
    }
}
@media only screen and (max-width: 1200px) {
    .PageHeader .HeaderActionsContainer {
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: space-around;
        .Btn.BtnAdd {
            width: 30px;
            height: 30px;
            padding: 0;
        }
        .BtnAddContent {
            display: none;
        }
    }
    // .PageHeader .HeaderActionsContainer > :not(:last-child) {
    //     margin-right: 0px;
    //     margin-top: 10px;
    // }
}
@media only screen and (max-width: 480px) {
    .PageHeader .HeaderActionsContainer {
        flex-direction: column-reverse; //row-reverse;
        .SearchBoxContainer {
            width: 130px; //145px;
            .SearchBox {
                padding: 5px; //10px;
            }
            .ClearSearchIcon,
            .SearchIcon {
                right: 0;
                width: 30px;
            }
        }
    }
    .PageHeader .HeaderActionsContainer > :not(:first-child) {
        margin-right: 3px; //5px;
    }
}

/*card management - managing cards*/
@media only screen and (max-width: 1024px) {
    .FlexTableWrapper .TableContainer .RightTable .TableBody .Row .Cell.ButtonCell {
        width: 180px;
    }
}
