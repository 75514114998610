.MarketTableInput {
    height: fit-content;
    position: relative;

    img,
    .SvgIcon {
        height: 15px;
        left: 10px;
        position: absolute;
        top: 8px;
    }

    .FormBox {
        margin-bottom: 0;

        .FormLabel {
            margin: 0;
        }

        .FormField {
            width: 200px;

            .EditBox {
                background-color: #fff;
                padding: 5px 10px;
                padding-left: 30px;
            }
        }
    }
}
