.CryptoExchangeList {
    display: flex;
    gap: 10px;
    max-width: 100%;
    flex-wrap: wrap;
    @include bp('small-tablet') {
        justify-content: center;
    }
}

.CryptoExchangeTile,
.TileInner {
    height: 145px;
    width: 300px;
}
.CryptoExchangeTile {
    transition: height 0.2s ease-in-out;
    .TileHeader {
        display: flex;
        .Actions {
            margin-left: auto;
            display: flex;
            gap: 10px;
        }
        .FavouriteButton {
            background-image: url('../../assets/icon-favorite-01.svg');
            &.Favourited {
                background-image: url('../../assets/icon-favorite-02.svg');
            }
        }
        .ChartButton {
            background-image: url('../../assets/icon-chart.svg');
        }
        .FavouriteButton,
        .ChartButton {
            border: none;
            background-color: unset;
            width: 20px;
            height: 20px;
        }
    }

    .TileInner {
        border: 1px solid $MainColor3;
        background-color: $BasicColor1;
        padding: 15px;
        position: relative;
        transition: height 0.2s ease-in-out;
        overflow: hidden;

        &.Expanded {
            height: 300px;
            z-index: 1;
        }
    }
    .ExchangeValues {
        display: flex;
    }
    .CurrencyItem {
        width: 50%;
        font-size: 0.8rem;
        font-weight: 600;

        .CurrencyAmount {
            display: flex;
            font-size: 1rem;
            max-width: 120px;
            overflow: hidden;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }

            &.Green {
                color: $FX_Green;
            }

            &.Red {
                color: $FX_Red;
            }
        }
    }
    .MinMaxValues {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ExpandBtn {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        border: none;
        border-top: 1px solid $MainColor3;
        background: $BasicColor1;
        display: flex;
        justify-content: center;

        .ChevronOpen {
            transform: rotate(-135deg);
            margin-top: 5px; // keeps the chevrons aligned as they rotate
            border-color: $MainColor1;
        }
        .ChevronClosed {
            transform: rotate(45deg);
            margin-bottom: 5px;
            border-color: $MainColor1;
        }
    }
    .RadioButtons {
        margin-top: 10px;
        .RadioButton {
            margin: 0;
            height: 30px;
            border-color: $DynamicColor2;
            text-align: center;
            display: flex;
            font-size: 0.85rem;
            line-height: 0.85rem;
            span {
                margin: auto;
            }
            span::before {
                display: none;
            }
            &.Selected {
                background-color: $DynamicColor2;
                color: $BasicColor1;
            }
            &:first-of-type {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:not(:first-of-type) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
    .ArrowIcon {
        margin-left: 5px;
        display: inline-block;
        transform: rotate(-45deg);

        // svg class defining it's fill
        .cls-1 {
            fill: $FX_Red;
        }

        &.Up {
            transform: rotate(135deg);
            .cls-1 {
                fill: $FX_Green;
            }
        }
    }
}
.CurrencyInputWrapper {
    position: relative;
    .FormField .EditBox.CurrencyAmountInput {
        padding-left: 55px;
        font-size: 14px;
        line-height: 14px;
        &:focus {
            border-color: $DynamicColor2;
            background-color: $DynamicColor3;
            color: $DynamicColor2;
        }
    }
    .CurrencyLabel {
        z-index: 1;
        position: absolute;
        left: 10px;
        line-height: 14px;
        font-size: 14px;
        top: 13px;
    }

    &:focus-within {
        .CurrencyLabel {
            color: $DynamicColor2;
        }
    }
}

.CryptoExchangeModal {
    .CryptoExchangeModalError {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .InputSection {
        padding: 20px;
        color: $BasicColor1;
        font-weight: 600;
        background-image: url('../../assets/bg-exchange-01.png');

        &.Exact {
            background-image: url('../../assets/bg-exchange-02.png');
        }
        * {
            color: $MainColor1;
        }
        .FormBox {
            margin-bottom: 0;
        }
    }
    .ExchangeDetail {
        border-radius: 3px;
        overflow: hidden;
    }
    .ExchangeRate {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 15px;
        background-color: #323c46;
        color: $BasicColor1;
        line-height: 0.7rem;
        font-size: 0.7rem;

        .Rates {
            margin-left: 10px;
            margin-right: auto;
            p {
                color: $BasicColor1;
                margin-bottom: 0;
                line-height: 0.7rem;
                font-size: 0.7rem;
            }
            .c2 {
                color: #8aceff;
            }
            .c3 {
                color: #0391f6;
            }
        }

        .ExactToggle {
            svg {
                height: 24px;
            }
            width: 132px;
            min-width: 132px;
            padding: 5px;
            height: 30px;
            margin-right: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 3px;
        }
    }
    .FromTo {
        margin-bottom: 20px;
        .From,
        .To {
            display: flex;
            height: 60px;
            border-bottom: 1px solid $MainColor3;
            align-items: center;

            &.ExtraMargin {
                padding: 0 10px;
            }

            .TextIcon {
                margin-left: 0;
            }

            .AccountInfo {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                > * {
                    margin: 0;
                }
                p {
                    font-size: 0.7rem;
                    line-height: 0.7rem;
                }
                h4 {
                    font-size: 0.8rem;
                }
            }
            .Balance {
                margin-left: auto;
                font-size: 0.8rem;
                font-weight: 600;
            }
        }
    }
    .TFAInputContainer {
        margin-top: 10px;
    }
    .FormField .EditBox.CommentField {
        resize: vertical;
        min-height: 100px;
        max-height: 300px;
    }
    .OrderCompleteReference {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .OrderCompleteImage {
            width: 50px;
        }
    }
    .OrderCompleteButtons {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 1rem;
    }
    .BuySellAmounts {
        background-image: url('../../assets/bg-exchange-02.png');
        color: $BasicColor1;
        display: flex;
        padding: 20px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        flex-wrap: wrap;
        column-gap: 10px;
        > div {
            min-width: calc(50% - 10px);
        }
        h3 {
            font-size: 1.2rem;
            color: $BasicColor1;
            font-weight: 400;
        }
        h3,
        h4 {
            margin-bottom: 10px;
        }
    }
    .ExchangeRateConfirm {
        justify-content: center;
        .Rates {
            margin-right: 0;
        }
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    .SummaryRow {
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $MainColor3;

        .RowInfo {
            width: 50%;
            > * {
                margin: 0;
            }
            p {
                font-size: 0.7rem;
                line-height: 0.7rem;
            }
            h4 {
                font-size: 0.8rem;
            }
        }
    }
    .OrderButtons {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        .Btn {
            flex: 1;
        }
    }
    .ExchangeDetail .ErrorLabel {
        color: $BasicColor6 !important;
    }
    .AccountDropdown {
        margin: 5px 0;
        height: 60px;
        &__control {
            height: 60px;
        }
        &__value-container {
            height: 100%;
        }
        &--value {
            width: 100%;
            .From {
                padding-right: 5px;
                width: 100%;

                .TextIcon {
                    min-width: 24px;
                }
                .AccountInfo {
                    width: 100%;
                    .AccountDropdownRow {
                        display: flex;
                        flex-wrap: wrap;
                        column-gap: 5px;
                        justify-content: space-between;
                        .Balance {
                            margin-left: unset;
                        }

                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.Modal.CryptoExchangeChartModal {
    width: 80vw;
    @include bp('small-tablet') {
        width: 90vw;
    }
    @include bp('mobile') {
        width: 95vw;
    }
    .ModalContent {
        height: 80vh;
        @include bp('tablet') {
            padding: 5px;
        }
    }
}

.CryptoExchangePageHeader {
    justify-content: start;
    margin-bottom: 10px;
    > div {
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .left {
        .CustomSelect__control {
            padding: 0 10px;
        }
        .CustomSelect__indicator {
            padding: 0;
        }
        .CustomSelect__value-container {
            padding: 0;
        }
    }
    .right {
        .ToggleDisplayFavs {
            height: 60%;
        }
    }
    .CustomSelect {
        width: 200px;
        height: 100%;
        .CustomSelect__control {
            border-radius: 5px;
            min-height: 25px;
            height: 25px;
        }
    }
    &.TableHeader {
        .SetFavouriteButton {
            display: flex;
            gap: 10px;
            height: 25px;
            align-items: center;
            border-color: $MainColor3;
            color: $MainColor1;
            &.Selected {
                border-color: $DynamicColor2;
                color: $DynamicColor2;
            }
            img {
                height: 60%;
                margin-left: -10px;
            }
        }
        .SearchBoxContainer {
            height: 25px;
            .SearchBox {
                color: $MainColor1;
            }
        }
        @include bp('tablet') {
            flex-direction: row;
            .Btn {
                width: unset;
            }
        }
        @include bp('mobile') {
            height: auto;
            .right {
                flex-direction: column;
                width: 100%;
            }
            .CustomSelect,
            .left {
                width: 100%;
            }
            .SetFavouriteButton {
                width: 100%;
                justify-content: center;
            }
        }
    }
}
