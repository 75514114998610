.Footer {
    $FooterHeight: 60px;

    &.Desktop {
        background-color: white;
        bottom: 0;
        display: flex;
        font-size: 10px;
        height: $FooterHeight;
        padding: 0px 60px;
        position: fixed;
        right: 0;
        width: 100%;

        @include bp('tablet') {
            display: none;
        }

        ul {
            align-items: center;
            border-top: solid 1px $MainColor3;
            display: flex;
            height: 100%;
            list-style: none;
            min-width: 100%;

            li {
                font-size: 10px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
                a {
                    color: $MainColor1;
                    text-decoration: none;
                }
            }
        }
        .BuildNumber {
            width: 200px;
            margin-left: -200px;
            text-align: end;
            line-height: $FooterHeight;
        }
    }
}
