@import 'styles/variables.scss';

.MakePaymentWrapper {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .MakePayment {
       
        width: 100%;

        h1 {
            text-transform: capitalize;
        }

        .MakePaymentHeader {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: 2rem 0;

            h2 {
                margin-bottom: 0;
            }

            .BackButton {
                background-color: transparent;
                border: 1px solid $MainColor3;
                border-radius: 3px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                box-sizing: content-box;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        form {
            textarea {
                resize: vertical;
                min-height: 130px;
                max-height: 300px;
            }
            :global(.DropzoneFooter) {
                height: unset;
            }
        }
    }

    .PayeeNameWrapper {
        display: flex;
        align-items: center;
        max-width: 300px;
        gap: 0.5rem;

        @include bp('tablet') {
            display: none;
        }

        .PayeeInitials {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            min-width: 60px;
            height: 60px;
            background-color: $DynamicColor2;
            border-radius: 50%;
            color: $BasicColor1;
            font-weight: 500;
            font-size: 1.25rem;
        }
        .PayeeName {
            font-weight: 700;
            font-size: 1.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .ReviewPayment {
        .Layout {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: space-between;
        }

        .Row {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.75rem;
            margin-bottom: 0.5rem;

            &:last-of-type {
                margin-bottom: 2rem;
            }

            dt {
                min-width: 110px;
            }
            dd {
                font-weight: 500;
                color: $BasicColor2;
                text-wrap: wrap;
                word-break: break-word;
            }
        }
        .Loading {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 200px;
        }
    }
    .ReviewPaymentIbaneraNetwork {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 1rem; 
        .Row {
            border-radius: 5px;
    
            &:last-of-type {
                margin-bottom: 2rem;
            }
    
            dt {
                min-width: 110px;
            }
            dd {
                font-weight: 500;
                color: $BasicColor2;
                text-wrap: wrap;
                word-break: break-word;
            }
        }
    }

    
}
