.FileUpload {
    .FileNames {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 1rem;
        height: 100%;
    }
    .Dropzone {
        height: 100%;
        min-height: 100px;
        overflow-y: auto;
        padding: 1rem;
        p {
            margin: 0;
        }
        h5 {
            font-size: 0.7rem;
            font-weight: 500;
            margin-top: 5px;
        }
        img {
            height: 20px;
        }
    }
    .DropzoneFooter {
        display: flex;
        gap: 1rem;
        margin-bottom: 0;

        > * {
            flex-basis: 50%;
        }
    }
}

.UploadedFilesList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 20px;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;

    .File {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $MainColor8;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        text-decoration: none;
        gap: 10px;

        .FileName {
            width: 100%;
            word-break: break-word;
            font-size: 0.7rem;
            font-weight: 600;
        }
        button {
            cursor: pointer;
            outline: none;
            background-color: transparent;
            border: none;
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            margin: 0;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}
